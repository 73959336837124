import { useAtomValue } from 'jotai'
import useInitAlerts from '../../../../../hooks/data/effects/useInitAlerts'
import {
  alertEventsAtom,
  alertsAtom,
  contactChannelsAtom,
} from '../../../../../state/state'
import AlertDetailsSidebar from './AlertDetailsSidebar/AlertDetailsSidebar'
import CodeCTAButton from '../../../../library/CodeCTAButton/CodeCTAButton'
import EventRow from './EventRow/EventRow'
import useInitAlertEvents from '../../../../../hooks/data/effects/useInitAlertEvents'
import { useState } from 'react'
import AlertPopup from '../../../../library/AlertPopup/AlertPopup'
import DeleteAlertPopup from '../../../../library/DeleteAlertPopup/DeleteAlertPopup'
import useUpdateAlert from '../../../../../hooks/actions/useUpdateAlert'
import useDeleteAlert from '../../../../../hooks/actions/useDeleteAlert'
import { AlertInfo, AlertUpdateRequest } from '../../../../../backend/types'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'

const AlertDetails = () => {
  const navigate = useNav()

  useInitAlerts()
  useInitAlertEvents()

  const updateAlert = useUpdateAlert()
  const deleteAlert = useDeleteAlert()

  const alerts = useAtomValue(alertsAtom)
  const alertEvents = useAtomValue(alertEventsAtom)
  const contactChannels = useAtomValue(contactChannelsAtom)

  const alertId = getAlertId()
  const alert = alerts.find((alert) => alert.alert.id === alertId)

  const [isEditing, setIsEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    if (!alertId) return
    await deleteAlert(alertId)
    navigate(LinkKey.Alerts)
    setIsDeleting(false)
  }

  const handleUpdate = async (request: AlertUpdateRequest) => {
    if (!alertId) return
    await updateAlert(alertId, request)
    setIsEditing(false)
  }

  const handleToggleMute = async () => {
    if (!alertId) return
    const status = alert?.alert.status === 'muted' ? 'active' : 'muted'
    await updateAlert(alertId, { status })
  }

  return (
    <div className="w-full flex">
      <div className="flex-1 flex flex-col">
        <div className="w-full px-8 py-6 flex items-center justify-between border-b border-main-border">
          <h1 className="text-small-header text-text-1">{alert?.alert.name}</h1>
          <div className="flex items-center gap-2">
            <CodeCTAButton onClick={handleToggleMute}>
              {isMuted(alert) ? 'Unmute' : 'Mute'}
            </CodeCTAButton>
            <CodeCTAButton onClick={() => setIsEditing(true)}>
              Edit
            </CodeCTAButton>
            <CodeCTAButton onClick={() => setIsDeleting(true)}>
              Delete
            </CodeCTAButton>
          </div>
        </div>
        <div className="flex flex-col overflow-y-auto">
          <div className="sticky top-0 w-full px-8 py-4 flex items-center bg-panel-bg border-b border-main-border">
            <h2 className="text-small-header text-text-1">History</h2>
          </div>
          {alertEvents.map((event) => (
            <EventRow key={event.id} event={event} />
          ))}
        </div>
      </div>
      {alert && contactChannels && (
        <AlertDetailsSidebar alert={alert} contactChannels={contactChannels} />
      )}

      {isEditing && (
        <AlertPopup
          info={alert}
          onUpdate={handleUpdate}
          close={() => setIsEditing(false)}
        />
      )}

      {isDeleting && (
        <DeleteAlertPopup
          name={alert?.alert.name || 'Alert'}
          onSubmit={handleDelete}
          close={() => setIsDeleting(false)}
        />
      )}
    </div>
  )
}

function isMuted(alert: AlertInfo | undefined) {
  return alert?.alert.status === 'muted'
}

function getAlertId() {
  const path = window.location.pathname
  const parts = path.split('/alerts/')
  return parts[1]
}

export default AlertDetails
