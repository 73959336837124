import { QueryOptionsRunner } from './queryOptionsRunner'
import { OptionsQueryParameters, OptionsQueryState } from './types'
import { createOptionsQueryState } from './utils'

export type QueryOptionsSubscriber = (queryOptions: OptionsQueryState) => void

export class QueryOptionsManager {
  private queryOptionsRunner: QueryOptionsRunner

  private queryMap: { [key: number]: OptionsQueryState }
  private queryIndex: number

  private subscribers: { [key: string]: QueryOptionsSubscriber }

  constructor(queryOptionsRunner: QueryOptionsRunner) {
    this.queryOptionsRunner = queryOptionsRunner

    this.queryMap = {}
    this.queryIndex = 0

    this.subscribers = {}
  }

  subscribe = (key: string, subscriber: QueryOptionsSubscriber) => {
    this.subscribers[key] = subscriber
  }

  unsubscribe = (key: string) => {
    delete this.subscribers[key]
  }

  queryOptions = async (params: OptionsQueryParameters) => {
    const index = this.queryIndex++
    const state = createOptionsQueryState(index, params)

    await this.runQuery(state)
  }

  private runQuery = async (state: OptionsQueryState) => {
    this.queryMap[state.index] = { ...state }
    this.cancelLowerQueries(state.index)
    this.publishMaxQuery()

    const result = await this.queryOptionsRunner.queryOptions(
      state.request,
      state.controller.signal,
    )
    if (result.type === 'canceled') {
      state.mode = 'none'
      this.publishMaxQuery()
      return
    }

    this.queryMap[state.index] = {
      ...state,
      mode: 'none',
      result: result,
    }
    this.publishMaxQuery()
  }

  private cancelLowerQueries = (index: number): void => {
    for (const query of Object.values(this.queryMap)) {
      if (query.index < index) {
        query.controller.abort()
      }
    }
  }

  private publishMaxQuery = (): void => {
    const maxQuery = Object.values(this.queryMap).reduce((max, query) => {
      return Math.max(max, query.index)
    }, 0)

    const queryState = this.queryMap[maxQuery]
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(queryState)
    }
  }

  private publishOptions = (state: OptionsQueryState): void => {
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(state)
    }
  }
}
