import { X } from 'lucide-react'
import { Filter } from '../../../../../../../backend/types'
import { propertyToDisplay } from '../../../../../../../utils/properties'

type FilterBlockProps = {
  filter: Filter
  remove: () => void
}

const FilterBlock = ({ filter, remove }: FilterBlockProps) => {
  return (
    <div className="px-1 py-0.5 flex items-center gap-1 rounded-[4px] bg-input-tint">
      <p className="text-small-code text-text-1">{formatFilter(filter)}</p>
      <X
        size={14}
        className="text-text-2 hover:text-text-1 cursor-pointer"
        onClick={remove}
      />
    </div>
  )
}

function formatFilter(filter: Filter) {
  return `${propertyToDisplay(filter.field)}:${formatValue(filter)}`
}

function formatValue(filter: Filter) {
  switch (filter.operator) {
    case 'equals':
      return `${filter.value}`
    case 'not_equals':
      return `!${filter.value}`
    case 'contains':
      return `*${filter.value}*`
    case 'not_contains':
      return `!*${filter.value}*`
    case 'exists':
      return `*`
    case 'not_exists':
      return `!*`
    default:
      return filter.value
  }
}

export default FilterBlock
