import { useState, useEffect } from 'react'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import RowButtonDropdown from '../../../../../library/RowButtonDropdown/RowDropdown'
import CodeButton from '../../../../../library/CodeButton/CodeButton'
import { Minus, PanelLeft, Plus, Square } from 'lucide-react'
import { SquareCheckBig } from 'lucide-react'
import { Filter, ValueCount } from '../../../../../../backend/types'
import {
  COLUMN_LEVEL,
  COLUMN_BODY,
  COLUMN_TIMESTAMP,
  ATTRIBUTE_PREFIX,
  ATTRIBUTE_SERVICE,
  COLUMN_PREFIX,
  formatNumber,
  propertyToDisplay,
} from '../../../../../../utils/properties'
import { Header } from '../../Logs'
import { QueryMode } from '../../../../../../library/query/types'
import LoadingDots from '../../../../../library/LoadingDots/LoadingDots'
import IconButton from '../../../../../library/IconButton/IconButton'
import { useAtom } from 'jotai'
import { sidebarVisibleAtom } from '../Searchbar/SidebarVisibilityButton/SidebarVisibilityButton'

interface LogSidebarProps {
  total: number
  queryMode: QueryMode

  services: ValueCount[]
  levels: ValueCount[]
  properties: ValueCount[]

  headers: Header[]
  toggleHeader: (header: string) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  selectedProperty: string | null
  selectProperty: (property: string) => void
}

const LogSidebar = ({
  total,
  queryMode,
  services,
  levels,
  properties,
  headers,
  toggleHeader,
  filters,
  setFilters,
  selectedProperty,
  selectProperty,
}: LogSidebarProps) => {
  const [serviceOpen, setServiceOpen] = useState(true)
  const [levelOpen, setLevelOpen] = useState(true)
  const [visibleOpen, setVisibleOpen] = useState(true)
  const [hiddenOpen, setHiddenOpen] = useState(true)
  const [sidebarVisible, setSidebarVisible] = useAtom(sidebarVisibleAtom)

  const handleSelectService = (service: string) => {
    const exists = filters.some(
      (f) => f.field === ATTRIBUTE_SERVICE && f.value === service,
    )
    const newFilters = filters.filter((f) => f.field !== ATTRIBUTE_SERVICE)
    if (!exists) {
      newFilters.push({
        field: ATTRIBUTE_SERVICE,
        operator: 'equals',
        value: service,
      })
    }
    setFilters(newFilters)
  }

  const handleSelectLevel = (level: string) => {
    const exists = filters.some(
      (f) => f.field === COLUMN_LEVEL && f.value === level,
    )
    const newFilters = filters.filter((f) => f.field !== COLUMN_LEVEL)
    if (!exists) {
      newFilters.push({
        field: COLUMN_LEVEL,
        operator: 'equals',
        value: level,
      })
    }
    setFilters(newFilters)
  }

  const initialLoad = queryMode === 'initial_load'
  const querying = queryMode === 'user_query'
  const visibleProperties = headers.map((h) => ({
    value: h.value,
    count: properties.find((a) => a.value === h.value)?.count || 0,
  }))
  const hiddenProperties = properties.filter(
    (a) => !headers.some((h) => h.value === a.value),
  )

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 1024) {
        setSidebarVisible(false)
      } else {
        setSidebarVisible(true)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setSidebarVisible])

  if (!sidebarVisible) return null

  return (
    <div className="flex-none w-[280px] border-r border-main-border overflow-y-auto">
      <PanelSection>
        <div className="w-full h-6 flex items-center gap-1">
          <div className="px-2 flex flex-1 justify-between">
            <h3 className="text-small-header text-text-1">Total Results</h3>
            <p className="text-small-code text-text-2">
              {querying || initialLoad ? '-' : formatNumber(total)}
            </p>
          </div>
          <IconButton
            icon={<PanelLeft size={16} />}
            size="24"
            onClick={() => setSidebarVisible(false)}
          />
        </div>
      </PanelSection>
      {initialLoad && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <div className="px-2 flex items-center text-small-header text-text-1">
              Loading
              <LoadingDots />
            </div>
            <div className="w-6"></div>
          </div>
        </PanelSection>
      )}
      {querying && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <div className="px-2 flex items-center text-small-header text-text-1">
              Running query
              <LoadingDots />
            </div>
            <div className="w-6"></div>
          </div>
        </PanelSection>
      )}
      {services.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title={'Service'}
              open={serviceOpen}
              setOpen={setServiceOpen}
            />
            <div className="w-6"></div>
          </div>
          {serviceOpen &&
            services.map((service) => (
              <ServiceButton
                key={service.value}
                service={service.value}
                count={service.count}
                active={isServiceFiltered(service.value, filters)}
                onClick={() => handleSelectService(service.value)}
              />
            ))}
        </PanelSection>
      )}
      {levels.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title="Level"
              open={levelOpen}
              setOpen={setLevelOpen}
            />
            <div className="w-6"></div>
          </div>
          {levelOpen &&
            levels.map((level) => (
              <LevelButton
                key={level.value}
                level={level.value}
                count={level.count}
                active={isLevelFiltered(level.value, filters)}
                onClick={() => handleSelectLevel(level.value)}
              />
            ))}
        </PanelSection>
      )}
      {visibleProperties.length > 0 &&
        total > 0 &&
        !initialLoad &&
        !querying && (
          <PanelSection>
            <div className="w-full h-6 flex items-center gap-1">
              <RowButtonDropdown
                title="Visible"
                open={visibleOpen}
                setOpen={setVisibleOpen}
              />
              <div className="w-6"></div>
            </div>
            {visibleOpen && (
              <>
                {visibleProperties.map((property, index) => (
                  <PropertyButton
                    key={`${property.value}-${index}`}
                    property={property.value}
                    count={property.count}
                    visible={true}
                    active={headers.some((h) => h.value === property.value)}
                    open={selectedProperty === property.value}
                    onClick={() => selectProperty(property.value)}
                    onClickToggle={() => toggleHeader(property.value)}
                  />
                ))}
              </>
            )}
          </PanelSection>
        )}
      {hiddenProperties.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title="Hidden"
              open={hiddenOpen}
              setOpen={setHiddenOpen}
            />
            <div className="w-6"></div>
          </div>
          {hiddenOpen && (
            <>
              {sortProperties(hiddenProperties).map((property, index) => (
                <PropertyButton
                  key={`${property.value}-${index}`}
                  property={property.value}
                  count={property.count}
                  visible={false}
                  active={headers.some((h) => h.value === property.value)}
                  open={selectedProperty === property.value}
                  onClick={() => selectProperty(property.value)}
                  onClickToggle={() => toggleHeader(property.value)}
                />
              ))}
            </>
          )}
        </PanelSection>
      )}
    </div>
  )
}

interface ServiceButtonProps {
  service: string
  count: number
  active: boolean
  onClick: () => void
}

const ServiceButton = ({
  service,
  count,
  active,
  onClick,
}: ServiceButtonProps) => {
  return (
    <div className="h-6 flex items-center gap-1">
      <CodeButton
        text={service}
        subtext={count === 0 ? '0' : formatNumber(count)}
        active={active}
        icon={active ? <SquareCheckBig size={16} /> : <Square size={16} />}
        onClick={onClick}
      />
      <div className="w-6"></div>
    </div>
  )
}

interface LevelButtonProps {
  level: string
  count: number
  active: boolean
  onClick: () => void
}

const LevelButton = ({ level, count, active, onClick }: LevelButtonProps) => {
  return (
    <div className="w-full h-6 flex items-center gap-1">
      <CodeButton
        text={formatLevel(level)}
        subtext={count === 0 ? '-' : formatNumber(count)}
        active={active}
        onClick={onClick}
        color={getLevelColor(level)}
        icon={active ? <SquareCheckBig size={16} /> : <Square size={16} />}
      />
      <div className="w-6"></div>
    </div>
  )
}

function getLevelColor(level: string) {
  if (level === 'INFO') return 'info'
  if (level === 'WARNING') return 'warning'
  if (level === 'ERROR') return 'error'
  if (level === 'DEBUG') return 'success'
  return 'info'
}

function formatLevel(level: string) {
  if (level === 'INFO') return 'info'
  if (level === 'WARNING') return 'warning'
  if (level === 'ERROR') return 'error'
  if (level === 'DEBUG') return 'debug'
  return 'info'
}

interface PropertyButtonProps {
  property: string
  count: number
  active: boolean
  open: boolean
  visible: boolean
  onClick: () => void
  onClickToggle: () => void
}

const PropertyButton = ({
  property,
  count,
  active,
  open,
  visible,
  onClick,
  onClickToggle,
}: PropertyButtonProps) => {
  return (
    <div className="property-button w-full h-6 flex items-center gap-1">
      <CodeButton
        text={propertyToDisplay(property)}
        subtext={count === 0 ? '-' : formatNumber(count)}
        active={active}
        selected={open}
        onClick={onClick}
      />
      <div className="w-6 flex items-center justify-center">
        {visible ? (
          <IconButton
            icon={<Minus size={16} />}
            size="24"
            onClick={onClickToggle}
          />
        ) : (
          <IconButton
            icon={<Plus size={16} />}
            size="24"
            onClick={onClickToggle}
          />
        )}
      </div>
    </div>
  )
}

function isLevelFiltered(level: string, filters: Filter[]) {
  return filters.some(
    (filter) =>
      filter.field === COLUMN_LEVEL &&
      filter.value === level &&
      filter.operator === 'equals',
  )
}

function isServiceFiltered(service: string, filters: Filter[]) {
  return filters.some(
    (filter) =>
      filter.field === ATTRIBUTE_SERVICE &&
      filter.value === service &&
      filter.operator === 'equals',
  )
}

function sortProperties(properties: ValueCount[]) {
  return [...properties].sort((a, b) => {
    if (propertyPriority(a.value) === propertyPriority(b.value)) {
      return b.count - a.count
    }
    return propertyPriority(a.value) - propertyPriority(b.value)
  })
}

function propertyPriority(property: string) {
  if (property.startsWith(COLUMN_PREFIX)) {
    switch (property) {
      case COLUMN_TIMESTAMP:
        return 0
      case COLUMN_BODY:
        return 1
      case COLUMN_LEVEL:
        return 2
    }
  }
  if (property.startsWith(ATTRIBUTE_PREFIX)) {
    switch (property) {
      case ATTRIBUTE_SERVICE:
        return 3
    }
  }
  return 4
}

export default LogSidebar
