import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useCreateOrganization from '../../../hooks/actions/useCreateOrganization'
import LabeledInput from '../../library/LabeledInput/LabeledInput'
import CTAButton from '../../library/CTAButton/CTAButton'

const NewOrganization = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createOrganization = useCreateOrganization()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createOrganization(name)
    navigate(LinkKey.Onboarding)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-main-bg">
      <form
        className="p-6 w-[360px] flex flex-col items-start justify-start gap-6 rounded-[4px] border border-panel-border"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h1 className="text-h1 text-text-1">Create an Organization</h1>
          <p className="text-body text-text-1">
            Your organization is the top-level structure with all your
            teammates, projects, and data. We recommend naming it after your
            company.
          </p>
        </div>
        <LabeledInput
          label="Organization Name"
          value={name}
          onChange={(e) => setName(e)}
          placeholder="My Company"
        />
        <CTAButton type="submit" disabled={!name}>
          Create Organization
        </CTAButton>
      </form>
    </div>
  )
}

export default NewOrganization
