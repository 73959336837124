import { useAtomValue } from 'jotai'
import { AlertInfo, ContactChannel } from '../../../../../../backend/types'
import { contactChannelsAtom } from '../../../../../../state/state'
import { formatTimestamp } from '../../../../../../utils/time'
import IconButton from '../../../../../library/IconButton/IconButton'
import { Bell, BellOff, Pencil, Trash } from 'lucide-react'

interface AlertRowProps {
  info: AlertInfo
  onOpen: () => void
  onMute: () => void
  onEdit: () => void
  onDelete: () => void
}

const AlertRow = ({
  info,
  onOpen,
  onMute,
  onEdit,
  onDelete,
}: AlertRowProps) => {
  const contactChannels = useAtomValue(contactChannelsAtom)

  return (
    <div className="px-8 flex flex-row items-center gap-2 border-b border-main-border">
      <div className="w-[200px] py-4 flex flex-row items-center">
        <p
          onClick={onOpen}
          className="text-small text-text-1 hover:text-brand-cta hover:underline cursor-pointer"
        >
          {getName(info)}
        </p>
      </div>
      <div className="hidden xl:flex w-[200px] py-4 flex-row items-center">
        <p className="text-small text-text-1">
          {getContactChannelName(info, contactChannels)}
        </p>
      </div>
      <div className="hidden lg:flex w-[200px] py-4 flex-row items-center">
        <p className="text-small text-text-1">{getLastEventTime(info)}</p>
      </div>
      <div className="hidden xl:flex w-[100px] py-4 flex-row items-center">
        <p className="text-small text-text-1">{getStatus(info)}</p>
      </div>
      <div className="hidden md:flex w-[80px] py-4 flex-row items-center">
        <p className="text-small text-text-1">{getEventTotal(info)}</p>
      </div>
      <div className="hidden md:flex flex-1 py-4 flex-row items-center">
        <p className="text-small text-text-1">{getEventTotal24hr(info)}</p>
      </div>
      <IconButton
        size="32"
        icon={
          info.alert.status === 'muted' ? (
            <BellOff className="w-4 h-4" />
          ) : (
            <Bell className="w-4 h-4" />
          )
        }
        onClick={onMute}
      />
      <IconButton
        size="32"
        icon={<Pencil className="w-4 h-4" />}
        onClick={onEdit}
      />
      <IconButton
        size="32"
        icon={<Trash className="w-4 h-4" />}
        onClick={onDelete}
      />
    </div>
  )
}

function getName(info: AlertInfo) {
  return info.alert.name
}

function getStatus(info: AlertInfo) {
  switch (info.alert.status) {
    case 'active':
      return <span className="text-brand-success">Active</span>
    case 'muted':
      return <span className="text-text-2">Muted</span>
  }
}

function getContactChannelName(
  info: AlertInfo,
  contactChannels: ContactChannel[],
) {
  const found = contactChannels.find(
    (c) => c.id === info.contact_channels[0].contact_channel_id,
  )
  if (!found) return 'Not found'
  return found.name
}

function getLastEventTime(info: AlertInfo) {
  return info.last_event_time ? (
    <span className="text-text-1">{formatTimestamp(info.last_event_time)}</span>
  ) : (
    <span className="text-text-2">Never</span>
  )
}

function getEventTotal(info: AlertInfo) {
  return info.event_total === 0 ? (
    <span className="text-text-2">0</span>
  ) : (
    <span className="text-text-1">{info.event_total}</span>
  )
}

function getEventTotal24hr(info: AlertInfo) {
  return info.event_total_24hr === 0 ? (
    <span className="text-text-2">0</span>
  ) : (
    <span className="text-text-1">{info.event_total_24hr}</span>
  )
}

export default AlertRow
