import {
  LoadMoreResponseSuccess,
  QueryParameters,
  QueryResponseSuccess,
  QueryState,
} from './types'

export type MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: QueryResponseSuccess | LoadMoreResponseSuccess,
) => QueryState

export const loadMoreMerge: MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: QueryResponseSuccess | LoadMoreResponseSuccess,
): QueryState => {
  if (result.type !== 'loadMore') return prevState
  return {
    ...state,
    result: {
      count: prevState.result?.count ?? 0,
      properties: prevState.result?.properties ?? [],
      serviceOptions: prevState.result?.serviceOptions ?? [],
      levelOptions: prevState.result?.levelOptions ?? [],
      logs: [...(prevState.result?.logs || []), ...result.logs],
      done: result.logs.length === 0,
    },
    mode: 'none',
  }
}

export const defaultMerge: MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: QueryResponseSuccess | LoadMoreResponseSuccess,
): QueryState => {
  if (result.type !== 'query') return prevState
  return {
    ...state,
    result: result,
    mode: 'none',
  }
}

export const createInitialState = (index: number): QueryState => {
  return {
    index: index,
    mode: 'initial_load',
    request: {
      term: '',
      timerange: { start: '12h', end: undefined },
      filters: [],
      properties: readSavedProperties(),
      limit: 100,
      cursor: undefined,
    },
    controller: new AbortController(),
    result: null,
  }
}

export const createQueryState = (
  index: number,
  params: QueryParameters,
): QueryState => {
  return {
    index: index,
    mode: 'user_query',
    request: {
      term: params.term,
      timerange: params.timeRange,
      filters: params.filters,
      properties: params.properties,
      limit: params.limit,
      cursor: params.cursor,
    },
    controller: new AbortController(),
    result: null,
  }
}

export const createLiveTickState = (
  index: number,
  queryState: QueryState,
): QueryState => {
  return {
    ...queryState,
    request: {
      ...queryState.request,
      cursor: undefined,
      limit: 100,
    },
    index: index,
    mode: 'live_tick',
    controller: new AbortController(),
  }
}

export const createLoadMoreState = (
  index: number,
  queryState: QueryState,
): QueryState => {
  return {
    index: index,
    mode: 'loading_more',
    request: {
      ...queryState.request,
      cursor: queryState.result?.logs[queryState.result.logs.length - 1],
    },
    result: queryState.result
      ? {
          ...queryState.result,
        }
      : null,
    controller: new AbortController(),
  }
}

export function readSavedProperties(): string[] {
  const storedHeaders = localStorage.getItem('logsVisibleProperties')
  if (!storedHeaders) {
    return []
  }

  try {
    const headers = JSON.parse(storedHeaders)
    if (!Array.isArray(headers)) {
      return []
    }
    return headers.map((h) => h.value)
  } catch {
    return []
  }
}
