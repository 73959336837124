import useLoadOrganizations from '../data/load/useLoadOrganizations'
import useBackend from '../services/useBackend'
import useSetInstance from './useSetInstance'

const useJoinOrganization = () => {
  const backend = useBackend()

  const switchInstance = useSetInstance()
  const loadOrganizations = useLoadOrganizations()

  const joinOrganization = async (code: string) => {
    const { organization_id } = await backend.joinOrganization(code)
    await loadOrganizations()
    await switchInstance(organization_id, undefined)
  }

  return joinOrganization
}

export default useJoinOrganization
