import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'

const useCreateOrganizationInvite = () => {
  const backend = useBackend()

  const { organizationId } = useAtomValue(instanceIdAtom)

  const createOrganizationInvite = async (email: string) => {
    if (!organizationId) return
    const response = await backend.createOrganizationInvite(
      organizationId,
      email,
    )
    return response.token
  }

  return createOrganizationInvite
}

export default useCreateOrganizationInvite
