import { List } from 'lucide-react'
import PanelRow from '../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../library/PanelSection/PanelSection'
import RowButton from '../../../../library/RowButton/RowButton'
import { alertsAtom } from '../../../../../state/state'
import { useAtomValue } from 'jotai'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'

const AlertsSidebar = () => {
  const navigate = useNav()

  const alerts = useAtomValue(alertsAtom)

  return (
    <div className="flex-none w-[264px] h-full flex flex-col border-r border-main-border">
      <PanelSection>
        <PanelRow>
          <h2 className="text-small-header text-text-1">General</h2>
        </PanelRow>
        <RowButton
          text="Alerts List"
          active={isBaseAlertsPage()}
          icon={<List className="w-4 h-4" />}
          onClick={() => navigate(LinkKey.Alerts)}
        />
      </PanelSection>
      <PanelSection bottom={true}>
        <PanelRow>
          <h2 className="text-small-header text-text-1">Your Alerts</h2>
        </PanelRow>
        {alerts.map((alert) => (
          <RowButton
            key={alert.alert.id}
            text={alert.alert.name}
            active={isSelected(alert.alert.id)}
            onClick={() => navigate(LinkKey.Alert, { id: alert.alert.id })}
          />
        ))}
      </PanelSection>
    </div>
  )
}

const isSelected = (id: string) => {
  const url = new URL(window.location.href)
  const alertID = url.pathname.split('/')[2]
  return alertID === id
}

const isBaseAlertsPage = () => {
  const url = new URL(window.location.href)
  return url.pathname === '/alerts'
}

export default AlertsSidebar
