import { cn } from '../../../utils/cn'

interface PanelSectionProps {
  children: React.ReactNode
  mode?: 'default' | 'popup'
  bottom?: boolean
  className?: string
}

const PanelSection = ({
  children,
  mode = 'default',
  bottom = false,
  className,
}: PanelSectionProps) => {
  const borderColor = getBorderColor(mode)

  return (
    <div
      className={cn(
        'px-4 py-3 w-full flex flex-col gap-1',
        borderColor,
        className,
        bottom ? '' : 'border-b',
      )}
    >
      {children}
    </div>
  )
}

function getBorderColor(mode: 'default' | 'popup') {
  switch (mode) {
    case 'default':
      return 'border-main-border'
    case 'popup':
      return 'border-popup-border'
  }
}

export default PanelSection
