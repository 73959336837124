import { Plus, X } from 'lucide-react'
import IconButton from '../../IconButton/IconButton'
import PanelRow from '../../PanelRow/PanelRow'
import PanelSection from '../../PanelSection/PanelSection'
import {
  AlertTriggerLogClause,
  AlertTriggerLogSettings,
  FilterOperator,
} from '../../../../backend/types'
import CodeInput from '../../CodeInput/CodeInput'
import CodeDropdown from '../../CodeDropdown/CodeDropdown'
import useQuery from '../../../../hooks/services/useQuery'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../../../state/state'
import { propertyToDisplay } from '../../../../utils/properties'
import LoadingDots from '../../LoadingDots/LoadingDots'

interface LogFiltersProps {
  settings: AlertTriggerLogSettings
  setSettings: (settings: AlertTriggerLogSettings) => void
}

const LogFilters: React.FC<LogFiltersProps> = ({ settings, setSettings }) => {
  const query = useQuery()

  const { projectId } = useAtomValue(instanceIdAtom)

  const [loadedAttributes, setLoadedAttributes] = useState(false)
  const [attributesOptions, setAttributesOptions] = useState<string[]>([])

  const fetchAttributesOptions = useCallback(async () => {
    if (!projectId) return
    const { list } = await query.logsAttributeList({
      project_id: projectId,
    })
    if (!list) return
    setAttributesOptions(list.map((item) => item.value))
    setLoadedAttributes(true)
  }, [query, projectId])

  const addClause = () => {
    const newClause: AlertTriggerLogClause = {
      field: 'body',
      operator: 'contains',
      value: '',
    }
    setSettings({ ...settings, clauses: [...settings.clauses, newClause] })
  }

  const removeClause = (index: number) => {
    const newClauses = [...settings.clauses]
    newClauses.splice(index, 1)
    setSettings({ ...settings, clauses: newClauses })
  }

  const updateClauseField = (index: number, field: string, value: string) => {
    const newClauses = [...settings.clauses]
    newClauses[index] = { ...newClauses[index], [field]: value }
    setSettings({ ...settings, clauses: newClauses })
  }

  const updateClauseOperator = (index: number, operator: FilterOperator) => {
    const newClauses = [...settings.clauses]
    newClauses[index] = { ...newClauses[index], operator: operator }
    setSettings({ ...settings, clauses: newClauses })
  }

  const updateClauseValue = (index: number, value: string) => {
    const newClauses = [...settings.clauses]
    newClauses[index] = { ...newClauses[index], value: value }
    setSettings({ ...settings, clauses: newClauses })
  }

  useEffect(() => {
    if (!projectId) return
    fetchAttributesOptions()
  }, [projectId, fetchAttributesOptions])

  const dropdownOptions = useMemo(
    () => getDropdownOptions(attributesOptions),
    [attributesOptions],
  )

  return (
    <PanelSection className="gap-2">
      <PanelRow>
        <p className="text-small-header text-text-1">Log Filters</p>
        {loadedAttributes && <IconButton icon={<Plus />} onClick={addClause} />}
      </PanelRow>
      {!loadedAttributes && (
        <PanelRow>
          <div className="text-small-code text-text-2 flex items-center">
            Loading filter options
            <LoadingDots />
          </div>
        </PanelRow>
      )}
      {loadedAttributes &&
        settings.clauses.map((clause, index) => (
          <PanelRow key={index}>
            <CodeDropdown
              selected={clause.field}
              options={dropdownOptions}
              onChange={(e) => updateClauseField(index, 'field', e)}
            />
            <CodeDropdown
              selected={clause.operator}
              options={filterOperatorOptions}
              onChange={(e) => updateClauseOperator(index, e)}
            />
            <CodeInput
              value={clause.value}
              placeholder="Value"
              onChange={(e) => updateClauseValue(index, e)}
            />
            {index < settings.clauses.length - 1 &&
              settings.clauses.length > 1 && (
                <span className="text-text-2 text-small-code">and</span>
              )}
            {index === settings.clauses.length - 1 &&
              settings.clauses.length > 1 && (
                <div className="flex-none w-[21.6px] h-full"></div>
              )}
            <IconButton icon={<X />} onClick={() => removeClause(index)} />
          </PanelRow>
        ))}
    </PanelSection>
  )
}

const filterOperatorOptions: { label: string; value: FilterOperator }[] = [
  { label: 'equals', value: 'equals' },
  { label: "doesn't equal", value: 'not_equals' },
  { label: 'contains', value: 'contains' },
  { label: "doesn't contain", value: 'not_contains' },
]

const columns = ['body', 'severity_name']

function getDropdownOptions(
  attributes: string[],
): { label: string; value: string }[] {
  return [
    ...columns.map((column) => ({
      label: propertyToDisplay(column),
      value: column,
    })),
    ...attributes.map((attribute) => ({
      label: propertyToDisplay(attribute),
      value: attribute,
    })),
  ]
}

export default LogFilters
