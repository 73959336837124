import { useEffect } from 'react'
import useQueryLiveTick from '../../../../hooks/stateServices/useQueryLiveTick'
import { genKey } from '../../../../utils/sub'
import { QueryState } from '../../../../library/query/types'
import { useState } from 'react'
import Terminal from '../../../library/Terminal/Terminal'
import useQueryManager from '../../../../hooks/stateServices/useQueryManager'

const LogPreview = () => {
  const queryManager = useQueryManager()
  useQueryLiveTick(queryManager)

  const [logState, setLogState] = useState<QueryState | undefined>(undefined)

  useEffect(() => {
    if (!queryManager) return
    const key = genKey()
    queryManager.subscribe(key, setLogState)
    queryManager.init()
    return () => {
      queryManager.unsubscribe(key)
    }
  }, [queryManager])

  return (
    <div className="w-full flex flex-col items-start justify-start gap-2">
      <h2 className="text-h2 text-text-1">View logs</h2>
      <Terminal
        className="w-full h-[220px]"
        content={logState?.result?.logs.map((log) => log.body) ?? []}
      />
    </div>
  )
}

export default LogPreview
