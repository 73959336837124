import { AlertEvent } from '../../../../../../backend/types'
import { cn } from '../../../../../../utils/cn'
import { formatTimestamp } from '../../../../../../utils/time'
import { logDetailsAtom } from '../../../../../library/LogDetails/LogDetails'
import { useAtom } from 'jotai'

interface EventRowProps {
  event: AlertEvent
}

const EventRow: React.FC<EventRowProps> = ({ event }) => {
  const [logDetails, setLogDetails] = useAtom(logDetailsAtom)
  const isOpen =
    logDetails.log?.log_id === event.details.log.log_id &&
    logDetails.log?.log_id !== '00000000-0000-0000-0000-000000000000'

  return (
    <div
      className={cn(
        'event-row w-full px-8 py-4 flex items-center gap-2 border-b border-main-border cursor-pointer hover:bg-input-tint',
        isOpen ? 'bg-input-tint' : 'bg-transparent',
      )}
      onClick={() => setLogDetails({ log: event.details.log, type: 'alert' })}
    >
      <p className="w-[120px] text-small text-text-1">
        {formatTimestamp(event.created_at, {
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })}
      </p>
      <p className="flex-1 text-small-code text-text-1 break-all">
        {event.details.log.body}
      </p>
    </div>
  )
}

export default EventRow
