import { QueryRunner } from './queryRunner'
import { QueryParameters, QueryState } from './types'
import {
  createInitialState,
  createQueryState,
  createLiveTickState,
  createLoadMoreState,
  MergeFunction,
  loadMoreMerge,
  defaultMerge,
} from './utils'

export type QuerySubscriber = (queryState: QueryState) => void

export class QueryManager {
  private queryRunner: QueryRunner

  private queryMap: { [key: number]: QueryState }
  private queryIndex: number

  private subscribers: { [key: string]: QuerySubscriber }

  constructor(queryRunner: QueryRunner) {
    this.queryRunner = queryRunner

    this.queryMap = {}
    this.queryIndex = 0
    this.subscribers = {}
  }

  subscribe = (key: string, subscriber: QuerySubscriber) => {
    this.subscribers[key] = subscriber
  }

  unsubscribe = (key: string) => {
    delete this.subscribers[key]
  }

  init = async () => {
    const index = this.queryIndex++
    const state = createInitialState(index)

    this.runQuery(state, state, defaultMerge)
  }

  query = async (params: QueryParameters) => {
    const index = this.queryIndex++
    const state = createQueryState(index, params)

    this.runQuery(state, state, defaultMerge)
  }

  loadMore = async (prevState: QueryState) => {
    if (this.isLoadingMore()) return
    const index = this.queryIndex++
    const state = createLoadMoreState(index, prevState)

    this.runLoadMore(prevState, state, loadMoreMerge)
  }

  liveTick = async (prevState: QueryState) => {
    const index = this.queryIndex++
    const state = createLiveTickState(index, prevState)

    this.runQuery(prevState, state, defaultMerge)
  }

  cancelLiveTick = () => {
    for (const query of Object.values(this.queryMap)) {
      if (query.mode === 'live_tick') {
        query.controller.abort()
      }
    }
    this.publishMaxQuery()
  }

  private runQuery = async (
    prevState: QueryState,
    state: QueryState,
    mergeFn: MergeFunction,
  ) => {
    this.queryMap[state.index] = state
    this.cancelLowerQueries(state.index)
    this.publishMaxQuery()

    const result = await this.queryRunner.query(
      state.request,
      state.controller.signal,
    )
    if (result.result === 'canceled') {
      state.mode = 'none'
      this.publishMaxQuery()
      return
    }

    this.queryMap[state.index] = mergeFn(prevState, state, result)
    this.publishMaxQuery()
  }

  private runLoadMore = async (
    prevState: QueryState,
    state: QueryState,
    mergeFn: MergeFunction,
  ) => {
    this.queryMap[state.index] = state
    this.cancelLowerQueries(state.index)
    this.publishMaxQuery()

    const result = await this.queryRunner.loadMore(
      state.request,
      state.controller.signal,
    )
    if (result.result === 'canceled') {
      state.mode = 'none'
      this.publishMaxQuery()
      return
    }

    this.queryMap[state.index] = mergeFn(prevState, state, result)
    this.publishMaxQuery()
  }

  private cancelLowerQueries = (index: number): void => {
    for (const query of Object.values(this.queryMap)) {
      if (query.index < index) {
        query.controller.abort()
      }
    }
  }

  private publishMaxQuery = (): void => {
    const maxQuery = Object.values(this.queryMap).reduce((max, query) => {
      return Math.max(max, query.index)
    }, 0)

    const queryState = this.queryMap[maxQuery]
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(queryState)
    }
  }

  private isLoadingMore = (): boolean => {
    const maxQuery = Object.values(this.queryMap).reduce((max, query) => {
      return Math.max(max, query.index)
    }, 0)

    const queryState = this.queryMap[maxQuery]
    if (!queryState) return false

    return queryState.mode === 'loading_more'
  }
}
