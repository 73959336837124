import { useEffect } from 'react'
import { colors, setBrandCTA } from '../../utils/setBrandColor'

const useSetBrandColor = (color: keyof typeof colors) => {
  useEffect(() => {
    setBrandCTA(color)
  }, [color])
}

export default useSetBrandColor
