import { sha256 } from 'js-sha256'
import { AuthService } from './auth'
import { HTTPService } from './http'
import {
  UserAuthResponse,
  GetUserResponse,
  AuthResponse,
  GetOrganizationResponse,
  CreateOrganizationResponse,
  CreateProjectResponse,
  GetProjectResponse,
  GetOrganizationsResponse,
  CreateUserResponse,
  GetOrganizationProjectsResponse,
  AuthRefreshResponse,
  CreateTokenResponse,
  GetTokensResponse,
  GetOrganizationMembersResponse,
  OrganizationMemberJoinResponse,
  CreateOrganizationInviteResponse,
  IntegrationCreateRequest,
  GetIntegrationsResponse,
  GetContactChannelsResponse,
  ContactChannelCreateRequest,
  ContactChannelUpdateRequest,
  GetAlertsResponse,
  ContactChannelCreateResponse,
  AlertCreateRequest,
  AlertUpdateRequest,
  GetAlertEventsResponse,
  GetIntegrationExternalDetailsResponse,
} from './types'

export class BackendService {
  private httpService: HTTPService
  private authService: AuthService

  constructor(httpService: HTTPService, authService: AuthService) {
    this.httpService = httpService
    this.authService = authService
  }

  sendSupport = async (message: string): Promise<void> => {
    return this.httpService.request<void>(
      '/api/user/support',
      'POST',
      { message },
      this.getAuthHeaders(),
    )
  }

  login = async (
    email: string,
    password: string,
  ): Promise<UserAuthResponse> => {
    return this.httpService
      .request<UserAuthResponse>('/api/login', 'POST', {
        email: email,
        password: sha256(password),
      })
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  authOrganization = async (organization_id: string): Promise<AuthResponse> => {
    return this.httpService
      .request<AuthResponse>(
        '/api/auth',
        'POST',
        {
          type: 'organization',
          organization_id,
        },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  authProject = async (
    organization_id: string,
    project_id: string,
  ): Promise<AuthResponse> => {
    return this.httpService
      .request<AuthResponse>(
        '/api/auth',
        'POST',
        {
          type: 'project',
          organization_id,
          project_id,
        },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  refresh = async (): Promise<AuthRefreshResponse> => {
    return this.httpService
      .request<AuthRefreshResponse>(
        '/api/refresh',
        'POST',
        {},
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  getUser = async (): Promise<GetUserResponse> => {
    return this.httpService.request<GetUserResponse>(
      '/api/user',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createUser = async (
    email: string,
    password: string,
  ): Promise<CreateUserResponse> => {
    const passwordHash = sha256(password)
    return this.httpService
      .request<CreateUserResponse>(
        '/api/user',
        'POST',
        { email, password: passwordHash },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  getOrganization = async (
    organization_id: string,
  ): Promise<GetOrganizationResponse> => {
    return this.httpService.request<GetOrganizationResponse>(
      `/api/organization/${organization_id}`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createOrganization = async (
    name: string,
  ): Promise<CreateOrganizationResponse> => {
    return this.httpService.request<CreateOrganizationResponse>(
      '/api/organization',
      'POST',
      { name },
      this.getAuthHeaders(),
    )
  }

  getOrganizationMembers = async (
    organization_id: string,
  ): Promise<GetOrganizationMembersResponse> => {
    return this.httpService.request<GetOrganizationMembersResponse>(
      `/api/organization/${organization_id}/members`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  deleteOrganizationMember = async (
    organization_id: string,
    user_id: string,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}/member/${user_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getOrganizations = async (): Promise<GetOrganizationsResponse> => {
    return this.httpService.request<GetOrganizationsResponse>(
      `/api/organizations`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createOrganizationInvite = async (
    organization_id: string,
    email: string,
  ): Promise<CreateOrganizationInviteResponse> => {
    return this.httpService.request<CreateOrganizationInviteResponse>(
      `/api/organization/${organization_id}/invite`,
      'POST',
      { email },
      this.getAuthHeaders(),
    )
  }

  updateOrganization = async (
    organization_id: string,
    name: string,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}`,
      'PUT',
      { name },
      this.getAuthHeaders(),
    )
  }

  deleteOrganization = async (organization_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  joinOrganization = async (
    token: string,
  ): Promise<OrganizationMemberJoinResponse> => {
    return this.httpService.request<OrganizationMemberJoinResponse>(
      '/api/organization/join',
      'POST',
      { token },
      this.getAuthHeaders(),
    )
  }

  getProjects = async (
    organization_id: string,
  ): Promise<GetOrganizationProjectsResponse> => {
    return this.httpService.request<GetOrganizationProjectsResponse>(
      `/api/organization/${organization_id}/projects`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  getProject = async (project_id: string): Promise<GetProjectResponse> => {
    return this.httpService.request<GetProjectResponse>(
      `/api/project/${project_id}`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createProject = async (
    organization_id: string,
    name: string,
  ): Promise<CreateProjectResponse> => {
    return this.httpService.request<CreateProjectResponse>(
      '/api/project',
      'POST',
      { organization_id, name },
      this.getAuthHeaders(),
    )
  }

  updateProject = async (project_id: string, name: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}`,
      'PUT',
      { name },
      this.getAuthHeaders(),
    )
  }

  deleteProject = async (project_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getProjectTokens = async (project_id: string): Promise<GetTokensResponse> => {
    return this.httpService.request<GetTokensResponse>(
      `/api/project/${project_id}/tokens`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createProjectToken = async (
    project_id: string,
    label: string,
    description: string,
  ): Promise<CreateTokenResponse> => {
    return this.httpService.request<CreateTokenResponse>(
      `/api/project/${project_id}/token`,
      'POST',
      { label, description },
      this.getAuthHeaders(),
    )
  }

  deleteProjectToken = async (
    project_id: string,
    id: string,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}/token/${id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getIntegrations = async (): Promise<GetIntegrationsResponse> => {
    return this.httpService.request<GetIntegrationsResponse>(
      '/api/integrations',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createIntegration = async (
    request: IntegrationCreateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      '/api/integration',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  getIntegrationExternalDetails = async (
    integration_id: string,
  ): Promise<GetIntegrationExternalDetailsResponse> => {
    return this.httpService.request<GetIntegrationExternalDetailsResponse>(
      `/api/integration/${integration_id}/external`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  getContactChannels = async (): Promise<GetContactChannelsResponse> => {
    return this.httpService.request<GetContactChannelsResponse>(
      '/api/channels',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createContactChannel = async (
    request: ContactChannelCreateRequest,
  ): Promise<ContactChannelCreateResponse> => {
    return this.httpService.request<ContactChannelCreateResponse>(
      '/api/channel',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  updateContactChannel = async (
    contact_channel_id: string,
    request: ContactChannelUpdateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/channel/${contact_channel_id}`,
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  deleteContactChannel = async (contact_channel_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/channel/${contact_channel_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getAlerts = async (): Promise<GetAlertsResponse> => {
    return this.httpService.request<GetAlertsResponse>(
      '/api/alerts',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createAlert = async (request: AlertCreateRequest): Promise<void> => {
    return this.httpService.request<void>(
      '/api/alert',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  updateAlert = async (
    alert_id: string,
    request: AlertUpdateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/alert/${alert_id}`,
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  deleteAlert = async (alert_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/alert/${alert_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getAlertEvents = async (
    alert_id: string,
  ): Promise<GetAlertEventsResponse> => {
    return this.httpService.request<GetAlertEventsResponse>(
      `/api/alert/${alert_id}/events`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  private getAuthHeaders = (): Record<string, string> => {
    const headers: Record<string, string> = {}

    const token = this.authService.getUserToken()
    if (token !== '') {
      headers.Authorization = `Bearer ${token}`
    }

    const sessionId = this.authService.getUserSessionId()
    if (sessionId !== '') {
      headers['X-Session-Id'] = sessionId
    }

    const userEmail = this.authService.getUserEmail()
    if (userEmail !== '') {
      headers['X-User-Email'] = userEmail
    }

    return headers
  }
}
