import HelpPopup from '../HelpPopup/HelpPopup'
import Button from '../Button/Button'
import { atom, useAtom } from 'jotai'

export const helpButtonAtom = atom(false)

const HelpButton = () => {
  const [isOpen, setIsOpen] = useAtom(helpButtonAtom)

  return (
    <>
      <Button onClick={() => setIsOpen(!isOpen)}>Feedback</Button>
      {isOpen && <HelpPopup close={() => setIsOpen(false)} />}
    </>
  )
}

export default HelpButton
