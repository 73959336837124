import useLoadContactChannels from '../data/load/useLoadContactChannels'
import useBackend from '../services/useBackend'

const useDeleteContactChannel = () => {
  const backend = useBackend()

  const loadContactChannels = useLoadContactChannels()

  const deleteContactChannel = async (contactChannelId: string) => {
    await backend.deleteContactChannel(contactChannelId)
    await loadContactChannels()
  }

  return deleteContactChannel
}

export default useDeleteContactChannel
