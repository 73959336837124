import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../hooks/utils/useClickOutside'
import Fullscreen from '../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import LabeledInput from '../../../../../library/LabeledInput/LabeledInput'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import { User } from '../../../../../../state/types'
import { userInfoAtom } from '../../../../../../state/state'
import { useAtomValue } from 'jotai'

interface InviteTeammatePopupProps {
  onSubmit: (email: string) => Promise<void>
  close: () => void
}

const InviteTeammatePopup: React.FC<InviteTeammatePopupProps> = ({
  onSubmit,
  close,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const userInfo = useAtomValue(userInfoAtom)

  const [email, setEmail] = useState('')

  const handleSubmit = async () => {
    await onSubmit(email)
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Invite Teammate</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection>
          <p className="text-body text-text-1">
            Enter the email of the person you want to invite to your
            organization. They will receive an email with a link to join the
            organization.
          </p>
        </PanelSection>
        <PanelSection className="gap-2">
          <LabeledInput
            label="Email"
            placeholder="email@mycompany.com"
            value={email}
            onChange={(v) => setEmail(v)}
          />
        </PanelSection>
        <PanelSection bottom={true}>
          <div className="w-fit">
            <CTAButton
              disabled={!isValidEmail(email, userInfo)}
              onClick={handleSubmit}
            >
              Send Invite
            </CTAButton>
          </div>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

function isValidEmail(email: string, userInfo: User) {
  return (
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
    !userInfo.email.includes(email) &&
    !userInfo.email.includes(email.toLowerCase())
  )
}

export default InviteTeammatePopup
