import SettingsSection from '../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../library/SettingsTitle/SettingsTitle'
import { helpButtonAtom } from '../../../../library/Navbar/HelpButton'
import { useAtomValue, useSetAtom } from 'jotai'
import { helpPopupModeAtom } from '../../../../library/HelpPopup/HelpPopup'
import {
  contactChannelsAtom,
  integrationsAtom,
} from '../../../../../state/state'
import useInitIntegrations from '../../../../../hooks/data/effects/useInitIntegrations'
import {
  ContactChannel,
  Integration,
  IntegrationType,
} from '../../../../../backend/types'
import SettingsTable from '../../../../library/SettingsTable/SettingsTable'
import SettingsTableColumn from '../../../../library/SettingsTable/SettingsTableColumn'
import SettingsTableHeader from '../../../../library/SettingsTable/SettingsTableHeader'
import SettingsTableCell from '../../../../library/SettingsTable/SettingsTableCell'
import ConnectButton from './ConnectButton/ConnectButton'
import useLoadIntegrations from '../../../../../hooks/data/load/useLoadIntegrations'
import useInitContactChannels from '../../../../../hooks/data/effects/useInitContactChannels'
import _ from 'lodash'
import { useState } from 'react'
import ContactChannelPopup from '../../../../library/ContactChannelPopup/ContactChannelPopup'
import useCreateContactChannel from '../../../../../hooks/actions/useCreateContactChannel'
import SettingsTableIcon from '../../../../library/SettingsTable/SettingsTableIcon'
import { Pencil, Trash } from 'lucide-react'
import useDeleteContactChannel from '../../../../../hooks/actions/useDeleteContactChannel'
import DeleteContactChannelPopup from './DeleteContactChannelPopup/DeleteContactChannelPopup'
import useUpdateContactChannel from '../../../../../hooks/actions/useUpdateContactChannel'

const AlertSettings = () => {
  useInitContactChannels()
  useInitIntegrations()

  const loadIntegrations = useLoadIntegrations()
  const createContactChannel = useCreateContactChannel()
  const updateContactChannel = useUpdateContactChannel()
  const deleteContactChannel = useDeleteContactChannel()

  const setHelpButton = useSetAtom(helpButtonAtom)
  const setHelpMode = useSetAtom(helpPopupModeAtom)

  const contactChannels = useAtomValue(contactChannelsAtom)
  const integrations = useAtomValue(integrationsAtom)

  const [createIsOpen, setCreateIsOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [deletingId, setDeletingId] = useState<string | null>(null)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)

  const handleCreateContactChannel = async (
    contactChannel: Omit<ContactChannel, 'id'>,
  ) => {
    setCreateIsOpen(false)
    await createContactChannel(contactChannel)
  }

  const handleDeleteContactChannel = async () => {
    if (!deletingId) return
    setDeleteIsOpen(true)
    await deleteContactChannel(deletingId)
    setDeletingId(null)
  }

  const handleUpdateContactChannel = async (
    contactChannel: Omit<ContactChannel, 'id'>,
  ) => {
    setEditIsOpen(false)
    if (!editingId) return
    await updateContactChannel(editingId, contactChannel)
  }

  const editingContactChannel = contactChannels.find(
    (contactChannel) => contactChannel.id === editingId,
  )

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Alert Destinations"
          description="These are all the places you can send alerts. Alert destinations are shared across all projects in the organization."
          button="New Destination"
          onClick={() => setCreateIsOpen(true)}
        />
        <SettingsTable>
          <SettingsTableColumn flex={false} width={'200px'}>
            <SettingsTableHeader content="Name" />
            {contactChannels.map((contactChannel) => (
              <SettingsTableCell
                key={contactChannel.id}
                content={contactChannel.name}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false} width={'200px'}>
            <SettingsTableHeader content="Type" />
            {contactChannels.map((contactChannel) => (
              <SettingsTableCell
                key={contactChannel.id}
                content={_.capitalize(contactChannel.type)}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <div className="h-[40px] border-b border-panel-border"></div>
            {contactChannels.map((contactChannel) => (
              <div
                key={contactChannel.id}
                className="h-[40px] border-b border-panel-border"
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {contactChannels.map((contactChannel) => (
              <SettingsTableIcon
                key={contactChannel.id}
                icon={
                  <Pencil className="w-4 h-4 text-text-2 hover:text-text-1" />
                }
                onClick={() => {
                  setEditingId(contactChannel.id)
                  setEditIsOpen(true)
                }}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {contactChannels.map((contactChannel) => (
              <SettingsTableIcon
                key={contactChannel.id}
                icon={
                  <Trash className="w-4 h-4 text-text-2 hover:text-text-1" />
                }
                onClick={() => {
                  setDeletingId(contactChannel.id)
                  setDeleteIsOpen(true)
                }}
              />
            ))}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Integrations"
          description="These are the external connections you've setup."
        />
        <SettingsTable>
          <SettingsTableColumn flex={false} width={'200px'}>
            <SettingsTableHeader content="Name" />
            {integrationOptions?.map((integration) => (
              <SettingsTableCell
                key={integration.type}
                content={integration.name}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false} width={'200px'}>
            <SettingsTableHeader content="Status" />
            {integrationOptions?.map((integration) => (
              <SettingsTableCell
                key={integration.type}
                color={
                  hasIntegration(integration.type, integrations)
                    ? 'text-brand-success'
                    : 'text-text-2'
                }
                content={
                  hasIntegration(integration.type, integrations)
                    ? 'Active'
                    : 'Inactive'
                }
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Description" />
            {integrationOptions?.map((integration) => (
              <SettingsTableCell
                key={integration.type}
                content={integration.description}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false} width={'100px'}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {integrationOptions?.map((integration) =>
              !hasIntegration(integration.type, integrations) ? (
                <ConnectButton
                  key={integration.type}
                  type={integration.type}
                  onConnect={async () => await loadIntegrations()}
                />
              ) : (
                <div
                  key={integration.type}
                  className="h-[40px] border-b border-panel-border"
                />
              ),
            )}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Request a contact method"
          description="We'll add contact methods regularly. If you have a request, please let us know!"
          button="Make Request"
          onClick={() => {
            setHelpMode('feedback')
            setHelpButton(true)
          }}
        />
      </SettingsSection>

      {createIsOpen && (
        <div className="relative z-[2]">
          <ContactChannelPopup
            onSubmit={handleCreateContactChannel}
            close={() => setCreateIsOpen(false)}
          />
        </div>
      )}

      {editIsOpen && editingContactChannel && (
        <div className="relative z-[2]">
          <ContactChannelPopup
            info={editingContactChannel}
            onSubmit={handleUpdateContactChannel}
            close={() => setEditIsOpen(false)}
          />
        </div>
      )}
      {deleteIsOpen && deletingId && (
        <div className="relative z-[2]">
          <DeleteContactChannelPopup
            name={
              contactChannels.find(
                (contactChannel) => contactChannel.id === deletingId,
              )?.name || 'Contact Channel'
            }
            onSubmit={handleDeleteContactChannel}
            close={() => setDeleteIsOpen(false)}
          />
        </div>
      )}
    </div>
  )
}

function hasIntegration(type: IntegrationType, integrations: Integration[]) {
  return integrations.some((integration) => integration.type === type)
}

const integrationOptions: {
  type: IntegrationType
  name: string
  description: string
}[] = [
  {
    type: 'slack',
    name: 'Slack',
    description: "Send alerts to your company's Slack",
  },
]

export default AlertSettings
