import { cn } from '../../../utils/cn'

interface CardButtonProps {
  children: React.ReactNode
  mode?: 'info' | 'success' | 'warning' | 'error'
  onClick?: () => void
  className?: string
}

const CardButton = ({
  children,
  mode = 'info',
  onClick,
  className,
}: CardButtonProps) => {
  const borderHoverColor = getBorderHoverColor(mode)

  return (
    <button
      className={cn(
        `p-6 gap-1 flex flex-col items-start justify-start bg-panel-bg rounded-[4px] border border-panel-border ${borderHoverColor}`,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function getBorderHoverColor(mode: CardButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'hover:border-brand-info'
    case 'success':
      return 'hover:border-brand-success'
    case 'warning':
      return 'hover:border-brand-warning'
    case 'error':
      return 'hover:border-brand-error'
  }
}

export default CardButton
