import { useEffect } from 'react'
import useLoadAlerts from '../load/useLoadAlerts'

const useInitAlerts = () => {
  const loadAlerts = useLoadAlerts()

  useEffect(() => {
    loadAlerts()
  }, [loadAlerts])
}

export default useInitAlerts
