import Divider from '../Divider/Divider'
import useClickOutside from '../../../hooks/utils/useClickOutside'
import { useRef } from 'react'
import RowButton from '../RowButton/RowButton'

export type NavPopupOption = {
  title: string
  value: string
  icon: React.ReactNode
  onClick?: () => void
  buttonRef?: React.RefObject<HTMLButtonElement | null>
}

interface NavPopupProps {
  title: string
  options: NavPopupOption[]
  selected: string | null
  close: () => void
  buttonRef?: React.RefObject<HTMLButtonElement | null>
}

const NavPopup = ({
  title,
  options,
  close,
  selected,
  buttonRef,
}: NavPopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => close(), buttonRef)

  return (
    <div
      ref={ref}
      className="absolute top-8 p-6 w-[264px] flex flex-col gap-2 bg-popup-bg border border-popup-border rounded-[4px]"
    >
      <p className="text-body text-text-1">{title}</p>
      <Divider mode="popup" />
      <div className="w-full flex flex-col gap-2 items-start">
        {options.map((option) => (
          <RowButton
            className="w-full"
            text={option.title}
            icon={option.icon}
            active={option.value === selected}
            key={option.value}
            onClick={option.onClick ?? (() => {})}
          />
        ))}
      </div>
    </div>
  )
}

export default NavPopup
