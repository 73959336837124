import { useRef } from 'react'

interface PanelInputProps {
  value: string
  valid?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  placeholder?: string
}

const PanelInput = ({
  value,
  valid = true,
  onChange,
  onBlur,
  placeholder,
}: PanelInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const validClass = valid ? 'border-transparent' : 'border-brand-error'

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
    }
  }

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  return (
    <input
      ref={inputRef}
      className={`px-3 py-1 w-full flex items-center justify-start text-small-code text-text-1 border ${validClass} placeholder:text-text-2 bg-input-bgTint hover:bg-input-tint focus:bg-input-tint border rounded-[4px] outline-none`}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      onFocus={handleFocus}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
    />
  )
}

export default PanelInput
