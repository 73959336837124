import { IntegrationType } from '../../../../../../backend/types'
import useConnectSlack from '../../../../../../hooks/utils/useConnectSlack'
import SettingsTableButton from '../../../../../library/SettingsTable/SettingsTableButton'

interface ConnectButtonProps {
  type: IntegrationType
  onConnect: () => Promise<void>
}

const ConnectButton = ({ type, onConnect }: ConnectButtonProps) => {
  switch (type) {
    case 'slack':
      return <ConnectButtonSlack onConnect={onConnect} />
    default:
      return <SettingsTableButton key={type} content={'Connect'} />
  }
}

interface ConnectButtonSlackProps {
  onConnect: () => Promise<void>
}

const ConnectButtonSlack = ({ onConnect }: ConnectButtonSlackProps) => {
  const connectSlack = useConnectSlack(onConnect)
  return <SettingsTableButton content={'Connect'} onClick={connectSlack} />
}

export default ConnectButton
