import { LogEntry } from '../state/types'
import {
  propertyToDisplay,
  ATTRIBUTE_PREFIX,
  COLUMN_LEVEL,
  COLUMN_BODY,
  COLUMN_TIMESTAMP,
} from './properties'
import { formatTimestamp } from './time'

export function getFormattedExtractor(
  column: string,
): (log: LogEntry) => string {
  switch (column) {
    case COLUMN_TIMESTAMP:
      return timestampExtractor
    case COLUMN_BODY:
      return bodyExtractor
    case COLUMN_LEVEL:
      return severityNameExtractor
    default:
      if (column.startsWith(ATTRIBUTE_PREFIX)) {
        const attribute = column.replace(ATTRIBUTE_PREFIX, '')
        return createAttributeExtractor(attribute)
      }
      return () => '-'
  }
}

function timestampExtractor(log: LogEntry) {
  return formatTimestamp(log.timestamp)
}

function bodyExtractor(log: LogEntry) {
  return cleanValue(log.body)
}

function severityNameExtractor(log: LogEntry) {
  return cleanValue(log.severity_name || '-')
}

function createAttributeExtractor(attribute: string) {
  return (log: LogEntry) => cleanValue(log.attributes?.[attribute] || '-')
}

function cleanValue(input: any) {
  if (typeof input !== 'string') return input
  // eslint-disable-next-line no-control-regex
  return input.replace(/(?:\u001b\[)[0-9;]*m/g, '')
}

export function getHeaderWidth(header: string, logs: LogEntry[]): number {
  if (logs.length === 0) {
    switch (header) {
      case COLUMN_TIMESTAMP:
        return 180
      case COLUMN_BODY:
        return 1000
      case COLUMN_LEVEL:
        return 100
      default:
        if (header.startsWith(ATTRIBUTE_PREFIX)) {
          return 400
        }
        return 100
    }
  }

  const extractor = getFormattedExtractor(header)

  const measureDiv = document.createElement('div')
  measureDiv.style.visibility = 'hidden'
  measureDiv.style.position = 'absolute'
  measureDiv.style.whiteSpace = 'pre'
  measureDiv.style.fontFamily = 'JetBrains Mono'
  measureDiv.style.fontSize = '12px'
  measureDiv.style.fontWeight = '300'
  document.body.appendChild(measureDiv)

  const maxWidth = logs.reduce((max, log) => {
    const content = extractor(log)
    measureDiv.textContent = content
    const width = measureDiv.offsetWidth
    return Math.max(max, width)
  }, 0)

  measureDiv.textContent = propertyToDisplay(header)
  const headerTextWidth = measureDiv.offsetWidth

  document.body.removeChild(measureDiv)

  return Math.min(Math.max(maxWidth, headerTextWidth) + 40, 1000)
}
