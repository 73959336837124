import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, contactChannelsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadContactChannels = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setContactChannels = useSetAtom(contactChannelsAtom)

  const loadContactChannels = useCallback(async () => {
    if (!projectId) return
    try {
      const { contact_channels } = await backend.getContactChannels()
      setContactChannels(contact_channels)
    } catch (e) {}
  }, [backend, setContactChannels, projectId])

  return loadContactChannels
}

export default useLoadContactChannels
