import { useState } from 'react'

interface IconButtonProps {
  icon: React.ReactNode
  size?: '24' | '32'
  disabled?: boolean
  onClick?: () => void
}

const IconButton = ({
  icon,
  size = '24',
  disabled = false,
  onClick,
}: IconButtonProps) => {
  const [hover, setHover] = useState(false)
  const hoverClass =
    hover || disabled ? 'text-text-1 bg-input-tint' : 'text-text-2'
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'
  const sizeClass = size === '24' ? 'p-1 w-6 h-6' : 'p-2 w-8 h-8'

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${sizeClass} flex items-center justify-center rounded ${hoverClass} ${disabledClass}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

export default IconButton
