import { cn } from '../../../utils/cn'

export type WordDetails<T> = {
  title: string
  value: T
  icon?: React.ReactNode
}

interface WordToggleProps<T> {
  words: WordDetails<T>[]
  active: T
  setActive: (value: T) => void
  className?: string
}

const WordToggle = <T,>({
  words,
  active,
  setActive,
  className,
}: WordToggleProps<T>) => {
  return (
    <div
      className={cn(
        'w-full flex border border-input-bgTint rounded-[4px] overflow-hidden',
        className,
      )}
    >
      {words.map((word) => (
        <Word
          key={word.title}
          title={word.title}
          value={word.value}
          icon={word.icon}
          active={active === word.value}
          setActive={setActive}
        />
      ))}
    </div>
  )
}

interface WordProps<T> {
  title: string
  value: T
  icon?: React.ReactNode
  active: boolean
  setActive: (value: T) => void
}

const Word = <T,>({ title, value, icon, active, setActive }: WordProps<T>) => {
  const activeClass = active
    ? 'text-brand-cta bg-brand-ctaTint'
    : 'text-text-1 bg-transparent hover:bg-input-tint'

  return (
    <button
      className={cn(
        'flex-1 basis-0 px-3 py-2 flex items-center justify-center gap-2 text-navlink outline-none',
        activeClass,
      )}
      onClick={() => setActive(value)}
    >
      {icon && (
        <div
          className={cn('w-4 h-4', active ? 'text-brand-cta' : 'text-text-2')}
        >
          {icon}
        </div>
      )}
      {title}
    </button>
  )
}

export default WordToggle
