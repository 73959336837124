import { atom } from 'jotai'

type AtomOptions<T> = {
  key: string
  defaultValue: T
  persistMode?: 'session' | 'local'
}

function persistAtom<T>({ key, defaultValue, persistMode }: AtomOptions<T>) {
  const storage = persistMode === 'local' ? localStorage : sessionStorage

  const getInitialValue = (): T => {
    const storedValue = storage.getItem(key)
    if (storedValue === null || storedValue === 'undefined') {
      return defaultValue
    } else {
      return JSON.parse(storedValue)
    }
  }

  const baseAtom = atom<T>(getInitialValue())

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (_, set, newValue: T) => {
      set(baseAtom, newValue)
      if (newValue === undefined || newValue === null) {
        storage.removeItem(key)
      } else {
        storage.setItem(key, JSON.stringify(newValue))
      }
    },
  )

  return derivedAtom
}

export default persistAtom
