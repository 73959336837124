import { useRef } from 'react'
import { cn } from '../../../utils/cn'

interface LabeledInputProps {
  label: string
  value: string
  type?: 'text' | 'password' | 'email' | 'number'
  onChange: (value: string) => void
  placeholder?: string

  id?: string
  className?: string
}

const LabeledInput = ({
  label,
  value,
  type = 'text',
  onChange,
  placeholder = '',
  id,
  className,
}: LabeledInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  return (
    <div
      className={cn(
        'w-full flex flex-col gap-2 items-start justify-start',
        className,
      )}
    >
      <p className="text-label text-text-2">{label}</p>
      <input
        ref={inputRef}
        id={id ? `${id}-input` : undefined}
        className="w-full h-9 px-3 py-1 text-label text-text-1 bg-input-bgTint border border-input-border rounded placeholder:text-text-2 hover:bg-input-tint focus:bg-input-tint outline-none"
        type={type}
        value={value}
        onFocus={handleFocus}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default LabeledInput
