import { useAtomValue, useSetAtom } from 'jotai'
import { hasLogsAtom, instanceIdAtom } from '../../../state/state'
import useQuery from '../../services/useQuery'
import { useCallback, useEffect } from 'react'

const useLoadHasLogs = () => {
  const query = useQuery()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setHasLogs = useSetAtom(hasLogsAtom)

  const loadHasLogs = useCallback(async () => {
    if (!projectId) return
    try {
      const { count } = await query.logsCount({
        project_id: projectId,
      })
      setHasLogs(count > 0)
    } catch (e) {}
  }, [query, projectId, setHasLogs])

  useEffect(() => {
    loadHasLogs()
  }, [loadHasLogs])

  return loadHasLogs
}

export default useLoadHasLogs
