import { useSetAtom } from 'jotai'
import { organizationsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadOrganizations = () => {
  const backend = useBackend()
  const setOrganizations = useSetAtom(organizationsAtom)

  const loadOrganizations = useCallback(async () => {
    try {
      const organizations = await backend.getOrganizations()
      setOrganizations(organizations.organizations)
    } catch (e) {}
  }, [backend, setOrganizations])

  return loadOrganizations
}

export default useLoadOrganizations
