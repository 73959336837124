import { CornerDownLeft } from 'lucide-react'
import { useState } from 'react'
import { QueryMode } from '../../../../../../../library/query/types'

interface QueryButtonProps {
  queryMode: QueryMode
  onClick?: () => void
}

const QueryButton = ({ queryMode, onClick }: QueryButtonProps) => {
  const [hover, setHover] = useState(false)
  const hoverClass = hover ? 'bg-input-tint' : 'bg-panel-bg'

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`px-4 lg:px-6 py-2 flex items-center justify-center gap-2 ${hoverClass} outline-none rounded-r-[3px]`}
      onClick={onClick}
    >
      <p className="text-small text-text-1 whitespace-nowrap">
        {queryMode === 'user_query' ? 'Running Query' : 'Run Query'}
      </p>
      {queryMode !== 'user_query' && (
        <div className="w-4 h-4 flex items-center justify-center rounded-[4px] bg-input-tint text-text-1">
          <CornerDownLeft size={12} />
        </div>
      )}
    </button>
  )
}

export default QueryButton
