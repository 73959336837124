import { useEffect, useState } from 'react'
import useCreateToken from '../../../hooks/actions/useCreateToken'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import CTAButton from '../../library/CTAButton/CTAButton'
import CodeSnippet from '../../library/CodeSnippet/CodeSnippet'
import CodeBlock, {
  CodeSnippetDetails,
  languageAtom,
} from '../../library/CodeBlock/CodeBlock'
import CardButton from '../../library/CardButton/CardButton'
import NavbarPlaceholder from '../../library/NavbarPlaceholder/NavbarPlaceholder'
import { setBrandCTA } from '../../../utils/setBrandColor'
import LogPreview from './LogPreview/LogPreview'
import { useAtomValue } from 'jotai'

const { REACT_APP_LOG_ADDRESS } = process.env
const LOG_ADDRESS = REACT_APP_LOG_ADDRESS || 'log.vigilant.run:4317'

const Onboarding: React.FC = () => {
  const navigate = useNav()

  const generateToken = useCreateToken()

  const language = useAtomValue(languageAtom)

  const [apiKey, setApiKey] = useState<string>('')
  const handleGenerateToken = async () => {
    const response = await generateToken(
      'Onboarding Token',
      'Token created during onboarding.',
    )
    if (!response) return
    setApiKey(response.token)
  }

  useEffect(() => {
    setBrandCTA('info')
  }, [])

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center">
      <NavbarPlaceholder />
      <div className="px-6 py-12 w-full h-full max-w-[800px] flex flex-col items-center justify-start gap-8">
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h1 className="text-h1 text-text-1">Welcome to Vigilant!</h1>
          <p className="text-body text-text-2">
            Let's get you setup with the Vigilant SDK
          </p>
        </div>

        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h2 className="text-h2 text-text-1">Create an API key</h2>
          <p className="text-body text-text-2">
            You need to use an API key to send messages to Vigilant.
          </p>
          <div className="w-auto my-2">
            <CTAButton mode="info" onClick={handleGenerateToken}>
              Generate Key
            </CTAButton>
          </div>
          <CodeSnippet
            className={'w-full'}
            content={apiKey ? apiKey : 'No key generated yet'}
            language="bash"
            disabled={apiKey === ''}
          />
        </div>

        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h2 className="text-h2 text-text-1">Install the SDK</h2>
          <p className="text-body text-text-2">
            Install the SDK in the app you want to monitor.
          </p>
          <CodeBlock className={'w-full'} snippets={installSnippets} />
        </div>

        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h2 className="text-h2 text-text-1">Send a log</h2>
          <p className="text-body text-text-2">
            Send a log to Vigilant to see it in the dashboard.
          </p>
          <CodeBlock
            className={'w-full'}
            snippets={getLogSnippets(LOG_ADDRESS, apiKey)}
          />
        </div>

        <LogPreview />

        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h2 className="text-h2 text-text-1">Next steps</h2>
          <div className="w-full flex items-start justify-start gap-4">
            <CardButton
              className="w-full"
              mode="info"
              onClick={() => navigate(LinkKey.Logs)}
            >
              <h2 className="text-h3 text-text-1">View logs</h2>
              <p className="text-body text-text-2">
                Open the dashboard to view, filter, and search your logs.
              </p>
            </CardButton>
            <CardButton
              className="w-full"
              mode="success"
              onClick={() => {
                window.open(
                  getLanguageEndpoint(language),
                  '_blank',
                  'noreferrer',
                )
              }}
            >
              <h2 className="text-h3 text-text-1">Visit Docs</h2>
              <p className="text-body text-text-2">
                Learn more about the Vigilant SDK and how to use it.
              </p>
            </CardButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'Python',
    language: 'python',
    displayLanguage: 'bash',
    content: `pip install vigilant-py`,
  },
  {
    title: 'Node.js',
    language: 'javascript',
    displayLanguage: 'bash',
    content: `npm install vigilant-js`,
  },
  {
    title: 'Go',
    language: 'go',
    displayLanguage: 'bash',
    content: `go get github.com/vigilant-run/vigilant-golang`,
  },
]

function getLogSnippets(logAddress: string, key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Python',
      language: 'python',
      displayLanguage: 'python',
      content: getPythonSnippet(logAddress, key),
    },
    {
      title: 'Node.js',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: getNodejsSnippet(logAddress, key),
    },
    {
      title: 'Go',
      language: 'go',
      displayLanguage: 'go',
      content: getGoSnippet(logAddress, key),
    },
  ]
}

function getLanguageEndpoint(language: string) {
  if (language === 'python') {
    return 'https://docs.vigilant.run/documentation/python'
  }
  if (language === 'javascript') {
    return 'https://docs.vigilant.run/documentation/nodejs'
  }
  if (language === 'go') {
    return 'https://docs.vigilant.run/documentation/go'
  }
}

function getPythonSnippet(logAddress: string, key: string) {
  return `from vigilant import create_autocapture_logger

# Initialize the logger
logger = create_autocapture_logger(
    url="${logAddress}",
    name="my-service",
    token="${key}",
    passthrough=True,
)

# Start reading logs from the console
logger.enable()

# Autocapture print statements
print("Hello, World!")

# Log a message using the logger
logger.info("Hello, World!")
`
}

function getNodejsSnippet(logAddress: string, key: string) {
  return `import { AutocaptureLogger } from 'vigilant-js'

// Initialize the logger
const logger = new AutocaptureLogger({
  name: 'my-service',
  url: '${logAddress}',
  token: '${key}',
  passthrough: true,
})

// Start reading logs from the console
logger.enable();

// Autocapture console statements
console.log('Hello, World!');

// Send a log using the logger
logger.info('User logged in')

// Shutdown the logger before application exit
await logger.shutdown()
`
}

function getGoSnippet(logAddress: string, key: string) {
  return `package main

import (
    "context"

    "github.com/vigilant-run/vigilant-golang"
)

func main() {
    // Create the logger options
    loggerOptions := vigilant.NewLoggerOptions(
        vigilant.WithLoggerURL("${logAddress}"),
        vigilant.WithLoggerToken("${key}"),
        vigilant.WithLoggerName("my-service"),
        vigilant.WithLoggerPassthrough(),
    )

    // Create the logger
    logger := vigilant.NewLogger(loggerOptions)

    // Defer the shutdown of the logger
    defer logger.Shutdown(context.Background())

    // Log a message
    logger.Info(context.Background(), "Hello, World!")
}`
}

export default Onboarding
