import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import AlertsSidebar from './AlertsSidebar/AlertsSidebar'
import useInitAlerts from '../../../../hooks/data/effects/useInitAlerts'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import { Outlet } from 'react-router-dom'
import useInitContactChannels from '../../../../hooks/data/effects/useInitContactChannels'
import useInitIntegrations from '../../../../hooks/data/effects/useInitIntegrations'

const Alerts = () => {
  useInitAlerts()
  useInitIntegrations()
  useInitContactChannels()
  useSetBrandColor('warning')

  return (
    <div className="flex flex-col w-full h-full">
      <NavbarPlaceholder />
      <div className="flex-1 flex overflow-hidden">
        <AlertsSidebar />
        <Outlet />
      </div>
    </div>
  )
}

export default Alerts
