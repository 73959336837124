import useBackend from '../services/useBackend'
import useToast from '../utils/useToast'

const useSendSupport = () => {
  const backend = useBackend()
  const { createToast } = useToast()

  return async (message: string) => {
    try {
      await backend.sendSupport(message)
      createToast('Support message sent', 'success')
    } catch (e) {}
  }
}

export default useSendSupport
