import { cn } from '../../../../../../../utils/cn'
import { CirclePause, CirclePlay } from 'lucide-react'

interface LiveButtonProps {
  live: boolean
  setLive: (live: boolean) => void
}

const LiveButton = ({ live, setLive }: LiveButtonProps) => {
  const backgroundClass = live
    ? 'bg-brand-ctaTint hover:bg-brand-ctaTint'
    : 'bg-panel-bg hover:bg-input-tint'
  const borderClass = live
    ? 'border-brand-ctaTint hover:border-brand-cta'
    : 'border-panel-border hover:border-panel-border'
  const textClass = live ? 'text-brand-cta' : 'text-text-1'

  return (
    <button
      className={`px-4 lg:px-6 py-2 flex items-center justify-center gap-2 rounded-[4px] border ${borderClass} ${backgroundClass} outline-none`}
      onClick={() => setLive(!live)}
    >
      {live ? (
        <CirclePause size={16} className={textClass} />
      ) : (
        <CirclePlay size={16} className={textClass} />
      )}
      <p className={cn('text-small whitespace-nowrap', textClass)}>Live</p>
    </button>
  )
}

export default LiveButton
