import { cn } from '../../../utils/cn'

interface SettingsTableColumnProps {
  children: React.ReactNode
  flex?: boolean
  width?: string
}

const SettingsTableColumn = ({
  children,
  flex = true,
  width = undefined,
}: SettingsTableColumnProps) => {
  const flexClass = flex ? 'flex-1' : ''

  return (
    <div className={cn('flex flex-col', flexClass)} style={{ width }}>
      {children}
    </div>
  )
}

export default SettingsTableColumn
