import { useSetAtom } from 'jotai'
import { userInfoAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useNav, { LinkKey } from '../utils/useNav'
import useJoinToken from './useJoinToken'

const useSignup = () => {
  const backend = useBackend()
  const navigate = useNav()

  const setUserInfo = useSetAtom(userInfoAtom)
  const joinToken = useJoinToken()

  return async (email: string, password: string) => {
    try {
      await backend.createUser(email, password)
      const user = await backend.getUser()
      setUserInfo(user)

      const joined = await joinToken()
      if (joined) {
        navigate(LinkKey.Logs)
        return
      }

      navigate(LinkKey.NewOrganization)
    } catch (e) {}
  }
}

export default useSignup
