import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { organizationsAtom } from '../../state/state'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import useSetInstance from './useSetInstance'
import useNav, { LinkKey } from '../utils/useNav'

export const useDeleteOrganization = () => {
  const backend = useBackend()
  const navigate = useNav()

  const organizations = useAtomValue(organizationsAtom)
  const switchInstance = useSetInstance()
  const loadOrganizations = useLoadOrganizations()

  return async (id: string) => {
    if (!organizations?.find((o) => o.id === id)) return
    await backend.deleteOrganization(id)
    await loadOrganizations()
    const newOrganization = organizations.find((o) => o.id !== id)
    if (newOrganization) {
      await switchInstance(newOrganization.id, undefined)
      navigate(LinkKey.Logs)
    } else {
      navigate(LinkKey.NewOrganization)
    }
  }
}
