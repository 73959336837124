import { PanelsTopLeft, Plus } from 'lucide-react'
import Button from '../Button/Button'
import NavPopup from '../NavPopup/NavPopup'
import { useRef, useState } from 'react'
import { useAtomValue } from 'jotai'
import { projectsAtom, instanceIdAtom } from '../../../state/state'

import useNav from '../../../hooks/utils/useNav'
import { LinkKey } from '../../../hooks/utils/useNav'
import useSetInstance from '../../../hooks/actions/useSetInstance'

const ProjectButton = () => {
  const navigate = useNav()
  const switchInstance = useSetInstance()

  const projects = useAtomValue(projectsAtom)
  const { organizationId, projectId } = useAtomValue(instanceIdAtom)

  const [isProjectOpen, setIsProjectOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <div className="relative">
      <Button
        buttonRef={buttonRef}
        active={isProjectOpen}
        onClick={() => setIsProjectOpen(!isProjectOpen)}
      >
        {projectId
          ? projects?.find((p) => p.id === projectId)?.name
          : 'Project'}
      </Button>
      {isProjectOpen && (
        <NavPopup
          title="Project"
          selected={projectId}
          options={[
            ...(projects?.map((p) => ({
              title: p.name,
              value: p.id,
              icon: <PanelsTopLeft size={16} />,
              onClick: async () => {
                if (!organizationId) return
                await switchInstance(organizationId, p.id)
                setIsProjectOpen(false)
                window.location.reload()
              },
            })) ?? []),
            {
              title: 'New Project',
              value: 'new-project',
              icon: <Plus size={16} />,
              onClick: () => navigate(LinkKey.NewProject),
            },
          ]}
          close={() => setIsProjectOpen(false)}
          buttonRef={buttonRef}
        />
      )}
    </div>
  )
}

export default ProjectButton
