import { cn } from '../../../utils/cn'

export type TabDetails<T> = {
  title: string
  value: T
  mode: 'info' | 'warning' | 'error' | 'success'
}

interface MainTabsProps<T> {
  tabs: TabDetails<T>[]
  activeTab: T
  setActiveTab: (tab: T) => void
}

const MainTabs = <T,>({ tabs, activeTab, setActiveTab }: MainTabsProps<T>) => {
  return (
    <div className="flex">
      {tabs.map((tab) => (
        <Tab
          key={tab.title}
          title={tab.title}
          value={tab.value}
          mode={tab.mode}
          active={activeTab === tab.value}
          setActiveTab={setActiveTab}
        />
      ))}
    </div>
  )
}

interface TabProps<T> {
  title: string
  value: T
  mode: 'info' | 'warning' | 'error' | 'success'
  active: boolean
  setActiveTab: (tab: T) => void
}

const Tab = <T,>({ title, value, mode, active, setActiveTab }: TabProps<T>) => {
  const borderClass = active ? getBorderColor(mode) : 'border-transparent'
  const textClass = active ? getTabTextColor(mode) : 'text-text-2'
  const textHoverClass = getTabTextHoverColor(mode)

  return (
    <button
      onClick={() => setActiveTab(value)}
      className={cn(
        'h-12 px-3 flex items-center justify-center border-b text-navlink outline-none',
        borderClass,
        textClass,
        textHoverClass,
      )}
    >
      {title}
    </button>
  )
}

const getBorderColor = (mode: 'info' | 'warning' | 'error' | 'success') => {
  switch (mode) {
    case 'info':
      return 'border-brand-info'
    case 'warning':
      return 'border-brand-warning'
    case 'error':
      return 'border-brand-error'
    case 'success':
      return 'border-brand-success'
  }
}

const getTabTextColor = (mode: 'info' | 'warning' | 'error' | 'success') => {
  switch (mode) {
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
  }
}

const getTabTextHoverColor = (
  mode: 'info' | 'warning' | 'error' | 'success',
) => {
  switch (mode) {
    case 'info':
      return 'hover:text-brand-info'
    case 'warning':
      return 'hover:text-brand-warning'
    case 'error':
      return 'hover:text-brand-error'
    case 'success':
      return 'hover:text-brand-success'
  }
}

export default MainTabs
