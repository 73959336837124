import { FilterIcon, Search } from 'lucide-react'
import TimeButton from './TimeButton/TimeButton'
import QueryButton from './QueryButton/QueryButton'
import LiveButton from './LiveButton/LiveButton'
import FilterBlock from './FilterBlock/FilterBlock'
import { Filter } from '../../../../../../backend/types'
import { useEffect, useRef, useState } from 'react'
import { TimeRange } from '../../../../../../utils/time'
import { getModifierKey } from '../../../../../../utils/browser'
import _ from 'lodash'
import SidebarVisibilityButton from './SidebarVisibilityButton/SidebarVisibilityButton'
import { QueryMode } from '../../../../../../library/query/types'

interface SearchbarProps {
  term: string
  setTerm: (term: string) => void

  queryMode: QueryMode
  runSearch: () => Promise<void>

  live: boolean
  setLive: (live: boolean) => void

  timeRange: TimeRange
  setTimeRange: (range: TimeRange) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void
}

const Searchbar = ({
  term,
  setTerm,
  queryMode,
  runSearch,
  live,
  setLive,
  timeRange,
  setTimeRange,
  filters,
  setFilters,
}: SearchbarProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localTerm, setLocalTerm] = useState(term)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!inputRef.current) return
    if (e.key === 'k' && e.metaKey) {
      if (document.activeElement === inputRef.current) {
        inputRef.current.select()
      } else {
        inputRef.current.focus()
      }
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const handleInputBlur = async () => {
    if (localTerm === term) return
    setTerm(localTerm)
  }

  const handleWrapperMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!inputRef.current) return

    const input = inputRef.current
    const isFullySelected =
      input.selectionStart === 0 && input.selectionEnd === input.value.length

    if (document.activeElement !== input) {
      input.focus()
      input.select()
    } else if (isFullySelected) {
      input.setSelectionRange(input.value.length, input.value.length)
    } else {
      input.select()
    }
  }

  useEffect(() => {
    setLocalTerm(term)
  }, [term])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="p-4 w-full flex flex-col gap-2 bg-main-bg">
      <div className="flex flex-col gap-2">
        <div className="flex lg:hidden gap-2 justify-start">
          <SidebarVisibilityButton />
          <LiveButton live={live} setLive={setLive} />
        </div>

        <div className="flex gap-2 items-center">
          <SidebarVisibilityButton className="hidden lg:block" />

          <div className="flex-1 flex border border-input-border rounded-[4px]">
            <div
              className="px-3 py-2 w-full flex flex-col gap-2 rounded-l-[3px] items-stretch bg-panel-bg border-r border-panel-border hover:bg-input-tint  cursor-text"
              onMouseDown={handleWrapperMouseDown}
            >
              <div className="flex-1 flex items-center gap-2">
                <Search size={16} className="text-text-2 flex-none" />
                <input
                  ref={inputRef}
                  className="w-full bg-transparent text-small-code text-text-1 placeholder:text-text-2 outline-none"
                  value={localTerm}
                  onChange={(e) => setLocalTerm(e.target.value)}
                  onKeyDown={handleInputKeyDown}
                  onBlur={handleInputBlur}
                  onFocus={handleInputFocus}
                  placeholder="Search"
                />
                <div className="flex-none flex items-center gap-2">
                  <p className="text-small text-text-2">{getModifierKey()}K</p>
                </div>
              </div>
            </div>
            <TimeButton range={timeRange} setRange={setTimeRange} />
            <QueryButton queryMode={queryMode} onClick={runSearch} />
          </div>

          <div className="hidden lg:block">
            <LiveButton live={live} setLive={setLive} />
          </div>
        </div>
      </div>
      {filters.length > 0 && (
        <div className="px-3 py-1.5 w-full flex flex-wrap items-center justify-start gap-2 bg-panel-bg border border-panel-border rounded-[4px]">
          <FilterIcon size={16} className="text-text-2" />
          {filters.map((filter, index) => (
            <FilterBlock
              key={`${filter.field}-${index}`}
              filter={filter}
              remove={() =>
                setFilters(filters.filter((f) => !_.isEqual(f, filter)))
              }
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Searchbar
