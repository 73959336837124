import { cn } from '../../../utils/cn'

interface PopupContainerProps {
  popupRef?: React.RefObject<HTMLDivElement | null>
  children: React.ReactNode
  className?: string
}

const PopupContainer = ({
  popupRef,
  children,
  className,
}: PopupContainerProps) => {
  return (
    <div
      ref={popupRef}
      className={cn(
        'flex flex-col bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default PopupContainer
