import { useCallback, useEffect, useRef, useState } from 'react'
import { Filter } from '../../../../backend/types'
import { useAtom } from 'jotai'
import { TimeRange } from '../../../../utils/time'
import persistAtom from '../../../../state/persistAtom'
import useQueryManager from '../../../../hooks/stateServices/useQueryManager'
import useQueryParameterManager from '../../../../hooks/stateServices/useQueryParameterManager'
import LogDisplay from './LogDisplay/LogDisplay'
import { getHeaderWidth } from '../../../../utils/logs'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import { QueryParameters, QueryState } from '../../../../library/query/types'
import useQueryLiveTick from '../../../../hooks/stateServices/useQueryLiveTick'
import { readSavedProperties } from '../../../../library/query/utils'
import useQueryOptionsManager from '../../../../hooks/stateServices/useQueryOptionsManager'
import { genKey } from '../../../../utils/sub'
import { OptionsQueryState } from '../../../../library/queryOptions/types'
import useLoadHasLogs from '../../../../hooks/data/load/useLoadHasLogs'
import {
  COLUMN_TIMESTAMP,
  COLUMN_LEVEL,
  ATTRIBUTE_SERVICE,
  COLUMN_BODY,
} from '../../../../utils/properties'

export type Header = {
  value: string
  width: number
}

export const headersAtom = persistAtom<Header[]>({
  key: 'logsVisibleProperties',
  defaultValue: [
    { value: COLUMN_TIMESTAMP, width: 160 },
    { value: COLUMN_LEVEL, width: 80 },
    { value: ATTRIBUTE_SERVICE, width: 160 },
    { value: COLUMN_BODY, width: 1000 },
  ],
  persistMode: 'local',
})

const Logs = () => {
  useSetBrandColor('info')
  useLoadHasLogs()

  const queryManager = useQueryManager()
  const queryOptionsManager = useQueryOptionsManager()
  const queryParameterManager = useQueryParameterManager()

  const logContainerRef = useRef<HTMLDivElement | null>(null)

  const [headers, setHeaders] = useAtom(headersAtom)
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null)

  const [queryParams, setQueryParams] = useState<QueryParameters>(defaultParams)
  const [isLive, setIsLive] = useQueryLiveTick(queryManager)

  const [state, setState] = useState<QueryState | null>(null)
  const [options, setOptions] = useState<OptionsQueryState | null>(null)

  const handleScroll = useCallback(async () => {
    const container = logContainerRef.current
    if (!container || !queryManager) return

    const top = container.scrollTop
    const isAtBottom =
      top + container.clientHeight + 264 >= container.scrollHeight

    const atTop = container.scrollTop === 0
    if (atTop) {
      setIsLive(true)
    } else {
      setIsLive(false)
    }

    if (isAtBottom && state?.mode === 'none' && !state?.result?.done) {
      await queryManager.loadMore(state)
    }
  }, [queryManager, state, setIsLive])

  const handleSetIsLive = useCallback(
    async (isLive: boolean) => {
      setIsLive(isLive)
      const container = logContainerRef.current
      if (container) container.scrollTop = 0
    },
    [setIsLive],
  )

  const handleSetTerm = useCallback(
    async (term: string) => {
      queryParameterManager.setTerm(term)
      await queryManager?.query({ ...queryParams, term })
    },
    [queryManager, queryParams, queryParameterManager],
  )

  const handleSetTimeRange = useCallback(
    async (timeRange: TimeRange) => {
      queryParameterManager.setTimeRange(timeRange)
      await queryManager?.query({ ...queryParams, timeRange })
    },
    [queryManager, queryParams, queryParameterManager],
  )

  const handleSetFilters = useCallback(
    async (filters: Filter[]) => {
      queryParameterManager.setFilters(filters)
      await queryManager?.query({ ...queryParams, filters })
      if (selectedProperty) {
        await queryOptionsManager?.queryOptions({
          ...queryParams,
          filters: filters,
          field: selectedProperty,
          value: '',
        })
      }
    },
    [
      queryManager,
      queryParams,
      queryParameterManager,
      queryOptionsManager,
      selectedProperty,
    ],
  )

  const handleToggleHeader = useCallback(
    async (value: string) => {
      const exists = headers.some((h) => h.value === value)
      let newHeaders = [...headers]
      if (exists) {
        newHeaders = newHeaders.filter((h) => h.value !== value)
      } else {
        const width = getHeaderWidth(value, state?.result?.logs ?? [])
        newHeaders = [...headers, { value, width }]
      }
      setHeaders(newHeaders)
      queryParameterManager.setProperties(newHeaders.map((h) => h.value))
    },
    [headers, queryParameterManager, setHeaders, state?.result?.logs],
  )

  const handleSetHeaders = useCallback(
    async (headers: Header[]) => {
      setHeaders(headers)
      const properties = headers.map((h) => h.value)
      queryParameterManager.setProperties(properties)
    },
    [queryParameterManager, setHeaders],
  )

  const handleSelectProperty = useCallback(
    async (property: string | null) => {
      if (!queryManager) return
      if (property === selectedProperty || property === null) {
        setSelectedProperty(null)
        setOptions(null)
      } else {
        setSelectedProperty(property)
        await queryOptionsManager?.queryOptions({
          ...queryParams,
          field: property,
          value: '',
        })
      }
    },
    [queryManager, queryParams, queryOptionsManager, selectedProperty],
  )

  const handleSetOptionsFilter = useCallback(
    async (value: string) => {
      await queryOptionsManager?.queryOptions({
        ...queryParams,
        field: selectedProperty ?? '',
        value: value,
      })
    },
    [queryOptionsManager, queryParams, selectedProperty],
  )

  const handleRunSearch = useCallback(async () => {
    await queryManager?.query(queryParams)
  }, [queryManager, queryParams])

  useEffect(() => {
    const container = logContainerRef.current
    if (!container) return

    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, logContainerRef])

  useEffect(() => {
    if (!queryManager) return
    const key = genKey()
    queryManager.subscribe(key, setState)
    queryManager.init()
    return () => {
      queryManager.unsubscribe(key)
    }
  }, [queryManager])

  useEffect(() => {
    if (!queryParameterManager) return
    const key = genKey()
    queryParameterManager.subscribe(key, setQueryParams)
    return () => {
      queryParameterManager.unsubscribe(key)
    }
  }, [queryParameterManager])

  useEffect(() => {
    if (!queryOptionsManager) return
    const key = genKey()
    queryOptionsManager.subscribe(key, setOptions)
    return () => {
      queryOptionsManager.unsubscribe(key)
    }
  }, [queryOptionsManager])

  return (
    <LogDisplay
      logs={state?.result?.logs ?? []}
      term={queryParams.term}
      setTerm={handleSetTerm}
      queryMode={state?.mode ?? 'initial_load'}
      done={state?.result?.done ?? false}
      runSearch={handleRunSearch}
      live={isLive}
      setLive={handleSetIsLive}
      filters={queryParams.filters}
      setFilters={handleSetFilters}
      timeRange={queryParams.timeRange}
      setTimeRange={handleSetTimeRange}
      total={state?.result?.count ?? 0}
      services={state?.result?.serviceOptions ?? []}
      levels={state?.result?.levelOptions ?? []}
      properties={state?.result?.properties ?? []}
      optionsMode={options?.mode ?? 'loading'}
      options={options?.result?.options ?? []}
      setOptionsFilter={handleSetOptionsFilter}
      headers={headers}
      setHeaders={handleSetHeaders}
      toggleHeader={handleToggleHeader}
      selectedProperty={selectedProperty}
      selectProperty={handleSelectProperty}
      logContainerRef={logContainerRef}
    />
  )
}

const defaultParams: QueryParameters = {
  term: '',
  timeRange: { start: '12h', end: undefined },
  filters: [],
  properties: readSavedProperties(),
  limit: 100,
  cursor: undefined,
}

export default Logs
