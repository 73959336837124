import Prism from 'prismjs'
import 'prism-themes/themes/prism-vsc-dark-plus.css'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-python'
import { useEffect, useRef, useState } from 'react'
import { cn } from '../../../utils/cn'
import CopyButton from '../CopyButton/CopyButton'
import EyeButton from './EyeButton'

interface CodeSnippetProps {
  language: string
  content: string
  canCopy?: boolean
  canHide?: boolean
  disabled?: boolean
  className?: string
}

const CodeSnippet = ({
  language,
  content,
  canCopy = true,
  canHide = true,
  disabled = false,
  className,
}: CodeSnippetProps) => {
  const codeRef = useRef<HTMLPreElement | null>(null)
  const [isHidden, setIsHidden] = useState(true && canHide)

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current)
    }
  }, [content])

  return (
    <div
      className={cn(
        'p-4 flex items-center justify-between bg-code-bg border border-code-border rounded-[4px]',
        className,
        disabled ? 'opacity-60' : 'opacity-100',
      )}
    >
      <pre
        ref={codeRef}
        key={`${isHidden}-${disabled}-${content}`}
        className="flex-1 bg-code-bg"
      >
        <code className={`language-${language} text-[13px] leading-5`}>
          {isHidden && !disabled ? '*************' : content}
        </code>
      </pre>
      {canHide && (
        <EyeButton
          hidden={isHidden}
          onClick={() => setIsHidden(!isHidden)}
          disabled={disabled}
        />
      )}
      {canCopy && <CopyButton content={content} disabled={disabled} />}
    </div>
  )
}

export default CodeSnippet
