import { cn } from '../../../utils/cn'

interface RowButtonProps {
  text: string
  icon?: React.ReactNode
  onClick: () => void
  color?: 'info' | 'success' | 'warning' | 'error' | 'base'
  active?: boolean
  className?: string
}

const RowButton = ({
  text,
  icon,
  color = 'base',
  onClick,
  active = false,
  className,
}: RowButtonProps) => {
  const bgColorClass = active ? getColor(color) : 'bg-transparent'
  const bgHoverClass = active ? getHoverColor(color) : 'hover:bg-input-tint'
  const borderClass = active ? getBorderColor(color) : 'border-transparent'
  const textColorClass = active ? getTextColor(color) : 'text-text-1'

  return (
    <button
      className={cn(
        'px-3 py-2 flex items-center gap-2 rounded-[4px] border',
        bgColorClass,
        bgHoverClass,
        borderClass,
        className,
      )}
      onClick={onClick}
    >
      {icon && <div className={textColorClass}>{icon}</div>}
      <p className={cn('text-small', textColorClass)}>{text}</p>
    </button>
  )
}

function getColor(color: 'info' | 'success' | 'warning' | 'error' | 'base') {
  switch (color) {
    case 'info':
      return 'bg-brand-infoTint'
    case 'warning':
      return 'bg-brand-warningTint'
    case 'error':
      return 'bg-brand-errorTint'
    case 'success':
      return 'bg-brand-successTint'
    case 'base':
      return 'bg-brand-ctaTint'
  }
}

function getHoverColor(
  color: 'info' | 'success' | 'warning' | 'error' | 'base',
) {
  switch (color) {
    case 'info':
      return 'hover:bg-brand-infoTint'
    case 'warning':
      return 'hover:bg-brand-warningTint'
    case 'error':
      return 'hover:bg-brand-errorTint'
    case 'success':
      return 'hover:bg-brand-successTint'
    case 'base':
      return 'hover:bg-brand-ctaTint'
  }
}

function getBorderColor(
  color: 'info' | 'success' | 'warning' | 'error' | 'base',
) {
  switch (color) {
    case 'info':
      return 'border-brand-infoTint'
    case 'warning':
      return 'border-brand-warningTint'
    case 'error':
      return 'border-brand-errorTint'
    case 'success':
      return 'border-brand-successTint'
    case 'base':
      return 'border-brand-ctaTint'
  }
}

function getTextColor(
  color: 'info' | 'success' | 'warning' | 'error' | 'base',
) {
  switch (color) {
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    case 'base':
      return 'text-brand-cta'
  }
}

export default RowButton
