import Sidebar from './LogSidebar/LogSidebar'
import NavbarPlaceholder from '../../../../library/NavbarPlaceholder/NavbarPlaceholder'
import { Filter, ValueCount } from '../../../../../backend/types'
import { LogEntry } from '../../../../../state/types'
import { Header } from '../Logs'
import { TimeRange } from '../../../../../utils/time'
import { QueryMode } from '../../../../../library/query/types'
import PropertyPopup from './PropertyPopup/PropertyPopup'
import Searchbar from './Searchbar/Searchbar'
import LogContainer from './LogContainer/LogContainer'
import { OptionsQueryMode } from '../../../../../library/queryOptions/types'

interface LogDisplayProps {
  logs: LogEntry[]

  queryMode: QueryMode
  done: boolean

  runSearch: () => Promise<void>

  term: string
  setTerm: (term: string) => void

  live: boolean
  setLive: (live: boolean) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void

  total: number
  services: ValueCount[]
  levels: ValueCount[]
  properties: ValueCount[]

  optionsMode: OptionsQueryMode
  options: ValueCount[]
  setOptionsFilter: (value: string) => void

  headers: Header[]
  setHeaders: (headers: Header[]) => void

  toggleHeader: (header: string) => void

  selectedProperty: string | null
  selectProperty: (property: string | null) => void

  logContainerRef: React.RefObject<HTMLDivElement | null>
}

const LogDisplay = ({
  logs,
  queryMode,
  done,
  term,
  setTerm,
  runSearch,
  live,
  setLive,
  filters,
  setFilters,
  timeRange,
  setTimeRange,
  total,
  services,
  levels,
  properties,
  optionsMode,
  options,
  setOptionsFilter,
  headers,
  setHeaders,
  toggleHeader,
  selectedProperty,
  selectProperty,
  logContainerRef,
}: LogDisplayProps) => {
  return (
    <div className="flex flex-col w-full h-full">
      <NavbarPlaceholder />
      <div className="flex-1 flex overflow-hidden">
        <Sidebar
          total={total}
          queryMode={queryMode}
          services={services}
          levels={levels}
          properties={properties}
          headers={headers}
          toggleHeader={toggleHeader}
          selectedProperty={selectedProperty}
          selectProperty={selectProperty}
          filters={filters}
          setFilters={setFilters}
        />
        {selectedProperty && (
          <div className="absolute bottom-2 left-2 lg:left-[288px] z-[2]">
            <PropertyPopup
              property={selectedProperty}
              optionsMode={optionsMode}
              valueCounts={options}
              close={() => selectProperty(null)}
              filters={filters}
              setFilters={setFilters}
              setOptionsFilter={setOptionsFilter}
            />
          </div>
        )}
        <div className="flex-1 min-w-0 bg-main-bg">
          <Searchbar
            term={term}
            setTerm={setTerm}
            queryMode={queryMode}
            runSearch={runSearch}
            live={live}
            setLive={setLive}
            filters={filters}
            setFilters={setFilters}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
          />
          <div
            ref={logContainerRef}
            className="flex flex-col h-full overflow-scroll"
          >
            <LogContainer
              logs={logs}
              term={term}
              headers={headers}
              setHeaders={setHeaders}
              queryMode={queryMode}
              done={done}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogDisplay
