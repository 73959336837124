import { useEffect } from 'react'
import useLoadAlertEvents from '../load/useLoadAlertEvents'

const useInitAlertEvents = () => {
  const loadAlertEvents = useLoadAlertEvents()
  const alertId = getAlertId()

  useEffect(() => {
    loadAlertEvents(alertId)
  }, [loadAlertEvents, alertId])
}

function getAlertId() {
  const path = window.location.pathname
  const parts = path.split('/alerts/')
  return parts[1]
}

export default useInitAlertEvents
