import { useMemo, useState } from 'react'
import Fullscreen from '../Fullscreen/Fullscreen'
import PopupContainer from '../PopupContainer/PopupContainer'
import PanelSection from '../PanelSection/PanelSection'
import PanelRow from '../PanelRow/PanelRow'
import { X } from 'lucide-react'
import {
  ContactChannelEmailSettings,
  ContactChannel,
  ContactChannelSettings,
  ContactChannelSlackSettings,
  ContactChannelType,
} from '../../../backend/types'
import CodeCTAButton from '../CodeCTAButton/CodeCTAButton'
import { isValidEmail } from '../../../utils/inputs'
import CodeDropdown from '../CodeDropdown/CodeDropdown'
import CodeInput from '../CodeInput/CodeInput'
import SlackSettings from './SlackSettings/SlackSettings'
import EmailSettings from './EmailSettings/EmailSetting'

interface ContactChannelPopupProps {
  info?: ContactChannel | undefined
  close?: () => void
  onSubmit?: (info: Omit<ContactChannel, 'id'>) => Promise<void>
}

const ContactChannelPopup: React.FC<ContactChannelPopupProps> = ({
  info,
  close,
  onSubmit,
}) => {
  const [localName, setLocalName] = useState(info?.name || '')
  const [localType, setLocalType] = useState(info?.type || 'slack')
  const [localConnectionId, setLocalConnectionId] = useState(
    info?.integration_id,
  )
  const [localSettings, setLocalSettings] = useState<ContactChannelSettings>(
    info?.settings || { channel_name: '' },
  )

  const valid = useMemo(
    () => isValid(localName, localType, localConnectionId, localSettings),
    [localName, localType, localConnectionId, localSettings],
  )

  const handleSetType = (type: ContactChannelType) => {
    setLocalType(type)
    setLocalConnectionId(undefined)
    setLocalSettings(type === 'slack' ? { channel_name: '' } : { emails: [''] })
  }

  const handleSubmit = async () => {
    if (!valid || !onSubmit) return
    await onSubmit({
      name: localName,
      type: localType,
      integration_id: localConnectionId,
      settings: localSettings,
    })
  }

  return (
    <Fullscreen>
      <PopupContainer className="w-[472px]">
        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Alert Destination</p>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Name</p>
            <div className="w-[232px]">
              <CodeInput
                value={localName}
                onChange={setLocalName}
                placeholder={'Slack Alerts'}
              />
            </div>
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Type</p>
            <div className="w-[232px]">
              <CodeDropdown
                selected={localType}
                options={[
                  { label: 'Slack', value: 'slack' },
                  { label: 'Email', value: 'email' },
                ]}
                onChange={handleSetType}
              />
            </div>
          </PanelRow>
        </PanelSection>
        {localType === 'slack' && isSlackSettings(localSettings) && (
          <SlackSettings
            connectionId={localConnectionId}
            setConnectionId={setLocalConnectionId}
            settings={localSettings}
            setSettings={setLocalSettings}
          />
        )}
        {localType === 'email' && isEmailSettings(localSettings) && (
          <EmailSettings
            settings={localSettings}
            setSettings={setLocalSettings}
          />
        )}
        <PanelSection>
          <div className="w-full flex justify-end px-2 gap-4">
            <CodeCTAButton
              mode="success"
              widthClass="w-fit"
              onClick={handleSubmit}
              disabled={!valid}
            >
              {info ? 'Update' : 'Create'}
            </CodeCTAButton>
          </div>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

function isEmailSettings(
  settings: ContactChannelSettings,
): settings is ContactChannelEmailSettings {
  return 'emails' in settings
}

function isSlackSettings(
  settings: ContactChannelSettings,
): settings is ContactChannelSlackSettings {
  return 'channel_name' in settings
}

function isValid(
  name: string,
  type: ContactChannelType,
  integrationId: string | undefined,
  settings: ContactChannelSettings,
) {
  switch (type) {
    case 'slack':
      return (
        name.length > 0 &&
        isSlackSettings(settings) &&
        settings.channel_name.length > 0 &&
        integrationId
      )
    case 'email':
      return (
        name.length > 0 &&
        isEmailSettings(settings) &&
        settings.emails.length > 0 &&
        settings.emails.every((email) => isValidEmail(email))
      )
    default:
      return false
  }
}

export default ContactChannelPopup
