interface CTAButtonProps {
  children: React.ReactNode
  disabled?: boolean
  mode?: 'info' | 'warning' | 'error' | 'success'
  type?: 'submit' | 'reset' | 'button'
  onClick?: () => void
}

const CTAButton = ({
  children,
  disabled = false,
  mode,
  type = 'button',
  onClick,
}: CTAButtonProps) => {
  const bgColor = getBackgroundColor(mode, disabled)
  const borderColor = getBorderColor(mode, disabled)
  const borderHoverColor = disabled ? '' : getBorderHoverColor(mode)
  const textColor = disabled ? 'text-text-1' : getTextColor(mode)
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'

  return (
    <button
      className={`w-full flex items-center justify-center py-2 px-6 rounded-[4px] text-input border ${bgColor} ${borderColor} ${borderHoverColor} ${textColor} ${disabledClass}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function getBackgroundColor(mode: CTAButtonProps['mode'], disabled: boolean) {
  switch (mode) {
    case 'info':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-infoTint'
    case 'warning':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-warningTint'
    case 'error':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-errorTint'
    case 'success':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-successTint'
    default:
      return disabled ? 'bg-input-bgTint' : 'bg-brand-ctaTint'
  }
}

function getBorderColor(mode: CTAButtonProps['mode'], disabled: boolean) {
  switch (mode) {
    case 'info':
      return disabled ? 'border-panel-border' : 'border-brand-infoTint'
    case 'warning':
      return disabled ? 'border-panel-border' : 'border-brand-warningTint'
    case 'error':
      return disabled ? 'border-panel-border' : 'border-brand-errorTint'
    case 'success':
      return disabled ? 'border-panel-border' : 'border-brand-successTint'
    default:
      return disabled ? 'border-panel-border' : 'border-brand-ctaTint'
  }
}

function getBorderHoverColor(mode: CTAButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'hover:border-brand-info'
    case 'warning':
      return 'hover:border-brand-warning'
    case 'error':
      return 'hover:border-brand-error'
    case 'success':
      return 'hover:border-brand-success'
    default:
      return 'hover:border-brand-cta'
  }
}

function getTextColor(mode: CTAButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    default:
      return 'text-brand-cta'
  }
}

export default CTAButton
