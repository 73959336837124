import { useAtomValue } from 'jotai'
import { alertsAtom } from '../../../../../state/state'
import Searchbar from './Searchbar/Searchbar'
import { useMemo, useState } from 'react'
import AlertPopup from '../../../../library/AlertPopup/AlertPopup'
import useCreateAlert from '../../../../../hooks/actions/useCreateAlert'
import HeaderRow from './HeaderRow/HeaderRow'
import AlertRow from './AlertRow/AlertRow'
import useUpdateAlert from '../../../../../hooks/actions/useUpdateAlert'
import useDeleteAlert from '../../../../../hooks/actions/useDeleteAlert'
import {
  AlertCreateRequest,
  AlertUpdateRequest,
} from '../../../../../backend/types'
import DeleteAlertPopup from '../../../../library/DeleteAlertPopup/DeleteAlertPopup'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'

const AlertList = () => {
  const navigate = useNav()

  const createAlert = useCreateAlert()
  const updateAlert = useUpdateAlert()
  const deleteAlert = useDeleteAlert()

  const alerts = useAtomValue(alertsAtom)

  const [term, setTerm] = useState('')
  const [isCreating, setIsCreating] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const [deletingId, setDeletingId] = useState<string | null>(null)

  const handleCreate = async (request: AlertCreateRequest) => {
    await createAlert(request)
    setIsCreating(false)
  }

  const handleUpdate = async (request: AlertUpdateRequest) => {
    if (!editingId) return
    await updateAlert(editingId, request)
    setEditingId(null)
  }

  const handleToggleMute = async (id: string) => {
    const alert = alerts.find((alert) => alert.alert.id === id)
    if (!alert) return
    await updateAlert(id, {
      status: alert.alert.status === 'muted' ? 'active' : 'muted',
    })
  }

  const handleDelete = async () => {
    if (!deletingId) return
    await deleteAlert(deletingId)
    setDeletingId(null)
  }

  const filteredAlerts = useMemo(
    () =>
      alerts.filter((alert) =>
        alert.alert.name.toLowerCase().includes(term.toLowerCase()),
      ),
    [alerts, term],
  )

  return (
    <div className="flex flex-col w-full">
      <Searchbar
        term={term}
        setTerm={setTerm}
        onClickCreate={() => setIsCreating(true)}
      />
      <div className="flex flex-col w-full h-full overflow-y-auto">
        <HeaderRow />
        {filteredAlerts.map((alert) => (
          <AlertRow
            key={alert.alert.id}
            info={alert}
            onOpen={() => navigate(LinkKey.Alert, { id: alert.alert.id })}
            onMute={() => handleToggleMute(alert.alert.id)}
            onEdit={() => setEditingId(alert.alert.id)}
            onDelete={() => setDeletingId(alert.alert.id)}
          />
        ))}
      </div>

      {isCreating && (
        <AlertPopup
          close={() => setIsCreating(false)}
          onCreate={handleCreate}
        />
      )}

      {editingId && (
        <AlertPopup
          info={alerts.find((alert) => alert.alert.id === editingId)}
          close={() => setEditingId(null)}
          onUpdate={handleUpdate}
        />
      )}

      {deletingId && (
        <DeleteAlertPopup
          name={
            alerts.find((alert) => alert.alert.id === deletingId)?.alert.name ??
            'Alert'
          }
          close={() => setDeletingId(null)}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}

export default AlertList
