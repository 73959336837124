import { Clock } from 'lucide-react'
import TimePopup from './TimePopup/TimePopup'
import { useState, useRef } from 'react'
import { format } from 'date-fns'
import { TimeRange } from '../../../../../../../utils/time'

interface TimeButtonProps {
  range: TimeRange
  setRange: (range: TimeRange) => void
}

const TimeButton = ({ range, setRange }: TimeButtonProps) => {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [timeOpen, setTimeOpen] = useState(false)

  return (
    <div className="relative flex">
      <div
        ref={buttonRef}
        className={`px-4 lg:px-6 py-2 flex items-center justify-center gap-2 bg-panel-bg hover:bg-input-tint border-r border-panel-border cursor-pointer outline-none`}
        onClick={() => setTimeOpen(!timeOpen)}
      >
        <Clock size={16} className="text-text-1" />
        <p className="text-small text-text-1 whitespace-nowrap">
          {formatTime(range)}
        </p>
      </div>
      {timeOpen && (
        <TimePopup
          buttonRef={buttonRef}
          range={range}
          setRange={setRange}
          close={() => setTimeOpen(false)}
        />
      )}
    </div>
  )
}

function formatTime(range: TimeRange) {
  const formatDate = (date: string | Date | undefined) => {
    try {
      if (!date) return null
      const dateObj = new Date(date)
      return format(dateObj, 'MMM d, yyyy h:mm a')
    } catch (e) {
      return null
    }
  }

  if (!range.start && !range.end) return 'Everything'
  else if (presets.includes(range.start)) return `Last ${range.start}`
  else if (range.start && !range.end) return `Since ${formatDate(range.start)}`
  else if (!range.start && range.end) return `Before ${formatDate(range.end)}`
  else return `${formatDate(range.start)} - ${formatDate(range.end)}`
}

const presets: (Date | string | undefined)[] = [
  '30m',
  '60m',
  '3h',
  '6h',
  '12h',
  '24h',
  '2d',
  '7d',
]

export default TimeButton
