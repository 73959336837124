import { useEffect } from 'react'
import useLoadIntegrations from '../load/useLoadIntegrations'

const useInitIntegrations = () => {
  const loadIntegrations = useLoadIntegrations()

  useEffect(() => {
    loadIntegrations()
  }, [loadIntegrations])
}

export default useInitIntegrations
