import { Filter, LogCursor } from '../../backend/types'
import { TimeRange } from '../../utils/time'
import { QueryParameters } from './types'
import { readSavedProperties } from './utils'

export type QueryParametersSubscriber = (params: QueryParameters) => void

export class QueryParameterManager {
  private term: string
  private timeRange: TimeRange
  private filters: Filter[]
  private properties: string[]
  private limit: number
  private cursor: LogCursor | undefined

  private subscribers: { [key: string]: QueryParametersSubscriber } = {}

  constructor() {
    this.term = ''
    this.timeRange = { start: '12h', end: undefined }
    this.filters = []
    this.properties = readSavedProperties()
    this.limit = 100
    this.cursor = undefined
  }

  subscribe(key: string, subscriber: QueryParametersSubscriber) {
    this.subscribers[key] = subscriber
  }

  unsubscribe(key: string) {
    delete this.subscribers[key]
  }

  setTerm(term: string) {
    this.term = term
    this.publish()
  }

  setTimeRange(timeRange: TimeRange) {
    this.timeRange = timeRange
    this.publish()
  }

  setFilters(filters: Filter[]) {
    this.filters = filters
    this.publish()
  }

  setProperties(properties: string[]) {
    this.properties = properties
    this.publish()
  }

  setLimit(limit: number) {
    this.limit = limit
    this.publish()
  }

  setCursor(cursor: LogCursor | undefined) {
    this.cursor = cursor
  }

  private publish() {
    const params: QueryParameters = {
      term: this.term,
      timeRange: this.timeRange,
      filters: this.filters,
      properties: this.properties,
      limit: this.limit,
      cursor: this.cursor,
    }
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(params)
    }
  }
}
