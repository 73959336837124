import { useState } from 'react'
import { useAtomValue } from 'jotai'
import useCreateOrganizationInvite from '../../../../../hooks/actions/useCreateOrganizationInvite'
import useDeleteOrganizationMember from '../../../../../hooks/actions/useDeleteOrganizationMember'
import useInitOrganizationMembers from '../../../../../hooks/data/effects/useInitOrganizationMemebers'
import CTAButton from '../../../../library/CTAButton/CTAButton'
import SettingsSection from '../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../library/SettingsTitle/SettingsTitle'
import TextInput from '../../../../library/TextInput/TextInput'
import { useMemo } from 'react'
import {
  organizationMembersAtom,
  userInfoAtom,
} from '../../../../../state/state'
import { organizationInfoAtom } from '../../../../../state/state'
import { useUpdateOrganizationName } from '../../../../../hooks/actions/useUpdateOrganizationName'
import { useDeleteOrganization } from '../../../../../hooks/actions/useDeleteOrganization'
import {
  OrganizationMember,
  OrganizationRole,
} from '../../../../../backend/types'
import { User } from '../../../../../state/types'
import SettingsTable from '../../../../library/SettingsTable/SettingsTable'
import SettingsTableColumn from '../../../../library/SettingsTable/SettingsTableColumn'
import SettingsTableHeader from '../../../../library/SettingsTable/SettingsTableHeader'
import SettingsTableCell from '../../../../library/SettingsTable/SettingsTableCell'
import { TrashIcon } from 'lucide-react'
import SettingsTableIcon from '../../../../library/SettingsTable/SettingsTableIcon'
import RemoveMemberPopup from './RemoveMemberPopup/RemoveMemberPopup'
import InviteTeammatePopup from './InviteTeammatePopup/InviteTeammatePopup'
import DeleteOrganizationPopup from './DeleteOrganizationPopup/DeleteOrganizationPopup'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'

const OrganizationSettings = () => {
  const navigate = useNav()

  useInitOrganizationMembers()

  const updateOrganizationName = useUpdateOrganizationName()
  const deleteOrganization = useDeleteOrganization()
  const createInvite = useCreateOrganizationInvite()
  const deleteMember = useDeleteOrganizationMember()

  const organizationInfo = useAtomValue(organizationInfoAtom)

  const userInfo = useAtomValue(userInfoAtom)
  const members = useAtomValue(organizationMembersAtom)
  const isAdmin = useMemo(
    () => isUserAdmin(userInfo, members ?? []),
    [userInfo, members],
  )

  const [deletingMember, setDeletingMember] = useState<string | null>(null)
  const [deletingOrganization, setDeletingOrganization] = useState(false)
  const [invitingMember, setInvitingMember] = useState(false)

  const [orgName, setOrgName] = useState(organizationInfo?.name ?? '')

  const handleSaveOrgName = async () => {
    if (!organizationInfo?.id || !orgName) return
    await updateOrganizationName(orgName)
    setOrgName(organizationInfo.name)
  }

  const handleCreateInvite = async (email: string) => {
    if (!organizationInfo?.id) return
    await createInvite(email)
    setInvitingMember(false)
  }

  const handleDeleteMember = async (id: string) => {
    if (!organizationInfo?.id) return
    await deleteMember(organizationInfo.id, id)
    setDeletingMember(null)
  }

  const handleDeleteOrganization = async () => {
    if (!organizationInfo?.id) return
    await deleteOrganization(organizationInfo.id)
    setDeletingOrganization(false)
    navigate(LinkKey.Logs)
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Organization Name"
          description="We recommend having one organization for the entire company."
        />
        <div className="py-4 flex flex-col items-start justify-center gap-1">
          <div className="flex gap-4">
            <TextInput value={orgName} onChange={setOrgName} />
            <div className="flex items-center justify-center">
              <CTAButton
                disabled={
                  !isOrgNameValid(organizationInfo?.name ?? '', orgName)
                }
                onClick={handleSaveOrgName}
              >
                Save
              </CTAButton>
            </div>
          </div>
          <p className="text-label text-text-2">Max 32 characters</p>
        </div>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Members"
          description="This is the list of everyone in the organization. Members can invite new members. Owners and admins can change roles and remove people."
          button="Invite Member"
          onClick={() => setInvitingMember(true)}
        />
        <SettingsTable>
          <SettingsTableColumn>
            <SettingsTableHeader content="Email" />
            {members?.map((member) => (
              <SettingsTableCell key={member.user_id} content={member.email} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Role" />
            {members?.map((member) => (
              <SettingsTableCell
                key={member.user_id}
                content={formatRole(member.role)}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {members?.map((member) => (
              <SettingsTableIcon
                key={member.user_id}
                icon={<TrashIcon size={16} />}
                onClick={() => setDeletingMember(member.user_id)}
              />
            ))}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>

      {isAdmin && (
        <SettingsSection>
          <SettingsTitle
            title="Delete Organization"
            description="This will delete your organization and all data associated with it. Only the organization owner can delete it."
          />
          <div className="py-4 w-full flex flex-col items-start justify-center gap-1">
            <div className="w-full flex items-center justify-between">
              <p className="text-body text-brand-error">
                Warning: This cannot be undone.
              </p>
              <div className="flex items-center justify-center">
                <CTAButton
                  mode="error"
                  onClick={() => setDeletingOrganization(true)}
                >
                  Delete Organization
                </CTAButton>
              </div>
            </div>
          </div>
        </SettingsSection>
      )}

      {invitingMember && (
        <InviteTeammatePopup
          onSubmit={handleCreateInvite}
          close={() => setInvitingMember(false)}
        />
      )}

      {deletingMember && (
        <RemoveMemberPopup
          email={
            members?.find((m) => m.user_id === deletingMember)?.email ?? ''
          }
          onSubmit={() => handleDeleteMember(deletingMember)}
          close={() => setDeletingMember(null)}
        />
      )}

      {deletingOrganization && (
        <DeleteOrganizationPopup
          name={organizationInfo?.name ?? ''}
          onSubmit={() => handleDeleteOrganization()}
          close={() => setDeletingOrganization(false)}
        />
      )}
    </div>
  )
}

function formatRole(role: OrganizationRole) {
  switch (role) {
    case 'owner':
      return 'Owner'
    case 'admin':
      return 'Admin'
    case 'user':
      return 'Member'
  }
}

function isUserAdmin(userInfo: User | null, members: OrganizationMember[]) {
  return (
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'admin') ||
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'owner')
  )
}

function isOrgNameValid(currentOrgName: string, orgName: string) {
  return (
    orgName.trim().length > 0 &&
    orgName.trim().length <= 32 &&
    orgName !== currentOrgName
  )
}

export default OrganizationSettings
