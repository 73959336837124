import { useAtomValue } from 'jotai'
import useInitIntegrations from '../../../../hooks/data/effects/useInitIntegrations'
import useLoadIntegrations from '../../../../hooks/data/load/useLoadIntegrations'
import useConnectSlack from '../../../../hooks/utils/useConnectSlack'
import {
  integrationDetailsAtom,
  integrationsAtom,
} from '../../../../state/state'
import CodeCTAButton from '../../CodeCTAButton/CodeCTAButton'
import {
  ContactChannelSlackSettings,
  Integration,
  IntegrationExternalDetails,
} from '../../../../backend/types'
import PanelSection from '../../PanelSection/PanelSection'
import PanelRow from '../../PanelRow/PanelRow'
import CodeInput from '../../CodeInput/CodeInput'
import { useEffect } from 'react'
import useInitIntegrationDetails from '../../../../hooks/data/effects/useInitIntegrationDetails'
import CodeDropdown from '../../CodeDropdown/CodeDropdown'

interface SlackSettingsProps {
  connectionId: string | undefined
  setConnectionId: (connectionId: string | undefined) => void

  settings: ContactChannelSlackSettings
  setSettings: (settings: ContactChannelSlackSettings) => void
}

const SlackSettings = ({
  connectionId,
  setConnectionId,
  settings,
  setSettings,
}: SlackSettingsProps) => {
  useInitIntegrations()
  useInitIntegrationDetails(connectionId)

  const loadIntegrations = useLoadIntegrations()

  const connectSlack = useConnectSlack(async () => {
    await loadIntegrations()
  })

  const integrations = useAtomValue(integrationsAtom)
  const integrationDetails = useAtomValue(integrationDetailsAtom)
  const channelOptions = getChannelOptions(integrationDetails)

  useEffect(() => {
    if (connectionId || integrations.length === 0) return

    const slackIntegration = integrations.find(
      (integration) => integration.type === 'slack',
    )
    if (slackIntegration) {
      setConnectionId(slackIntegration.id)
    }
  }, [connectionId, integrations, setConnectionId])

  return (
    <>
      <PanelSection>
        <PanelRow>
          <p className="text-small-header text-text-1">Connect your Slack</p>
          {hasSlackIntegration(integrations) && (
            <div className="flex items-center justify-center py-1 px-3 rounded-[4px] text-small-code text-brand-success border bg-brand-successTint border-brand-successTint">
              Slack Connected
            </div>
          )}
          {!hasSlackIntegration(integrations) && (
            <CodeCTAButton
              mode="base"
              widthClass="w-fit"
              onClick={connectSlack}
            >
              Connect Slack
            </CodeCTAButton>
          )}
        </PanelRow>
      </PanelSection>
      <PanelSection>
        <PanelRow>
          <p className="text-small-header text-text-1">Slack Channel</p>
          <div className="w-[232px]">
            {channelOptions.length === 0 ? (
              <CodeInput
                value={settings.channel_name}
                placeholder="existing-channel-name"
                onChange={(value) =>
                  setSettings({ ...settings, channel_name: value })
                }
              />
            ) : (
              <CodeDropdown
                options={channelOptions}
                selected={settings.channel_name}
                placeholder="Select an existing channel"
                onChange={(value) =>
                  setSettings({ ...settings, channel_name: value })
                }
              />
            )}
          </div>
        </PanelRow>
      </PanelSection>
    </>
  )
}

function hasSlackIntegration(integrations: Integration[]) {
  return integrations.find((integration) => integration.type === 'slack')
}

function getChannelOptions(
  integrationDetails: IntegrationExternalDetails | null,
) {
  if (!integrationDetails) return []
  return (
    integrationDetails.details?.channels?.map((channel) => ({
      label: channel,
      value: channel,
    })) ?? []
  )
}

export default SlackSettings
