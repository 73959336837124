import { useAtom } from 'jotai'
import useJoinOrganization from './useJoinOrganization'
import { joinOrganizationTokenAtom } from '../../state/state'

const useJoinToken = () => {
  const [token, setToken] = useAtom(joinOrganizationTokenAtom)
  const joinOrganization = useJoinOrganization()

  return async () => {
    if (token) {
      await joinOrganization(token)
      setToken(null)
      return true
    }
    return false
  }
}

export default useJoinToken
