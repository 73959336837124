import { cn } from '../../../utils/cn'

interface SettingsTableCellProps {
  content: string
  color?: string
}

const SettingsTableCell = ({
  content,
  color = 'text-text-1',
}: SettingsTableCellProps) => {
  return (
    <div
      className={cn(
        'flex-none h-[40px] flex items-center text-body whitespace-nowrap border-b border-panel-border',
        color,
      )}
    >
      {content}
      <div className="flex-none w-16"></div>
    </div>
  )
}

export default SettingsTableCell
