import axios from 'axios'

export class HTTPService {
  private url: string

  constructor(url: string) {
    this.url = url
  }

  public async request<T>(
    path: string,
    method: string,
    body?: any,
    headers?: any,
    signal?: AbortSignal,
  ): Promise<T> {
    try {
      const response = await axios.request({
        method: method,
        url: this.url + path,
        data: body || {},
        headers: headers || {},
        signal: signal,
      })
      return response.data
    } catch (e) {
      throw e
    }
  }
}
