import { useEffect, useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import useLogin from '../../../hooks/actions/useLogin'
import useLoggedIn from '../../../hooks/utils/useLoggedIn'
import LabeledInput from '../../library/LabeledInput/LabeledInput'
import CTAButton from '../../library/CTAButton/CTAButton'
import { setBrandCTA } from '../../../utils/setBrandColor'

const Login: React.FC = () => {
  const loggedIn = useLoggedIn()

  const navigate = useNav()
  const login = useLogin()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await login(email, password)
  }

  useEffect(() => {
    setBrandCTA('success')
  }, [])

  if (loggedIn) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-main-bg">
      <form
        className="p-6 w-[360px] flex flex-col items-start justify-start gap-6 rounded-[4px] border border-panel-border"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col items-start justify-start gap-1.5">
          <h1 className="text-h1 text-text-1">Login</h1>
          <p className="text-body text-text-2">
            Enter your email to login to your account
          </p>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-4">
          <LabeledInput
            id="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e)}
            placeholder="your@email.com"
            type="email"
          />
          <LabeledInput
            id="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e)}
            placeholder="********"
            type="password"
          />
        </div>
        <div className="w-full flex flex-col items-start gap-4">
          <CTAButton type="submit">Login</CTAButton>
          <div className="flex items-center justify-start">
            <p className="font-inter text-[14px] text-text-2">
              Don't have an account?{' '}
              <span
                className="text-text-1 underline cursor-pointer"
                onClick={() => navigate(LinkKey.Signup)}
              >
                Sign Up
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
