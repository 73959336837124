import { Filter } from '../backend/types'

export const COLUMN_PREFIX = 'column.'
export const COLUMN_BODY = 'column.body'
export const COLUMN_LEVEL = 'column.severity_name'
export const COLUMN_TIMESTAMP = 'column.timestamp'

export const ATTRIBUTE_PREFIX = 'attribute.'
export const ATTRIBUTE_SERVICE = 'attribute.service.name'

export function propertyToDisplay(property: string): string {
  if (property === COLUMN_LEVEL) {
    return 'level'
  } else if (property.startsWith(ATTRIBUTE_PREFIX)) {
    return property.replace(ATTRIBUTE_PREFIX, '')
  } else if (property.startsWith(COLUMN_PREFIX)) {
    return property.replace(COLUMN_PREFIX, '')
  }
  return property
}

export function formatProperty(property: string): string {
  if (property.startsWith(ATTRIBUTE_PREFIX)) {
    return property.replace(ATTRIBUTE_PREFIX, '')
  } else if (property.startsWith(COLUMN_PREFIX)) {
    return property.replace(COLUMN_PREFIX, '')
  }
  return property
}

export function formatFilters(filters: Filter[]): Filter[] {
  return filters.map((filter) => {
    return {
      ...filter,
      field: formatProperty(filter.field),
    }
  })
}

export function formatNumber(number: number): string {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}m`
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(1)}k`
  }
  return number.toString()
}
