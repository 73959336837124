import { AuthService } from './auth'
import { HTTPService } from './http'
import {
  LogQueryResponse,
  LogCountRequest,
  LogCountResponse,
  LogQueryRequest,
  LogAttributeCountRequest,
  LogAttributeCountResponse,
  LogAttributeOptionsRequest,
  LogAttributeOptionsResponse,
  LogColumnOptionsRequest,
  LogColumnOptionsResponse,
  LogColumnCountResponse,
  LogColumnCountRequest,
  LogAttributeListRequest,
  LogAttributeListResponse,
} from './types'

export class QueryService {
  private httpService: HTTPService
  private authService: AuthService

  constructor(httpService: HTTPService, authService: AuthService) {
    this.httpService = httpService
    this.authService = authService
  }

  logsCount = async (
    request: LogCountRequest,
    signal?: AbortSignal,
  ): Promise<LogCountResponse> => {
    return this.httpService.request<LogCountResponse>(
      `/api/logs/count`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsQuery = async (
    request: LogQueryRequest,
    signal?: AbortSignal,
  ): Promise<LogQueryResponse> => {
    return this.httpService.request<LogQueryResponse>(
      `/api/logs/query`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsColumnCount = async (
    request: LogColumnCountRequest,
    signal?: AbortSignal,
  ): Promise<LogColumnCountResponse> => {
    return this.httpService.request<LogColumnCountResponse>(
      `/api/logs/column/count`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsColumnOptions = async (
    request: LogColumnOptionsRequest,
    signal?: AbortSignal,
  ): Promise<LogColumnOptionsResponse> => {
    return this.httpService.request<LogColumnOptionsResponse>(
      `/api/logs/column/options`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsAttributeCount = async (
    request: LogAttributeCountRequest,
    signal?: AbortSignal,
  ): Promise<LogAttributeCountResponse> => {
    return this.httpService.request<LogAttributeCountResponse>(
      `/api/logs/attribute/count`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsAttributeList = async (
    request: LogAttributeListRequest,
    signal?: AbortSignal,
  ): Promise<LogAttributeListResponse> => {
    return this.httpService.request<LogAttributeListResponse>(
      `/api/logs/attribute/list`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsAttributeOptions = async (
    request: LogAttributeOptionsRequest,
    signal?: AbortSignal,
  ): Promise<LogAttributeOptionsResponse> => {
    return this.httpService.request<LogAttributeOptionsResponse>(
      `/api/logs/attribute/options`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  private getAuthHeaders = (): Record<string, string> => {
    const headers: Record<string, string> = {}

    const token = this.authService.getUserToken()
    if (token !== '') {
      headers.Authorization = `Bearer ${token}`
    }

    const sessionId = this.authService.getUserSessionId()
    if (sessionId !== '') {
      headers['X-Session-Id'] = sessionId
    }

    const userEmail = this.authService.getUserEmail()
    if (userEmail !== '') {
      headers['X-User-Email'] = userEmail
    }

    return headers
  }
}
