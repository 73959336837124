interface SettingsTableButtonProps {
  content: React.ReactNode
  onClick?: () => void
}

const SettingsTableButton = ({
  content,
  onClick,
}: SettingsTableButtonProps) => {
  return (
    <button
      className="px-4 flex-none h-[40px] flex items-center text-body text-text-2 hover:text-text-1 whitespace-nowrap border-b border-panel-border cursor-pointer"
      onClick={onClick}
    >
      {content}
    </button>
  )
}

export default SettingsTableButton
