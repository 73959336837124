import { useMemo, useState } from 'react'
import Fullscreen from '../Fullscreen/Fullscreen'
import PopupContainer from '../PopupContainer/PopupContainer'
import PanelSection from '../PanelSection/PanelSection'
import PanelRow from '../PanelRow/PanelRow'
import { X } from 'lucide-react'
import {
  AlertCreateRequest,
  AlertInfo,
  AlertTriggerSettings,
  AlertTriggerType,
  AlertUpdateRequest,
  ContactChannel,
} from '../../../backend/types'
import CodeCTAButton from '../CodeCTAButton/CodeCTAButton'
import CodeInput from '../CodeInput/CodeInput'
import CodeDropdown from '../CodeDropdown/CodeDropdown'
import { contactChannelsAtom } from '../../../state/state'
import { useAtomValue } from 'jotai'
import useInitContactChannels from '../../../hooks/data/effects/useInitContactChannels'
import ContactChannelPopup from '../ContactChannelPopup/ContactChannelPopup'
import useCreateContactChannel from '../../../hooks/actions/useCreateContactChannel'
import LogFilters from './LogFilters/LogFilters'
import _ from 'lodash'
import useUpdateContactChannel from '../../../hooks/actions/useUpdateContactChannel'

interface AlertPopupProps {
  info?: AlertInfo | undefined
  close?: () => void
  onCreate?: (info: AlertCreateRequest) => Promise<void>
  onUpdate?: (info: AlertUpdateRequest) => Promise<void>
}

const AlertPopup: React.FC<AlertPopupProps> = ({
  info,
  close,
  onCreate,
  onUpdate,
}) => {
  useInitContactChannels()

  const createContactChannel = useCreateContactChannel()
  const updateContactChannel = useUpdateContactChannel()

  const contactChannels = useAtomValue(contactChannelsAtom)

  const [trigger, setTrigger] = useState(getInitialTrigger(info))
  const [settings, setSettings] = useState(getInitialSettings(info))
  const [chanId, setChanId] = useState(getInitialChanId(info, contactChannels))
  const [frequency, setFrequency] = useState(getInitialFrequency(info))
  const [name, setName] = useState(getInitialName(info))

  const [createIsOpen, setCreateIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [editId, setEditId] = useState<string | undefined>(undefined)

  const valid = useMemo(
    () => isValid(name, trigger, settings, chanId),
    [name, trigger, settings, chanId],
  )

  const handleCreateContactChannel = async (
    contactChannel: Omit<ContactChannel, 'id'>,
  ) => {
    const id = await createContactChannel(contactChannel)
    if (!id) return
    setChanId(id)
    setCreateIsOpen(false)
  }

  const handleUpdateContactChannel = async (
    contactChannel: Omit<ContactChannel, 'id'>,
  ) => {
    if (!editId) return
    await updateContactChannel(editId, contactChannel)
    setEditId(undefined)
    setEditIsOpen(false)
  }

  const handleSubmit = async () => {
    if (!valid || !chanId) return
    if (!info && onCreate) {
      const request = getCreateRequest(
        name,
        frequency,
        chanId,
        trigger,
        settings,
      )
      await onCreate(request)
    } else if (info && onUpdate) {
      const request = getUpdateRequest(
        info,
        name,
        frequency,
        chanId,
        trigger,
        settings,
      )
      await onUpdate(request)
    }
  }

  return (
    <Fullscreen>
      <PopupContainer className="w-[612px]">
        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Alert</p>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </PanelRow>
        </PanelSection>

        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Trigger</p>
            <div className="w-[232px]">
              <CodeDropdown
                selected={trigger}
                options={triggerOptions}
                onChange={setTrigger}
              />
            </div>
          </PanelRow>
        </PanelSection>

        {trigger === 'log' && (
          <LogFilters settings={settings} setSettings={setSettings} />
        )}

        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Rate Limit</p>
            <div className="w-[232px]">
              <CodeDropdown
                selected={frequency}
                options={frequencyOptions}
                onChange={setFrequency}
              />
            </div>
          </PanelRow>
        </PanelSection>

        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Destination</p>
            <div className="flex gap-2">
              <CodeCTAButton
                widthClass="w-fit"
                onClick={() => setCreateIsOpen(true)}
              >
                New
              </CodeCTAButton>
              {chanId && (
                <CodeCTAButton
                  widthClass="w-fit"
                  onClick={() => {
                    setEditId(chanId)
                    setEditIsOpen(true)
                  }}
                >
                  Edit
                </CodeCTAButton>
              )}
              <div className="w-[232px]">
                <CodeDropdown
                  selected={chanId}
                  placeholder={'No contact channels found'}
                  options={getChanOptions(contactChannels)}
                  onChange={setChanId}
                />
              </div>
            </div>
          </PanelRow>
        </PanelSection>

        <PanelSection>
          <PanelRow>
            <span className="text-small-header text-text-1">Name</span>
            <div className="flex gap-2 items-center">
              <span className="text-small text-text-2">
                This appears in the alert message.
              </span>
              <div className="w-[232px]">
                <CodeInput
                  value={name}
                  onChange={setName}
                  placeholder="Prod Server Alert"
                />
              </div>
            </div>
          </PanelRow>
        </PanelSection>

        <PanelSection>
          <div className="w-full flex justify-end px-2 gap-4">
            <CodeCTAButton
              mode="success"
              widthClass="w-fit"
              onClick={handleSubmit}
              disabled={!valid}
            >
              {info ? 'Update' : 'Create'}
            </CodeCTAButton>
          </div>
        </PanelSection>

        {createIsOpen && (
          <ContactChannelPopup
            close={() => setCreateIsOpen(false)}
            onSubmit={handleCreateContactChannel}
          />
        )}
        {editIsOpen && (
          <ContactChannelPopup
            info={contactChannels.find((c) => c.id === chanId)}
            close={() => setEditIsOpen(false)}
            onSubmit={handleUpdateContactChannel}
          />
        )}
      </PopupContainer>
    </Fullscreen>
  )
}

const triggerOptions: { label: string; value: AlertTriggerType }[] = [
  { label: 'When I receive a log', value: 'log' },
]

const frequencyOptions: { label: string; value: number }[] = [
  { label: 'Send every time', value: 0 },
  { label: 'At most every 60 seconds', value: 60_000_000_000 },
  { label: 'At most every 5 minutes', value: 300_000_000_000 },
  { label: 'At most every hour', value: 3600_000_000_000 },
  { label: 'At most every 6 hours', value: 21600_000_000_000 },
  { label: 'At most every day', value: 86400_000_000_000 },
]

function isValid(
  name: string,
  trigger: AlertTriggerType,
  settings: AlertTriggerSettings,
  chanId: string | undefined,
) {
  switch (trigger) {
    case 'log':
      return (
        settings.clauses.length > 0 &&
        settings.clauses.every(
          (clause) => clause.field !== '' && clause.value !== '',
        ) &&
        chanId !== undefined &&
        name.length > 0
      )
    default:
      return name.length > 0
  }
}

function getInitialTrigger(info?: AlertInfo): AlertTriggerType {
  if (info?.triggers[0]?.type) return info.triggers[0].type
  return 'log'
}

function getInitialSettings(info?: AlertInfo): AlertTriggerSettings {
  if (info?.triggers[0]?.settings) return info.triggers[0].settings
  return {
    clauses: [{ field: 'body', operator: 'contains', value: '' }],
  }
}

function getInitialChanId(
  info?: AlertInfo,
  contactChannels?: ContactChannel[],
): string | undefined {
  if (info?.contact_channels[0]?.contact_channel_id)
    return info.contact_channels[0].contact_channel_id
  if (contactChannels?.[0]?.id) return contactChannels[0].id
  return undefined
}

function getInitialFrequency(info?: AlertInfo): number {
  if (info?.alert?.frequency) return info.alert.frequency
  return 0
}

function getInitialName(info?: AlertInfo): string {
  if (info?.alert?.name) return info.alert.name
  return ''
}

function getChanOptions(contactChannels: ContactChannel[]) {
  return contactChannels.map((contactChannel) => ({
    label: contactChannel.name,
    value: contactChannel.id,
  }))
}

function getCreateRequest(
  name: string,
  frequency: number,
  chanId: string,
  trigger: AlertTriggerType,
  settings: AlertTriggerSettings,
): AlertCreateRequest {
  return {
    name,
    frequency,
    contact_channels: [{ contact_channel_id: chanId }],
    triggers: [{ type: trigger, settings: settings }],
  }
}
function getUpdateRequest(
  info: AlertInfo,
  name: string,
  frequency: number,
  chanId: string,
  trigger: AlertTriggerType,
  settings: AlertTriggerSettings,
): AlertUpdateRequest {
  const contact_channels = [{ contact_channel_id: chanId }]
  const triggers = [{ type: trigger, settings: settings }]

  const nameEqual = name === info.alert.name
  const frequencyEqual = frequency === info.alert.frequency

  const contactChannelsEqual =
    contact_channels.length === info.contact_channels.length &&
    contact_channels.every((contact_channel) =>
      info.contact_channels.some(
        (ic) => ic.contact_channel_id === contact_channel.contact_channel_id,
      ),
    )

  const triggersEqual =
    triggers.length === info.triggers.length &&
    triggers.every((trigger) =>
      info.triggers.some(
        (it) =>
          it.type === trigger.type && _.isEqual(it.settings, trigger.settings),
      ),
    )

  const update: AlertUpdateRequest = {
    name: nameEqual ? undefined : name,
    frequency: frequencyEqual ? undefined : frequency,
    contact_channels: contactChannelsEqual ? undefined : contact_channels,
    triggers: triggersEqual ? undefined : triggers,
  }

  return update
}

export default AlertPopup
