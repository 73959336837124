export class AuthService {
  getUserToken = (): string => {
    return localStorage.getItem('bearerToken') || ''
  }

  setUserToken = (token: string): void => {
    localStorage.setItem('bearerToken', token)
  }

  clearUserToken = (): void => {
    localStorage.removeItem('bearerToken')
  }

  getUserEmail = (): string => {
    return localStorage.getItem('userEmail') || ''
  }

  getUserSessionId = (): string => {
    return sessionStorage.getItem('sessionId') || ''
  }
}
