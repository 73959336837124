import { ContactChannelUpdateRequest } from '../../backend/types'
import useLoadContactChannels from '../data/load/useLoadContactChannels'
import useBackend from '../services/useBackend'

const useUpdateContactChannel = () => {
  const backend = useBackend()

  const loadContactChannels = useLoadContactChannels()

  const updateContactChannel = async (
    id: string,
    request: ContactChannelUpdateRequest,
  ) => {
    await backend.updateContactChannel(id, request)
    await loadContactChannels()
  }

  return updateContactChannel
}

export default useUpdateContactChannel
