import PanelSection from '../../../../../library/PanelSection/PanelSection'
import PanelRow from '../../../../../library/PanelRow/PanelRow'
import {
  AlertInfo,
  AlertStatus,
  AlertTrigger,
  ContactChannel,
} from '../../../../../../backend/types'
import { formatNumber } from '../../../../../../utils/properties'

interface AlertDetailsSidebarProps {
  alert: AlertInfo
  contactChannels: ContactChannel[]
}

const AlertDetailsSidebar: React.FC<AlertDetailsSidebarProps> = ({
  alert,
  contactChannels,
}) => {
  return (
    <div className="w-[380px] flex flex-col border-l border-main-border">
      <PanelSection>
        <PanelRow>
          <h3 className="text-small-header text-text-1">Details</h3>
        </PanelRow>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="w-[120px] text-small text-text-2">Status</p>
          <p className="text-small text-text-2">
            {formatStatus(alert?.alert.status)}
          </p>
        </div>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">
            Times Fired
          </p>
          <p className="text-small">{formatCount(alert?.event_total)}</p>
        </div>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">
            Last 24 Hours
          </p>
          <p className="text-small">{formatCount(alert?.event_total_24hr)}</p>
        </div>
      </PanelSection>
      <PanelSection>
        <PanelRow>
          <h3 className="text-small-header text-text-1">Settings</h3>
        </PanelRow>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">Trigger</p>
          <p className="text-small text-text-1">
            {formatTrigger(alert?.triggers[0])}
          </p>
        </div>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">
            Trigger Settings
          </p>
          <div className="flex flex-col gap-1 text-small text-text-1 overflow-scroll">
            {formatTriggerSettings(alert?.triggers[0])}
          </div>
        </div>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">
            Destination
          </p>
          <div className="flex flex-col gap-1 text-small text-text-1">
            {formatContactChannel(
              alert?.contact_channels[0]?.contact_channel_id,
              contactChannels,
            )}
          </div>
        </div>
        <div className="px-2 py-1 w-full flex gap-2">
          <p className="flex-none w-[120px] text-small text-text-2">
            Frequency
          </p>
          <p className="text-small text-text-1">
            {formatFrequency(alert?.alert.frequency ?? 0)}
          </p>
        </div>
      </PanelSection>
    </div>
  )
}

function formatStatus(status: AlertStatus | undefined) {
  switch (status) {
    case 'active':
      return <span className="text-brand-success">Active</span>
    case 'muted':
      return <span className="text-brand-error">Muted</span>
    default:
      return <span className="text-text-2">Unknown</span>
  }
}

function formatCount(count: number | undefined) {
  if (count === undefined || count === 0) {
    return <span className="text-text-2">Never</span>
  } else {
    return <span className="text-text-1">{formatNumber(count)}</span>
  }
}

function formatTrigger(trigger: AlertTrigger | undefined) {
  switch (trigger?.type) {
    case 'log':
      return 'When I receive a log'
    default:
      return 'Unknown'
  }
}

function formatTriggerSettings(trigger: AlertTrigger | undefined) {
  switch (trigger?.type) {
    case 'log':
      return trigger.settings.clauses.map((clause, index) => {
        return (
          <p className="text-small whitespace-nowrap" key={index}>
            {index < trigger.settings.clauses.length - 1 && (
              <span>
                {clause.field} {clause.operator} {clause.value} and
              </span>
            )}
            {index === trigger.settings.clauses.length - 1 && (
              <span>
                {clause.field} {clause.operator} {clause.value}
              </span>
            )}
          </p>
        )
      })
    default:
      return 'Unknown'
  }
}

function formatContactChannel(
  id: string | undefined,
  contactChannels: ContactChannel[],
) {
  if (!id) return 'Unknown'

  const contactChannel = contactChannels.find(
    (contactChannel) => contactChannel.id === id,
  )

  if (!contactChannel) return 'Unknown'

  return contactChannel.name
}

function formatFrequency(frequency: number) {
  switch (frequency) {
    case 0:
      return 'Send every time'
    case 60_000_000_000:
      return 'At most every 60 seconds'
    case 300_000_000_000:
      return 'At most every 5 minutes'
    case 3600_000_000_000:
      return 'At most every hour'
    case 21600_000_000_000:
      return 'At most every 12 hours'
    case 86400_000_000_000:
      return 'At most every day'
    default:
      return 'Unknown'
  }
}

export default AlertDetailsSidebar
