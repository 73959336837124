import { useSetAtom } from 'jotai'
import { toastsAtom } from '../../state/state'
import {
  ToastItem,
  ToastType,
} from '../../components/library/ToastContainer/ToastContainer'

const useToast = () => {
  const setToasts = useSetAtom(toastsAtom)

  const createToast = (message: string, type?: ToastType) => {
    const id = Math.random().toString(36).substring(2)
    const newToast: ToastItem = {
      id,
      message,
      type: type || 'info',
    }

    setToasts((prev) => [...prev, newToast])

    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id))
    }, 4000)
  }

  return { createToast }
}

export default useToast
