import { useEffect } from 'react'
import useLoadContactChannels from '../load/useLoadContactChannels'

const useInitContactChannels = () => {
  const loadContactChannels = useLoadContactChannels()

  useEffect(() => {
    loadContactChannels()
  }, [loadContactChannels])
}

export default useInitContactChannels
