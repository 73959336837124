import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, alertEventsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadAlertEvents = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setAlertEvents = useSetAtom(alertEventsAtom)

  const loadAlertEvents = useCallback(
    async (alertId: string) => {
      if (!projectId) return
      try {
        const { events } = await backend.getAlertEvents(alertId)
        setAlertEvents(events)
      } catch (e) {}
    },
    [backend, setAlertEvents, projectId],
  )

  return loadAlertEvents
}

export default useLoadAlertEvents
