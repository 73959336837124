import { Filter, LogCursor, ValueCount } from '../../backend/types'
import { LogEntry } from '../../state/types'
import { TimeRange } from '../../utils/time'

export const SERVICE_FIELD = 'service.name'
export const LEVEL_FIELD = 'severity_name'

export type QueryMode =
  | 'initial_load'
  | 'user_query'
  | 'live_tick'
  | 'loading_more'
  | 'none'

export type QueryParameters = {
  term: string
  timeRange: TimeRange
  filters: Filter[]
  properties: string[]
  limit: number
  cursor: LogCursor | undefined
}

export type QueryRequestState = {
  term: string
  timerange: TimeRange
  filters: Filter[]
  properties: string[]
  limit: number
  cursor: LogCursor | undefined
}

export type QueryResponseSuccess = {
  result: 'success'
  type: 'query'
  count: number
  logs: LogEntry[]
  properties: ValueCount[]
  serviceOptions: ValueCount[]
  levelOptions: ValueCount[]
  done: boolean
}

export type QueryResponseCanceled = {
  result: 'canceled'
  type: 'query'
}

export type LoadMoreResponseSuccess = {
  result: 'success'
  type: 'loadMore'
  logs: LogEntry[]
  done: boolean
}

export type LoadMoreResponseCanceled = {
  result: 'canceled'
  type: 'loadMore'
}

export type QueryResponse = QueryResponseSuccess | QueryResponseCanceled

export type LoadMoreResponse =
  | LoadMoreResponseSuccess
  | LoadMoreResponseCanceled

export type QueryResultState = {
  count: number
  logs: LogEntry[]
  properties: ValueCount[]
  serviceOptions: ValueCount[]
  levelOptions: ValueCount[]
  done: boolean
}

export type QueryState = {
  index: number
  mode: QueryMode
  request: QueryRequestState
  controller: AbortController
  result: QueryResultState | null
}
