interface SettingsTableHeaderProps {
  content: string
}

const SettingsTableHeader = ({ content }: SettingsTableHeaderProps) => {
  return (
    <div className="flex-none h-[40px] flex items-center text-label text-text-2 border-b border-panel-border">
      {content}
    </div>
  )
}

export default SettingsTableHeader
