import { Plus, Search } from 'lucide-react'
import { useRef } from 'react'

interface SearchbarProps {
  term: string
  setTerm: (term: string) => void
  onClickCreate: () => void
}

const Searchbar = ({ term, setTerm, onClickCreate }: SearchbarProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputRef.current?.focus()
  }

  const handleFocus = () => {
    if (!inputRef.current) return
    inputRef.current.select()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  return (
    <div className="p-4 w-full flex items-center gap-2 border-b border-main-border">
      <div
        className="px-3 py-2 w-full rounded-[4px] bg-panel-bg border border-input-bgTint hover:bg-input-tint flex items-center gap-2 cursor-text"
        onClick={handleClick}
      >
        <Search className="w-4 h-4 text-text-2" />
        <input
          ref={inputRef}
          className="flex-1 bg-transparent outline-none text-small text-text-1 placeholder:text-text-2"
          placeholder="Search Alerts"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      </div>
      <button
        className="flex-none h-full px-4 py-2 rounded-[4px] flex items-center gap-2 bg-brand-ctaTint border border-brand-ctaTint hover:border-brand-cta"
        onClick={onClickCreate}
      >
        <Plus className="w-4 h-4 text-brand-cta" />
        <p className="text-small text-brand-cta">New Alert</p>
      </button>
    </div>
  )
}

export default Searchbar
