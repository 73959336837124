interface SettingsSectionProps {
  children: React.ReactNode
}

const SettingsSection = ({ children }: SettingsSectionProps) => {
  return (
    <div className="w-full max-w-[1080px] flex flex-col items-start justify-center pb-[20px]">
      {children}
    </div>
  )
}

export default SettingsSection
