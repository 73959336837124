import { Outlet } from 'react-router-dom'
import SettingsSidebar from './SettingsSidebar/SettingsSidebar'
import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import { setBrandCTA } from '../../../../utils/setBrandColor'
import { useEffect } from 'react'

const Settings = () => {
  useEffect(() => {
    setBrandCTA('success')
  }, [])

  return (
    <div className="h-full w-full flex flex-col">
      <NavbarPlaceholder />
      <div className="flex-1 flex overflow-hidden">
        <SettingsSidebar />
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
