interface OptionButtonProps {
  title: string
  selected: boolean
  onClick: () => void
}

const OptionButton = ({ title, selected, onClick }: OptionButtonProps) => {
  const selectedClass = selected ? 'text-text-1 bg-code-bg' : 'text-text-2'

  return (
    <button
      className={`px-4 py-3 text-body hover:text-text-1 ${selectedClass}`}
      onClick={onClick}
    >
      {title}
    </button>
  )
}

export default OptionButton
