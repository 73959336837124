import { AlertUpdateRequest } from '../../backend/types'
import useLoadAlerts from '../data/load/useLoadAlerts'
import useBackend from '../services/useBackend'

const useUpdateAlert = () => {
  const backend = useBackend()

  const loadAlerts = useLoadAlerts()

  const updateAlert = async (id: string, request: AlertUpdateRequest) => {
    try {
      await backend.updateAlert(id, request)
      await loadAlerts()
    } catch (error) {}
  }

  return updateAlert
}

export default useUpdateAlert
