import { ContactChannelCreateRequest } from '../../backend/types'
import useLoadContactChannels from '../data/load/useLoadContactChannels'
import useBackend from '../services/useBackend'

const useCreateContactChannel = () => {
  const backend = useBackend()

  const loadContactChannels = useLoadContactChannels()

  const createContactChannel = async (
    request: ContactChannelCreateRequest,
  ): Promise<string | undefined> => {
    try {
      const response = await backend.createContactChannel(request)
      await loadContactChannels()
      return response.id
    } catch (error) {
      return undefined
    }
  }

  return createContactChannel
}

export default useCreateContactChannel
