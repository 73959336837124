import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, tokensAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadTokens = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setTokens = useSetAtom(tokensAtom)

  const loadTokens = useCallback(async () => {
    if (!projectId) return
    try {
      const { tokens } = await backend.getProjectTokens(projectId)
      setTokens(tokens)
    } catch (e) {}
  }, [backend, setTokens, projectId])

  return loadTokens
}

export default useLoadTokens
