import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../hooks/utils/useClickOutside'
import Fullscreen from '../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import LabeledInput from '../../../../../library/LabeledInput/LabeledInput'
import CTAButton from '../../../../../library/CTAButton/CTAButton'

interface NewTokenPopupProps {
  onSubmit: (name: string, description: string) => Promise<void>
  close: () => void
}

const NewTokenPopup: React.FC<NewTokenPopupProps> = ({ onSubmit, close }) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const [tokenName, setTokenName] = useState('')
  const [tokenDescription, setTokenDescription] = useState('')

  const handleSubmit = async () => {
    await onSubmit(tokenName, tokenDescription || 'API Token for Vigilant.')
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">New API Token</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <LabeledInput
            label="Token Name"
            placeholder="Enter a name for your token"
            value={tokenName}
            onChange={(v) => setTokenName(v)}
          />
          <LabeledInput
            label="Token Description"
            placeholder="Enter a description for your token (optional)"
            value={tokenDescription}
            onChange={(v) => setTokenDescription(v)}
          />
        </PanelSection>
        <PanelSection bottom={true}>
          <div className="w-fit">
            <CTAButton disabled={!tokenName} onClick={handleSubmit}>
              Create Token
            </CTAButton>
          </div>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default NewTokenPopup
