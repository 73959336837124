import { LogEntry } from '../../../../../../../../state/types'
import { getFormattedExtractor } from '../../../../../../../../utils/logs'

interface LogCellProps {
  term: string
  width: number
  column: string
  log: LogEntry
}

const LogCell = ({ term, width, column, log }: LogCellProps) => {
  const content = getFormattedExtractor(column)(log)
  return (
    <div
      className="relative flex-none px-2 py-1 min-h-6 flex items-start justify-between gap-1 border-r border-main-border"
      style={{ width }}
    >
      <p className="text-small-code text-text-1 whitespace-pre-wrap break-all overflow-x-scroll">
        {highlightText(content, term)}
      </p>
    </div>
  )
}

function highlightText(text: string, searchTerm: string): React.ReactNode {
  if (!searchTerm || typeof text !== 'string') return text

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))
  return parts.map((part, i) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={i} className="text-brand-error">
        {part}
      </span>
    ) : (
      part
    ),
  )
}

export default LogCell
