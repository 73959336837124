import { useMemo } from 'react'
import useQuery from '../services/useQuery'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import { QueryManager } from '../../library/query/queryManager'
import { QueryRunner } from '../../library/query/queryRunner'

const useQueryManager = () => {
  const { projectId } = useAtomValue(instanceIdAtom)
  const queryService = useQuery()

  const queryManager = useMemo(() => {
    if (!projectId) return null
    const queryRunner = new QueryRunner(projectId, queryService)
    return new QueryManager(queryRunner)
  }, [projectId, queryService])

  return queryManager
}

export default useQueryManager
