interface SettingsTableIconProps {
  icon: React.ReactNode
  onClick?: () => void
}

const SettingsTableIcon = ({ icon, onClick }: SettingsTableIconProps) => {
  return (
    <div
      className="px-2 flex-none h-[40px] flex items-center text-body text-text-2 hover:text-text-1 whitespace-nowrap border-b border-panel-border cursor-pointer"
      onClick={onClick}
    >
      {icon}
    </div>
  )
}

export default SettingsTableIcon
