import { useEffect } from 'react'
import useLoadIntegrationDetails from '../load/useLoadIntegrationDetails'

const useInitIntegrationDetails = (integrationId: string | undefined) => {
  const loadIntegrationDetails = useLoadIntegrationDetails()

  useEffect(() => {
    if (!integrationId) return
    loadIntegrationDetails(integrationId)
  }, [loadIntegrationDetails, integrationId])
}

export default useInitIntegrationDetails
