import { useRef, useState } from 'react'
import WordToggle, { WordDetails } from '../WordToggle/WordToggle'
import { Bug, CircleHelp, MessagesSquare, X } from 'lucide-react'
import LabeledArea from '../LabeledArea/LabeledArea'
import CTAButton from '../CTAButton/CTAButton'
import useClickOutside from '../../../hooks/utils/useClickOutside'
import Fullscreen from '../Fullscreen/Fullscreen'
import PopupContainer from '../PopupContainer/PopupContainer'
import PanelSection from '../PanelSection/PanelSection'
import useSendSupport from '../../../hooks/actions/useSendSupport'
import { atom, useAtom } from 'jotai'

interface HelpPopupProps {
  close: () => void
}

type HelpMode = 'bug' | 'question' | 'feedback'

export const helpPopupModeAtom = atom<HelpMode>('bug')

const HelpPopup = ({ close }: HelpPopupProps) => {
  const sendSupport = useSendSupport()

  const popupRef = useRef<HTMLDivElement | null>(null)
  useClickOutside(popupRef, () => close())

  const [mode, setMode] = useAtom(helpPopupModeAtom)
  const [description, setDescription] = useState('')

  const handleSubmit = () => {
    sendSupport(`${mode}: ${description}`)
    close()
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[496px]">
        <PanelSection>
          <div className="flex items-center justify-between">
            <h2 className="text-h2 text-text-1">Contact Support</h2>
            <X
              size={16}
              className="text-text-2 cursor-pointer hover:text-text-1"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-4">
          <p className="text-body text-text-1">
            How can we help? Please provide as much detail as you can. We'll
            reply as soon as possible.
          </p>
          <WordToggle words={modes} active={mode} setActive={setMode} />
          <LabeledArea
            label={getLabel(mode)}
            placeholder={getPlaceholder(mode)}
            value={description}
            onChange={setDescription}
          />
          <p className="text-body text-text-2">
            If you want an especially fast response, try our{' '}
            <span
              className="text-brand-info hover:underline cursor-pointer"
              onClick={() =>
                window.open('https://discord.com/invite/Kr9YR2XUqr', '_blank')
              }
            >
              Discord
            </span>
            .
          </p>
        </PanelSection>
        <PanelSection>
          <CTAButton disabled={!description} onClick={handleSubmit}>
            Submit
          </CTAButton>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

const modes: WordDetails<HelpMode>[] = [
  { value: 'bug', title: 'Bug Report', icon: <Bug size={16} /> },
  { value: 'question', title: 'Question', icon: <CircleHelp size={16} /> },
  { value: 'feedback', title: 'Feedback', icon: <MessagesSquare size={16} /> },
]

const getLabel = (mode: HelpMode) => {
  switch (mode) {
    case 'bug':
      return 'What happened?'
    case 'question':
      return 'What do you want to know?'
    case 'feedback':
      return 'What can we improve?'
  }
}

const getPlaceholder = (mode: HelpMode) => {
  switch (mode) {
    case 'bug':
      return 'Please describe the bug you encountered. We can be most helpful if you include lots of details, like steps to reproduce.'
    case 'question':
      return 'Please describe your question.'
    case 'feedback':
      return 'Please describe your feedback.'
  }
}

export default HelpPopup
