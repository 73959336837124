import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useProjectSelected from '../../../hooks/utils/useProjectSelected'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar, { NavbarTab } from '../../library/Navbar/Navbar'
import { LinkKey } from '../../../hooks/utils/useNav'
import { linkMap } from '../../../hooks/utils/useNav'
import useNav from '../../../hooks/utils/useNav'
import LogDetails from '../../library/LogDetails/LogDetails'

const Dashboard: React.FC = () => {
  const loggedOut = useLoggedOut()
  const navigate = useNav()
  const location = useLocation()
  const activeTab = getActiveTab(location.pathname)

  useInitAdminData()
  useProjectSelected()

  if (loggedOut) return <></>

  const setActiveTab = (tab: NavbarTab | null) => {
    switch (tab) {
      case 'logs':
        navigate(LinkKey.Logs)
        break
      case 'alerts':
        navigate(LinkKey.Alerts)
        break
      case 'settings':
        navigate(LinkKey.ProjectSettings)
        break
    }
  }

  return (
    <div className="h-screen w-screen bg-background">
      <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      <Outlet />
      <LogDetails />
    </div>
  )
}

const getActiveTab = (location: string): NavbarTab | null => {
  if (location.includes(linkMap[LinkKey.OrganizationSettings]))
    return 'settings'
  if (location.includes(linkMap[LinkKey.AccountSettings])) return 'settings'
  if (location.includes(linkMap[LinkKey.ProjectSettings])) return 'settings'
  if (location.includes(linkMap[LinkKey.AlertSettings])) return 'settings'
  if (location.includes(linkMap[LinkKey.Logs])) return 'logs'
  if (location.includes(linkMap[LinkKey.Alerts])) return 'alerts'
  return null
}

export default Dashboard
