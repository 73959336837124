import { ChevronDown } from 'lucide-react'
import { useRef, useState } from 'react'
import PanelSection from '../PanelSection/PanelSection'
import CodeButton from '../CodeButton/CodeButton'
import useClickOutside from '../../../hooks/utils/useClickOutside'

type Option<T> = { label: string; value: T }

interface CodeDropdownProps<T> {
  selected: T | undefined
  options: Option<T>[]

  placeholder?: string
  onChange: (value: T) => void
}

const CodeDropdown = <T extends string | number>({
  selected,
  options,
  placeholder,
  onChange,
}: CodeDropdownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => setOpen(false))

  const [open, setOpen] = useState(false)
  const selectedOption = options.find((option) => option.value === selected)

  const handleDropdownClick = () => {
    if (options.length === 0) return
    setOpen(!open)
  }

  const handleOptionClick = (value: T) => {
    onChange(value)
    setOpen(false)
  }

  const hoverClass = options.length > 0 ? 'hover:bg-input-tint' : ''

  return (
    <div className="relative w-full flex flex-col gap-2">
      <button
        className={`p-1 pl-3 w-full flex items-center justify-between gap-1 bg-input-bgTint ${hoverClass} rounded-[4px]`}
        onClick={handleDropdownClick}
      >
        {selectedOption ? (
          <p className="text-small-code text-text-1">{selectedOption.label}</p>
        ) : (
          <p className="text-small-code text-text-2">{placeholder}</p>
        )}
        <ChevronDown className="w-4 h-4 text-text-1" />
      </button>
      {open && (
        <div
          ref={dropdownRef}
          className="absolute top-0 left-0 min-w-full bg-main-bg border border-main-border rounded-[4px] z-[3]"
        >
          <PanelSection bottom={true} className="max-h-[200px] overflow-y-auto">
            {options.map((option) => (
              <div className="w-full flex" key={option.value}>
                <CodeButton
                  text={option.label}
                  onClick={() => handleOptionClick(option.value)}
                />
              </div>
            ))}
          </PanelSection>
        </div>
      )}
    </div>
  )
}

export default CodeDropdown
