import axios from 'axios'
import { QueryService } from '../../backend/query'
import { OptionsQueryParameters, OptionsQueryResponse } from './types'
import { getTimestamps } from '../../utils/time'
import {
  formatFilters,
  formatProperty,
  COLUMN_TIMESTAMP,
  COLUMN_LEVEL,
  COLUMN_BODY,
} from '../../utils/properties'
import {
  LogAttributeOptionsRequest,
  LogColumnOptionsRequest,
} from '../../backend/types'

export class QueryOptionsRunner {
  private projectId: string
  private queryService: QueryService

  constructor(projectId: string, queryService: QueryService) {
    this.projectId = projectId
    this.queryService = queryService
  }

  queryOptions = async (
    query: OptionsQueryParameters,
    signal?: AbortSignal,
  ): Promise<OptionsQueryResponse> => {
    return this.runOptionsQuery(query, signal)
  }

  private runOptionsQuery = async (
    request: OptionsQueryParameters,
    signal?: AbortSignal,
  ): Promise<OptionsQueryResponse> => {
    try {
      if (signal?.aborted) {
        return { type: 'canceled' }
      }

      switch (request.field) {
        case COLUMN_TIMESTAMP:
        case COLUMN_LEVEL:
        case COLUMN_BODY:
          const columnQuery: LogColumnOptionsRequest = {
            project_id: this.projectId,
            search_term: request.term,
            start_timestamp: getTimestamps(request.timeRange)[0],
            end_timestamp: getTimestamps(request.timeRange)[1],
            filters: formatFilters(request.filters),
            properties: [],
            field: formatProperty(request.field),
            value: request.value,
          }
          const columnResult = await this.queryService.logsColumnOptions(
            columnQuery,
            signal,
          )
          if (signal?.aborted) {
            return { type: 'canceled' }
          }

          return { type: 'success', options: columnResult.options ?? [] }
        default:
          const attributeQuery: LogAttributeOptionsRequest = {
            project_id: this.projectId,
            search_term: request.term,
            start_timestamp: getTimestamps(request.timeRange)[0],
            end_timestamp: getTimestamps(request.timeRange)[1],
            filters: formatFilters(request.filters),
            properties: [],
            field: formatProperty(request.field),
            value: request.value,
          }
          const attrsResult = await this.queryService.logsAttributeOptions(
            attributeQuery,
            signal,
          )
          if (signal?.aborted) {
            return { type: 'canceled' }
          }

          return { type: 'success', options: attrsResult.options ?? [] }
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e)
      }
      return { type: 'canceled' }
    }
  }
}
