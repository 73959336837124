const HeaderRow = () => {
  return (
    <div className="sticky top-0 px-8 flex flex-row items-center gap-2 bg-panel-bg border-b border-main-border">
      <div className="w-[200px] py-4 flex flex-row items-center">
        <p className="text-small-header text-text-2">Alert Name</p>
      </div>
      <div className="hidden xl:flex w-[200px] py-4 flex-row items-center">
        <p className="text-small-header text-text-2">Destination</p>
      </div>
      <div className="hidden lg:flex w-[200px] py-4 flex-row items-center">
        <p className="text-small-header text-text-2">Last Alert</p>
      </div>
      <div className="hidden xl:flex w-[100px] py-4 flex flex-row items-center">
        <p className="text-small-header text-text-2">Status</p>
      </div>
      <div className="hidden md:flex w-[80px] py-4 flex flex-row items-center">
        <p className="text-small-header text-text-2">Total</p>
      </div>
      <div className="hidden md:flex flex-1 py-4 flex flex-row items-center">
        <p className="text-small-header text-text-2">24hr</p>
      </div>
    </div>
  )
}

export default HeaderRow
