import { useCallback, useRef } from 'react'
import { propertyToDisplay } from '../../../../../../../../utils/properties'
import { cn } from '../../../../../../../../utils/cn'

interface LogHeaderCellProps {
  headerRef: React.RefObject<HTMLDivElement | null>

  width: number

  resizing: boolean
  setResizing: (e: React.MouseEvent<HTMLDivElement>) => void

  dragging: boolean
  setDragging: (e: React.MouseEvent<HTMLDivElement>) => void

  header: string
}

const LogHeaderCell = ({
  headerRef,
  width,
  header,
  resizing,
  setResizing,
  dragging,
  setDragging,
}: LogHeaderCellProps) => {
  const resizeRef = useRef<HTMLDivElement>(null)

  const handleDragStart = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (clickedResizeRef(e)) return
      setDragging(e)
    },
    [setDragging],
  )

  const handleResizeMousedown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setResizing(e)
    },
    [setResizing],
  )

  return (
    <div
      ref={headerRef}
      className={cn(
        'relative flex-none px-2 py-1 h-6 flex items-center justify-between gap-1 border-r border-main-border bg-main-bg',
        dragging || resizing ? '' : 'cursor-grab',
      )}
      style={{ width }}
      onMouseDown={handleDragStart}
    >
      <p className="text-small-code text-text-1 overflow-hidden whitespace-pre text-ellipsis">
        {propertyToDisplay(header)}
      </p>
      <div
        ref={resizeRef}
        className={cn(
          'resize-ref absolute top-0 right-[-4px] h-full w-2 z-[1]',
          dragging || resizing ? '' : 'cursor-ew-resize',
        )}
        onMouseDown={handleResizeMousedown}
      />
    </div>
  )
}

const clickedResizeRef = (e: React.MouseEvent<HTMLDivElement>) => {
  const target = e.target as HTMLElement
  const resizeRef = target.closest('.resize-ref')
  return resizeRef !== null
}

export default LogHeaderCell
