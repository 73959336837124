import { useState, useEffect } from 'react'

const LoadingDots = () => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => (prev >= 3 ? 0 : prev + 1))
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <span key={index}>.</span>
      ))}
    </>
  )
}

export default LoadingDots
