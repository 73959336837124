interface FullscreenProps {
  children: React.ReactNode
}

const Fullscreen: React.FC<FullscreenProps> = ({ children }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-popup-tint backdrop-blur-[4px] z-50">
      {children}
    </div>
  )
}

export default Fullscreen
