import { instanceIdAtom } from '../../state/state'
import { useAtomValue } from 'jotai'
import useLoadProjects from '../data/load/useLoadProjects'
import useBackend from '../services/useBackend'

const useUpdateProjectName = () => {
  const backend = useBackend()

  const { projectId } = useAtomValue(instanceIdAtom)
  const loadProjects = useLoadProjects()

  const updateProjectName = async (name: string) => {
    if (!projectId) return
    try {
      await backend.updateProject(projectId, name)
      await loadProjects()
    } catch (error) {}
  }

  return updateProjectName
}

export default useUpdateProjectName
