import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, integrationDetailsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadIntegrationDetails = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setIntegrationDetails = useSetAtom(integrationDetailsAtom)

  const loadIntegrationDetails = useCallback(
    async (integrationId: string) => {
      if (!projectId) return
      try {
        const { details } =
          await backend.getIntegrationExternalDetails(integrationId)
        setIntegrationDetails(details)
      } catch (e) {}
    },
    [backend, setIntegrationDetails, projectId],
  )

  return loadIntegrationDetails
}

export default useLoadIntegrationDetails
