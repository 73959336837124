import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, alertsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadAlerts = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setAlerts = useSetAtom(alertsAtom)

  const loadAlerts = useCallback(async () => {
    if (!projectId) return
    try {
      const { alerts } = await backend.getAlerts()
      setAlerts(alerts)
    } catch (e) {}
  }, [backend, setAlerts, projectId])

  return loadAlerts
}

export default useLoadAlerts
