interface CodeCTAButtonProps {
  children: React.ReactNode
  disabled?: boolean
  mode?: 'info' | 'warning' | 'error' | 'success' | 'base'
  type?: 'submit' | 'reset' | 'button'
  widthClass?: 'w-full' | 'w-fit'
  onClick?: () => void
}

const CodeCTAButton = ({
  children,
  disabled = false,
  mode = 'base',
  type = 'button',
  widthClass = 'w-full',
  onClick,
}: CodeCTAButtonProps) => {
  const bgColor = getBackgroundColor(mode)
  const borderColor = getBorderColor(mode)
  const borderHoverColor = getBorderHoverColor(mode, disabled)
  const textColor = getTextColor(mode)
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'

  return (
    <button
      className={`${widthClass} flex items-center justify-center py-1 px-3 rounded-[4px] text-small-code border ${bgColor} ${borderColor} ${borderHoverColor} ${textColor} ${disabledClass}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function getBackgroundColor(mode: CodeCTAButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'bg-brand-infoTint'
    case 'warning':
      return 'bg-brand-warningTint'
    case 'error':
      return 'bg-brand-errorTint'
    case 'success':
      return 'bg-brand-successTint'
    case 'base':
    default:
      return 'bg-input-bgTint'
  }
}

function getBorderColor(mode: CodeCTAButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'border-brand-infoTint'
    case 'warning':
      return 'border-brand-warningTint'
    case 'error':
      return 'border-brand-errorTint'
    case 'success':
      return 'border-brand-successTint'
    case 'base':
    default:
      return 'border-panel-border'
  }
}

function getBorderHoverColor(
  mode: CodeCTAButtonProps['mode'],
  disabled: boolean,
) {
  switch (mode) {
    case 'info':
      return disabled ? '' : 'hover:border-brand-info'
    case 'warning':
      return disabled ? '' : 'hover:border-brand-warning'
    case 'error':
      return disabled ? '' : 'hover:border-brand-error'
    case 'success':
      return disabled ? '' : 'hover:border-brand-success'
    case 'base':
    default:
      return disabled ? '' : 'hover:border-input-outline'
  }
}

function getTextColor(mode: CodeCTAButtonProps['mode']) {
  switch (mode) {
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    case 'base':
    default:
      return 'text-text-1'
  }
}

export default CodeCTAButton
