import { ChevronDown } from 'lucide-react'
import { ChevronRight } from 'lucide-react'
import { useState } from 'react'

interface RowButtonDropdownProps {
  title: string
  open: boolean
  setOpen: (open: boolean) => void
}

const RowButtonDropdown = ({
  title,
  open,
  setOpen,
}: RowButtonDropdownProps) => {
  const [hover, setHover] = useState(false)
  const textColor = open || hover ? 'text-text-1' : 'text-text-2'

  return (
    <button
      className="px-2 w-full h-6 flex items-center gap-1 bg-transparent hover:bg-input-tint rounded-[4px] outline-none"
      onClick={() => setOpen(!open)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p className={`text-small-header text-[12px] ${textColor}`}>{title}</p>
      {!open && <ChevronRight size={16} className={textColor} />}
      {open && <ChevronDown size={16} className={textColor} />}
    </button>
  )
}

export default RowButtonDropdown
