import { useAtomValue } from 'jotai'
import useCreateToken from '../../../../../hooks/actions/useCreateToken'
import useDeleteProject from '../../../../../hooks/actions/useDeleteProject'
import { instanceIdAtom } from '../../../../../state/state'
import { tokensAtom } from '../../../../../state/state'
import { projectsAtom } from '../../../../../state/state'
import useUpdateProjectName from '../../../../../hooks/actions/useUpdateProjectName'
import useInitOrganizationMembers from '../../../../../hooks/data/effects/useInitOrganizationMemebers'
import useInitTokens from '../../../../../hooks/data/effects/useInitTokens'
import SettingsSection from '../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../library/SettingsTitle/SettingsTitle'
import TextInput from '../../../../library/TextInput/TextInput'
import { useMemo, useState } from 'react'
import useDeleteToken from '../../../../../hooks/actions/useDeleteToken'
import CTAButton from '../../../../library/CTAButton/CTAButton'
import SettingsTable from '../../../../library/SettingsTable/SettingsTable'
import SettingsTableColumn from '../../../../library/SettingsTable/SettingsTableColumn'
import SettingsTableHeader from '../../../../library/SettingsTable/SettingsTableHeader'
import SettingsTableCell from '../../../../library/SettingsTable/SettingsTableCell'
import SettingsTableIcon from '../../../../library/SettingsTable/SettingsTableIcon'
import { TrashIcon } from 'lucide-react'
import NewTokenPopup from './NewTokenPopup/NewTokenPopup'
import DeleteTokenPopup from './DeleteTokenPopup/DeleteTokenPopup'
import CopyTokenPopup from './CopyTokenPopup/CopyTokenPopup'
import DeleteProjectPopup from './DeleteProjectPopup/DeleteProjectPopup'
import useNav, { LinkKey } from '../../../../../hooks/utils/useNav'
import { formatTimestamp } from '../../../../../utils/time'

const ProjectSettings = () => {
  const navigate = useNav()

  useInitOrganizationMembers()
  useInitTokens()

  const deleteProject = useDeleteProject()
  const updateProjectName = useUpdateProjectName()
  const createToken = useCreateToken()
  const deleteToken = useDeleteToken()

  const instanceId = useAtomValue(instanceIdAtom)
  const projects = useAtomValue(projectsAtom)
  const project = useMemo(
    () => projects?.find((p) => p.id === instanceId.projectId),
    [projects, instanceId.projectId],
  )
  const tokens = useAtomValue(tokensAtom)

  const [projectName, setProjectName] = useState(project?.name ?? '')

  const [isDeletingProject, setIsDeletingProject] = useState(false)

  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const [isDisplayingToken, setIsDisplayingToken] = useState(false)
  const [isDeletingToken, setIsDeletingToken] = useState(false)
  const [privateToken, setPrivateToken] = useState('')
  const [tokenToDelete, setTokenToDelete] = useState('')

  const handleSaveProjectName = async () => {
    const name = projectName.trim()
    if (!project?.id || !name) return
    await updateProjectName(projectName)
  }

  const handleCreateToken = async (name: string, description: string) => {
    const response = await createToken(name, description)
    if (!response) return
    setPrivateToken(response.token)
    setIsCreatingToken(false)
    setIsDisplayingToken(true)
  }

  const handleDeleteToken = async () => {
    await deleteToken(tokenToDelete)
    setIsDeletingToken(false)
    setTokenToDelete('')
  }

  const handleDeleteProject = async () => {
    if (!instanceId.projectId) return
    await deleteProject(instanceId.projectId)
    setIsDeletingProject(false)
    navigate(LinkKey.Logs)
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Project Name"
          description="We recommend having one project per environment. (ex. local, dev, prod)"
        />
        <div className="py-4 flex flex-col items-start justify-center gap-1">
          <div className="flex gap-4">
            <TextInput value={projectName} onChange={setProjectName} />
            <div className="flex items-center justify-center">
              <CTAButton
                disabled={!isProjectNameValid(project?.name ?? '', projectName)}
                onClick={handleSaveProjectName}
              >
                Save
              </CTAButton>
            </div>
          </div>
          <p className="text-label text-text-2">Max 32 characters</p>
        </div>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="API Tokens"
          description="These are used to identify your servers when sending data to Vigilant. Use them when creating an instance of the Vigilant logger or error handler in the your application."
          button="Create Token"
          onClick={() => setIsCreatingToken(true)}
        />
        <SettingsTable>
          <SettingsTableColumn>
            <SettingsTableHeader content="Name" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.label} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Token" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.display_token} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Description" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.description} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Created Date" />
            {tokens?.map((token) => (
              <SettingsTableCell
                key={token.id}
                content={formatTimestamp(token.created_at)}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {tokens?.map((token) => (
              <SettingsTableIcon
                key={token.id}
                icon={<TrashIcon size={16} />}
                onClick={() => {
                  setTokenToDelete(token.id)
                  setIsDeletingToken(true)
                }}
              />
            ))}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Delete Project"
          description="This will delete your project and all data associated with it."
        />
        <div className="py-4 w-full flex flex-col items-start justify-center gap-1">
          <div className="w-full flex items-center justify-between">
            <p className="text-body text-brand-error">
              Warning: This cannot be undone.
            </p>
            <div className="flex items-center justify-center">
              <CTAButton
                mode="error"
                onClick={() => setIsDeletingProject(true)}
              >
                Delete Project
              </CTAButton>
            </div>
          </div>
        </div>
      </SettingsSection>

      {isCreatingToken && (
        <NewTokenPopup
          close={() => setIsCreatingToken(false)}
          onSubmit={handleCreateToken}
        />
      )}

      {isDisplayingToken && (
        <CopyTokenPopup
          token={privateToken}
          close={() => {
            setPrivateToken('')
            setIsDisplayingToken(false)
          }}
        />
      )}

      {isDeletingToken && (
        <DeleteTokenPopup
          name={tokens?.find((t) => t.id === tokenToDelete)?.label ?? ''}
          close={() => {
            setTokenToDelete('')
            setIsDeletingToken(false)
          }}
          onSubmit={handleDeleteToken}
        />
      )}

      {isDeletingProject && (
        <DeleteProjectPopup
          name={project?.name ?? ''}
          close={() => setIsDeletingProject(false)}
          onSubmit={handleDeleteProject}
        />
      )}
    </div>
  )
}

function isProjectNameValid(currentProjectName: string, projectName: string) {
  return (
    projectName.trim().length > 0 &&
    projectName.trim().length < 32 &&
    projectName.trim() !== currentProjectName
  )
}

export default ProjectSettings
