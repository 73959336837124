import { useState } from 'react'

interface CodeButtonProps {
  text: string
  subtext?: string
  icon?: React.ReactNode
  color?: 'info' | 'warning' | 'error' | 'success'

  active?: boolean
  selected?: boolean

  onClick?: (active: boolean) => void
}

const CodeButton = ({
  text,
  subtext,
  icon,
  color,
  active = false,
  selected = false,
  onClick,
}: CodeButtonProps) => {
  const [hover, setHover] = useState(false)
  const textClass = active || hover || selected ? 'text-text-1' : 'text-text-2'
  const selectedClass = selected ? 'bg-input-tint' : 'bg-transparent'
  const hoverClass = onClick ? 'hover:bg-input-tint' : 'hover:bg-transparent'
  const cursorClass = onClick ? 'cursor-pointer' : 'cursor-default'

  return (
    <button
      className={`w-full h-6 px-2 flex items-center justify-between gap-2 rounded-[4px] outline-none overflow-hidden ${selectedClass} ${hoverClass} ${cursorClass}`}
      onClick={() => onClick && onClick(!active)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex items-center gap-2 overflow-hidden">
        {icon && <div className={textClass}>{icon}</div>}
        {color && <ColorBar color={color} />}
        <p
          className={`truncate text-small-code ${textClass} hover:text-text-1`}
        >
          {text}
        </p>
      </div>
      <p className={`text-small-code text-text-2`}>{subtext}</p>
    </button>
  )
}

const ColorBar = ({
  color,
}: {
  color: 'info' | 'warning' | 'error' | 'success'
}) => {
  switch (color) {
    case 'info':
      return <div className={`w-0.5 h-4 rounded-[4px] bg-brand-info`} />
    case 'warning':
      return <div className={`w-0.5 h-4 rounded-[4px] bg-brand-warning`} />
    case 'error':
      return <div className={`w-0.5 h-4 rounded-[4px] bg-brand-error`} />
    case 'success':
      return <div className={`w-0.5 h-4 rounded-[4px] bg-brand-success`} />
  }
}

export default CodeButton
