import { OptionsQueryParameters, OptionsQueryState } from './types'

export const createOptionsQueryState = (
  index: number,
  params: OptionsQueryParameters,
): OptionsQueryState => {
  return {
    index: index,
    field: params.field,
    value: params.value,
    mode: 'loading',
    request: params,
    controller: new AbortController(),
    result: null,
  }
}
