import { useMemo } from 'react'
import { QueryParameterManager } from '../../library/query/queryParameterManager'

const useQueryParameterManager = () => {
  const queryParameterManager = useMemo(() => {
    return new QueryParameterManager()
  }, [])

  return queryParameterManager
}

export default useQueryParameterManager
