import { atom, useAtom, useAtomValue } from 'jotai'
import { LogEntry } from '../../../state/types'
import PanelRow from '../PanelRow/PanelRow'
import PanelSection from '../PanelSection/PanelSection'
import { useRef } from 'react'
import useClickOutside from '../../../hooks/utils/useClickOutside'
import IconButton from '../IconButton/IconButton'
import { X } from 'lucide-react'
import { formatTimestamp } from '../../../utils/time'
import CodeDisplay from '../CodeDisplay/CodeDisplay'
import { headersAtom } from '../../pages/Dashboard/Logs/Logs'
import { propertyToDisplay } from '../../../utils/properties'
import { getFormattedExtractor } from '../../../utils/logs'

export type LogDetailsData = {
  log: LogEntry | null
  type: 'log' | 'alert' | null
}

export const logDetailsAtom = atom<LogDetailsData>({ log: null, type: null })

const LogDetails = () => {
  const [logDetails, setLogDetails] = useAtom(logDetailsAtom)
  const headers = useAtomValue(headersAtom)

  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(
    ref,
    () => setLogDetails({ log: null, type: null }),
    undefined,
    ['event-row'],
  )

  if (!logDetails.log) return null

  return (
    <div
      ref={ref}
      className="absolute top-12 right-0 bottom-0 w-[600px] flex flex-col bg-panel-bg border border-y-0 border-main-border overflow-y-auto"
    >
      <PanelSection>
        <PanelRow>
          <p className="text-small-header text-text-1">Log Details</p>
          <IconButton
            icon={<X className="w-4 h-4" />}
            onClick={() => setLogDetails({ log: null, type: null })}
          />
        </PanelRow>
        <div className="px-2 py-1 flex flex-row items-start gap-2">
          <p className="w-[136px] flex-none text-small-code text-text-2">
            time
          </p>
          <p className="text-small-code text-text-1">
            {formatTimestamp(logDetails.log.timestamp)}
          </p>
        </div>
        <div className="px-2 py-1 flex flex-row items-start gap-2">
          <p className="w-[136px] flex-none text-small-code text-text-2">
            body
          </p>
          <p className="text-small-code text-text-1 whitespace-pre-wrap break-all overflow-x-scroll">
            {logDetails.log.body}
          </p>
        </div>
      </PanelSection>
      {logDetails.type === 'log' && (
        <PanelSection>
          <PanelRow>
            <p className="text-small-header text-text-1">Visible</p>
          </PanelRow>
          {headers.map((header) => {
            if (!logDetails.log) return null
            return (
              <div className="px-2 py-1 flex flex-row items-start gap-2">
                <p className="w-[136px] flex-none text-small-code text-text-2">
                  {propertyToDisplay(header.value)}
                </p>
                <p className="text-small-code text-text-1">
                  {getFormattedExtractor(header.value)(logDetails.log)}
                </p>
              </div>
            )
          })}
        </PanelSection>
      )}
      <PanelSection bottom={true}>
        <PanelRow>
          <p className="text-small-header text-text-1">Raw</p>
        </PanelRow>
        <div className="px-2 py-1">
          <CodeDisplay
            language="json"
            content={getFormattedLog(logDetails.log)}
          />
        </div>
      </PanelSection>
    </div>
  )
}

const getFormattedLog = (log: LogEntry): string => {
  return JSON.stringify(
    {
      project_id: log.project_id,
      log_id: log.log_id,
      timestamp: log.timestamp,
      body: log.body,
      severity_name: log.severity_name,
      attributes: log.attributes,
    },
    null,
    2,
  )
}

export default LogDetails
