import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useCreateProject from '../../../hooks/actions/useCreateProject'
import LabeledInput from '../../library/LabeledInput/LabeledInput'
import CTAButton from '../../library/CTAButton/CTAButton'

const NewProject = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createProject = useCreateProject()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createProject(name)
    navigate(LinkKey.Onboarding)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-main-bg">
      <form
        className="p-6 w-[360px] flex flex-col items-start justify-start gap-6 rounded-[4px] border border-panel-border"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h1 className="text-h1 text-text-1">Create a Project</h1>
          <p className="text-body text-text-1">
            A project is an isolated environment. You can send data to a project
            from multiple sources.
          </p>
          <p className="text-body text-text-1">
            We recommend using one project per environment (ex: prod, dev,
            local).
          </p>
        </div>
        <LabeledInput
          label="Project Name"
          value={name}
          onChange={(e) => setName(e)}
          placeholder="prod"
        />
        <CTAButton type="submit" disabled={!name}>
          Create Project
        </CTAButton>
      </form>
    </div>
  )
}

export default NewProject
