import { Building2, Grid2X2, ShieldAlert, User } from 'lucide-react'
import PanelRow from '../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../library/PanelSection/PanelSection'
import RowButton from '../../../../library/RowButton/RowButton'
import { useLocation } from 'react-router-dom'
import useNav, { LinkKey, linkMap } from '../../../../../hooks/utils/useNav'

const SettingsSidebar = () => {
  const navigate = useNav()
  const location = useLocation()

  const isActive = (link: LinkKey) => {
    return location.pathname.includes(linkMap[link])
  }

  return (
    <div className="flex-none w-[264px] h-full flex flex-col border-r border-panel-border">
      <PanelSection className="gap-2">
        <PanelRow>
          <h3 className="text-small-header text-text-1">Settings</h3>
        </PanelRow>
        <RowButton
          text="Project"
          icon={<Grid2X2 size={16} />}
          onClick={() => navigate(LinkKey.ProjectSettings)}
          active={isActive(LinkKey.ProjectSettings)}
        />
        <RowButton
          text="Alerts"
          icon={<ShieldAlert size={16} />}
          onClick={() => navigate(LinkKey.AlertSettings)}
          active={isActive(LinkKey.AlertSettings)}
        />
        <RowButton
          text="Organization"
          icon={<Building2 size={16} />}
          onClick={() => navigate(LinkKey.OrganizationSettings)}
          active={isActive(LinkKey.OrganizationSettings)}
        />
        <RowButton
          text="Account"
          icon={<User size={16} />}
          onClick={() => navigate(LinkKey.AccountSettings)}
          active={isActive(LinkKey.AccountSettings)}
        />
      </PanelSection>
    </div>
  )
}

export default SettingsSidebar
