import MainTabs, { TabDetails } from '../MainTabs/MainTabs'
import OrganizationButton from './OrganizationButton'
import ProjectButton from './ProjectButton'
import { CircleUser, Slash } from 'lucide-react'
import useNav, { LinkKey } from '../../../hooks/utils/useNav'
import HelpButton from './HelpButton'
import Button from '../Button/Button'
import IconButton from '../IconButton/IconButton'

export type NavbarTab = 'logs' | 'alerts' | 'settings'

interface NavbarProps {
  activeTab: NavbarTab | null
  setActiveTab: (tab: NavbarTab | null) => void
}

const Navbar = ({ activeTab, setActiveTab }: NavbarProps) => {
  const navigate = useNav()

  return (
    <div className="fixed top-0 px-4 w-full h-12 flex items-center justify-between bg-main-bg border-b border-main-border z-[1]">
      <div className="flex-1 basis-0 flex items-center gap-2">
        <OrganizationButton />
        <Slash size={16} className="text-text-2" />
        <ProjectButton />
      </div>
      <MainTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="flex-1 basis-0 flex items-center justify-end gap-2">
        <Button href="https://docs.vigilant.run/introduction">Docs</Button>
        <Button onClick={() => navigate(LinkKey.Onboarding)}>Setup</Button>
        <HelpButton />
        <IconButton
          icon={<CircleUser size={16} />}
          size="32"
          onClick={() => navigate(LinkKey.AccountSettings)}
        />
      </div>
    </div>
  )
}

const tabs: TabDetails<NavbarTab>[] = [
  { title: 'Logs', value: 'logs', mode: 'info' },
  { title: 'Alerts', value: 'alerts', mode: 'warning' },
  { title: 'Settings', value: 'settings', mode: 'success' },
]

export default Navbar
