import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadProjects from '../data/load/useLoadProjects'
import useSetInstance from './useSetInstance'

const useCreateProject = () => {
  const backend = useBackend()

  const { organizationId } = useAtomValue(instanceIdAtom)
  const loadProjects = useLoadProjects()
  const switchInstance = useSetInstance()

  const createProject = async (name: string) => {
    if (!organizationId) return
    try {
      const response = await backend.createProject(organizationId, name)
      await loadProjects()
      await switchInstance(organizationId, response.id)
    } catch (e) {}
  }

  return createProject
}

export default useCreateProject
