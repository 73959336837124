import useLoadAlerts from '../data/load/useLoadAlerts'
import useBackend from '../services/useBackend'

const useDeleteAlert = () => {
  const backend = useBackend()

  const loadAlerts = useLoadAlerts()

  const deleteAlert = async (id: string) => {
    try {
      await backend.deleteAlert(id)
      await loadAlerts()
    } catch (error) {}
  }

  return deleteAlert
}

export default useDeleteAlert
