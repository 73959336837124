import { cn } from '../../../utils/cn'

interface DividerProps {
  mode: 'main' | 'panel' | 'popup'
}

const Divider = ({ mode }: DividerProps) => {
  const borderColor = getBorderColor(mode)
  return (
    <div className="w-full py-2">
      <div className={cn('h-[1px] border-b', borderColor)} />
    </div>
  )
}

function getBorderColor(mode: 'main' | 'panel' | 'popup') {
  if (mode === 'main') return 'border-main-border'
  if (mode === 'panel') return 'border-panel-border'
  if (mode === 'popup') return 'border-popup-border'
}

export default Divider
