import { useAtomValue, useSetAtom } from 'jotai'
import { instanceIdAtom, organizationMembersAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadOrganizationMembers = () => {
  const backend = useBackend()
  const instanceId = useAtomValue(instanceIdAtom)
  const setOrganizationMembers = useSetAtom(organizationMembersAtom)

  const loadOrganizationMembers = useCallback(async () => {
    if (!instanceId.organizationId) return
    try {
      const members = await backend.getOrganizationMembers(
        instanceId.organizationId,
      )
      setOrganizationMembers(members.members)
    } catch (e) {}
  }, [backend, setOrganizationMembers, instanceId])

  return loadOrganizationMembers
}

export default useLoadOrganizationMembers
