import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { instanceIdAtom } from '../../state/state'
import useLoadOrganizationInfo from '../data/load/useLoadOrganizationInfo'

export const useUpdateOrganizationName = () => {
  const backend = useBackend()

  const { organizationId } = useAtomValue(instanceIdAtom)
  const loadOrganizationInfo = useLoadOrganizationInfo()

  return async (name: string) => {
    if (!organizationId) return
    try {
      await backend.updateOrganization(organizationId, name)
      await loadOrganizationInfo()
    } catch (error) {}
  }
}
