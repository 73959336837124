import { useSetAtom } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import useLoadProjects from '../data/load/useLoadProjects'

const useCreateOrganization = () => {
  const backend = useBackend()

  const setInstanceId = useSetAtom(instanceIdAtom)
  const loadOrganizations = useLoadOrganizations()
  const loadProjects = useLoadProjects()

  const createOrganization = async (name: string) => {
    const response = await backend.createOrganization(name)
    await loadOrganizations()
    await loadProjects()
    await backend.authProject(response.id, response.project_id)
    setInstanceId({
      organizationId: response.id,
      projectId: response.project_id,
    })
  }

  return createOrganization
}

export default useCreateOrganization
