interface LabeledAreaProps {
  label: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
  id?: string
}

const LabeledArea = ({
  label,
  value,
  onChange,
  placeholder,
  id,
}: LabeledAreaProps) => {
  return (
    <div className="py-2 w-full flex flex-col gap-2 items-start justify-start">
      <label
        className="text-label text-text-2"
        htmlFor={id ? `${id}-area` : undefined}
      >
        {label}
      </label>
      <textarea
        id={id ? `${id}-area` : undefined}
        className="w-full h-[160px] px-3 py-2 text-label text-text-1 bg-input-bgTint border border-input-border rounded-[4px] placeholder:text-text-2 hover:border-input-border focus:border-input-border outline-none resize-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default LabeledArea
