import { Check } from 'lucide-react'
import { Copy } from 'lucide-react'
import { useRef, useState } from 'react'

interface CopyInputProps {
  value: string
}

const CopyInput = ({ value }: CopyInputProps) => {
  const [copied, setCopied] = useState(false)
  const copyTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value)
    setCopied(true)
    if (copyTimeoutRef.current) {
      clearTimeout(copyTimeoutRef.current)
    }
    copyTimeoutRef.current = setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div
      onClick={handleCopy}
      className="px-3 py-2 flex items-center justify-between bg-input-bgTint border border-input-border rounded-[4px] hover:bg-input-tint cursor-pointer"
    >
      <p className="text-input text-text-1">{value}</p>
      {copied ? (
        <Check className="w-4 h-4 text-brand-success" />
      ) : (
        <Copy className="w-4 h-4 text-text-2" />
      )}
    </div>
  )
}

export default CopyInput
