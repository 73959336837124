import { Menu } from 'lucide-react'
import { LogEntry } from '../../../../../../../state/types'
import { Header } from '../../../Logs'
import LogCell from './LogCell/LogCell'
import { useState } from 'react'
import { useAtom } from 'jotai'
import { logDetailsAtom } from '../../../../../../library/LogDetails/LogDetails'
import { cn } from '../../../../../../../utils/cn'

interface LogRowProps {
  log: LogEntry
  term: string
  columns: Header[]
}

const LogRow = ({ log, term, columns }: LogRowProps) => {
  const [logDetails, setLogDetails] = useAtom(logDetailsAtom)

  const [hover, setHover] = useState(false)
  const isSelected = logDetails.log?.log_id === log.log_id

  return (
    <div
      className={cn(
        'relative flex border-b border-main-border z-[0]',
        isSelected
          ? 'bg-input-tint hover:bg-input-tint'
          : 'bg-transparent hover:bg-input-bgTint',
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {columns.map((column, index) => (
        <LogCell
          key={`${column.value}-${index}`}
          term={term}
          width={column.width}
          column={column.value}
          log={log}
        />
      ))}
      <div className="w-[256px]"></div>
      {hover && (
        <div
          className="sticky top-0 right-0 w-6 h-6 flex items-center justify-center border-l border-main-border cursor-pointer text-text-2 hover:text-text-1"
          onClick={() => setLogDetails({ log, type: 'log' })}
        >
          <Menu className="w-4 h-4" />
        </div>
      )}
    </div>
  )
}

export default LogRow
