import { useRef } from 'react'

interface CodeInputProps {
  value: string
  type?: 'text' | 'password' | 'email' | 'number'
  onChange: (value: string) => void
  error?: boolean
  placeholder?: string
}

const CodeInput = ({
  value,
  type = 'text',
  onChange,
  error = false,
  placeholder = '',
}: CodeInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const borderColor = error ? 'border-brand-error' : 'border-transparent'

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  return (
    <input
      ref={inputRef}
      className={`w-full px-3 py-1 text-small-code text-text-1 bg-input-bgTint border ${borderColor} rounded-[4px] placeholder:text-text-2 hover:bg-input-tint focus:bg-input-tint outline-none`}
      type={type}
      value={value}
      onFocus={handleFocus}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  )
}

export default CodeInput
