import { useSetAtom, useAtomValue } from 'jotai'
import { projectsAtom, instanceIdAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'

const useLoadProjects = () => {
  const backend = useBackend()
  const { organizationId } = useAtomValue(instanceIdAtom)
  const setProjects = useSetAtom(projectsAtom)

  const loadProjects = useCallback(async () => {
    if (!organizationId) return
    try {
      const projects = await backend.getProjects(organizationId)
      setProjects(projects.projects)
    } catch (e) {}
  }, [backend, setProjects, organizationId])

  return loadProjects
}

export default useLoadProjects
