import axios from 'axios'
import { QueryService } from '../../backend/query'
import {
  LogAttributeCountResponse,
  LogColumnCountResponse,
  LogQueryRequest,
} from '../../backend/types'
import {
  SERVICE_FIELD,
  LEVEL_FIELD,
  QueryResponse,
  QueryRequestState,
  LoadMoreResponse,
} from './types'
import { getTimestamps } from '../../utils/time'
import { formatFilters, ATTRIBUTE_PREFIX } from '../../utils/properties'

export class QueryRunner {
  private projectId: string
  private queryService: QueryService

  constructor(projectId: string, queryService: QueryService) {
    this.projectId = projectId
    this.queryService = queryService
  }

  query = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<QueryResponse> => {
    return this.runQuery(query, signal)
  }

  loadMore = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    return this.runLoadMore(query, signal)
  }

  private runQuery = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<QueryResponse> => {
    try {
      const [start, end] = getTimestamps(request.timerange)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        properties: [],
        limit: request.limit,
        cursor_start: request.cursor,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'query' }
      }

      const serviceQuery = { ...query, field: SERVICE_FIELD }
      const levelQuery = { ...query, field: LEVEL_FIELD }
      const [
        countResult,
        queryResult,
        columnsResult,
        attributesResult,
        serviceOptionsResult,
        levelOptionsResult,
      ] = await Promise.all([
        this.queryService.logsCount(query, signal),
        this.queryService.logsQuery(query, signal),
        this.queryService.logsColumnCount(query, signal),
        this.queryService.logsAttributeCount(query, signal),
        this.queryService.logsAttributeOptions(serviceQuery, signal),
        this.queryService.logsColumnOptions(levelQuery, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'query' }
      }

      return {
        result: 'success',
        type: 'query',
        count: countResult.count ?? 0,
        logs: queryResult.logs ?? [],
        properties: [
          ...getColumns(columnsResult),
          ...getAttributes(attributesResult),
        ],
        serviceOptions: serviceOptionsResult.options ?? [],
        levelOptions: levelOptionsResult.options ?? [],
        done: (queryResult.logs?.length ?? 0) < 100,
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e)
      }
      return { result: 'canceled', type: 'query' }
    }
  }

  private runLoadMore = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    try {
      const [start, end] = getTimestamps(request.timerange)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        properties: [],
        limit: request.limit,
        cursor_start: request.cursor,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      const [queryResult] = await Promise.all([
        this.queryService.logsQuery(query, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      return {
        result: 'success',
        type: 'loadMore',
        logs: queryResult.logs ?? [],
        done: (queryResult.logs?.length ?? 0) < 100,
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e)
      }
      return { result: 'canceled', type: 'loadMore' }
    }
  }
}

function getColumns(columnsResult: LogColumnCountResponse) {
  return Object.entries(columnsResult.columns ?? {}).map(([key, value]) => ({
    value: `column.${key}`,
    count: value,
  }))
}

function getAttributes(attributesResult: LogAttributeCountResponse) {
  return Object.entries(attributesResult.attributes ?? {}).map(
    ([key, value]) => ({
      value: `${ATTRIBUTE_PREFIX}${key}`,
      count: value,
    }),
  )
}
