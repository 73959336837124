import { useRef } from 'react'
import { LogEntry } from '../../../../../../state/types'
import { Header } from '../../Logs'
import LoadingDots from '../../../../../library/LoadingDots/LoadingDots'
import LogHeaderRow from './LogHeaderRow/LogHeaderRow'
import LogRow from './LogRow/LogRow'
import { QueryMode } from '../../../../../../library/query/types'
import { hasLogsAtom } from '../../../../../../state/state'
import { useAtomValue } from 'jotai'
import useNav, { LinkKey } from '../../../../../../hooks/utils/useNav'

interface LogContainerProps {
  logs: LogEntry[]

  term: string
  queryMode: QueryMode
  done: boolean

  headers: Header[]
  setHeaders: (headers: Header[]) => void
}

const LogContainer = ({
  logs,
  term,
  queryMode,
  done,
  headers,
  setHeaders,
}: LogContainerProps) => {
  const navigate = useNav()

  const containerRef = useRef<HTMLDivElement>(null)
  const hasLogs = useAtomValue(hasLogsAtom)

  return (
    <div
      ref={containerRef}
      className="relative w-fit flex flex-col bg-main-bg z-[0]"
    >
      <div className="relative flex flex-col">
        {logs.length > 0 && (
          <LogHeaderRow
            containerRef={containerRef}
            headers={headers}
            setHeaders={setHeaders}
          />
        )}
        {logs.map((log, index) => (
          <LogRow
            key={`${log.log_id}-${index}`}
            log={log}
            term={term}
            columns={headers}
          />
        ))}
      </div>
      <div className="py-8 w-[calc(100vw-280px)] flex justify-center items-center text-small-code text-text-1">
        {getMessage(hasLogs, logs, queryMode, done, navigate)}
      </div>
      <div className="h-64"></div>
    </div>
  )
}
function getMessage(
  hasLogs: boolean,
  logs: LogEntry[],
  queryMode: QueryMode,
  done: boolean,
  navigate: (key: LinkKey) => void,
): React.ReactNode {
  if (queryMode === 'initial_load') {
    return (
      <div className="w-[120px] flex items-center">
        Fetching logs
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'user_query') {
    return (
      <div className="w-[156px] flex items-center">
        Running query
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'loading_more') {
    return (
      <div className="w-[156px] flex items-center">
        Fetching more logs
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'none' || queryMode === 'live_tick') {
    if (!hasLogs) {
      return (
        <p>
          You don't have any logs yet. To start sending logs, visit the{' '}
          <span
            onClick={() => navigate(LinkKey.Onboarding)}
            className="underline cursor-pointer"
          >
            setup
          </span>{' '}
          page.
        </p>
      )
    }
    if (done) {
      if (logs.length === 0) {
        return 'No logs found matching this query.'
      } else if (logs.length > 0) {
        return "That's all the logs for this query."
      }
    } else {
      return (
        <div className="w-[156px] flex items-center">
          Fetching more logs
          <LoadingDots />
        </div>
      )
    }
  }
  return <></>
}

export default LogContainer
