import { cn } from '../../../utils/cn'

interface TerminalProps {
  content: string[]
  className?: string
}

const Terminal = ({ content, className }: TerminalProps) => {
  return (
    <div
      className={cn(
        'py-2 h-[220px] bg-code-bg border border-code-border rounded-[4px] overflow-y-auto',
        className,
      )}
    >
      {content.map((line, index) => (
        <TerminalLine key={index} content={line} />
      ))}
      {content.length === 0 && (
        <div className="px-4 py-1 flex items-center justify-start">
          <p className="text-code text-text-1">no logs received yet</p>
        </div>
      )}
    </div>
  )
}

interface TerminalLineProps {
  content: string
}

const TerminalLine = ({ content }: TerminalLineProps) => {
  return (
    <div className="px-4 py-1 flex items-center justify-start">
      <p className="text-code text-text-1">{content}</p>
    </div>
  )
}

export default Terminal
