import { useCallback } from 'react'
import useBackend from '../../services/useBackend'
import { userInfoAtom } from '../../../state/state'
import { useSetAtom } from 'jotai'

const useLoadUserInfo = () => {
  const backend = useBackend()
  const setUserInfo = useSetAtom(userInfoAtom)

  const loadUserInfo = useCallback(async () => {
    try {
      const userInfo = await backend.getUser()
      setUserInfo(userInfo)
    } catch (e) {}
  }, [backend, setUserInfo])

  return loadUserInfo
}

export default useLoadUserInfo
