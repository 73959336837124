export const colors = {
  info: {
    main: '#80EAFF',
    tint: '#80EAFF1A',
  },
  warning: {
    main: '#FFD980',
    tint: '#FFD9801A',
  },
  success: {
    main: '#81FF80',
    tint: '#81FF801A',
  },
  error: {
    main: '#FF8480',
    tint: '#FF84801A',
  },
}

export const setBrandCTA = (color: keyof typeof colors) => {
  document.documentElement.style.setProperty(
    '--brand-cta-color',
    colors[color].main,
  )
  document.documentElement.style.setProperty(
    '--brand-cta-tint',
    colors[color].tint,
  )
}
