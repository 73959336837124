import { LucideX } from 'lucide-react'
import { toastsAtom } from '../../../state/state'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'

export type ToastType = 'success' | 'info' | 'warning' | 'error'

export type ToastItem = {
  id: string
  message: string
  type: ToastType
}

const ToastContainer = () => {
  const toasts = useAtomValue(toastsAtom)
  const setToasts = useSetAtom(toastsAtom)

  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id))
  }

  return (
    <>
      {[...toasts].reverse().map((toast, index) => (
        <Toast
          key={toast.id}
          toast={toast}
          removeToast={() => removeToast(toast.id)}
          style={{ bottom: `${index * 48 + 8}px` }}
        />
      ))}
    </>
  )
}

const Toast = ({
  toast,
  removeToast,
  style,
}: {
  toast: ToastItem
  removeToast: () => void
  style: React.CSSProperties
}) => {
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsAnimating(true)
    })
  }, [])

  return (
    <div
      className={`z-50 fixed right-2 w-[300px] h-[40px] bg-panel-bg transition-all duration-300`}
      style={{ ...style, bottom: isAnimating ? style.bottom : '-40px' }}
    >
      <div className={`w-full h-full border ${colors[toast.type].tint}`}>
        <div
          className={`h-[1px] ${colors[toast.type].fuse}`}
          style={{
            width: isAnimating ? '0%' : '100%',
            transition: 'width 4s linear',
          }}
        />
        <div className="px-4 py-2 flex items-center justify-between">
          <p className={`text-body ${colors[toast.type].text}`}>
            {toast.message}
          </p>
          <LucideX
            className={`w-4 h-4 cursor-pointer ${colors[toast.type].text}`}
            onClick={removeToast}
          />
        </div>
      </div>
    </div>
  )
}

const colors = {
  success: {
    tint: 'bg-brand-successTint border-brand-successTint',
    text: 'text-brand-success',
    fuse: 'bg-brand-success',
  },
  info: {
    tint: 'bg-brand-infoTint border-brand-infoTint',
    text: 'text-brand-info',
    fuse: 'bg-brand-info',
  },
  warning: {
    tint: 'bg-brand-warningTint border-brand-warningTint',
    text: 'text-brand-warning',
    fuse: 'bg-brand-warning',
  },
  error: {
    tint: 'bg-brand-errorTint border-brand-errorTint',
    text: 'text-brand-error',
    fuse: 'bg-brand-error',
  },
}

export default ToastContainer
