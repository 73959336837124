import { useCallback, useEffect, useMemo, useState } from 'react'
import { QueryLiveTick } from '../../library/query/queryLiveTick'
import { QueryManager } from '../../library/query/queryManager'

const useQueryLiveTick = (
  queryManager: QueryManager | null,
): [boolean, (isLive: boolean) => void] => {
  const [isLive, setIsLive] = useState(true)
  const [isLiveBeforeExit, setIsLiveBeforeExit] = useState(true)

  const queryLiveTick = useMemo(() => {
    if (!queryManager) return null
    return new QueryLiveTick(queryManager)
  }, [queryManager])

  const handleSetIsLive = useCallback(
    (isLive: boolean) => {
      if (!queryManager || !queryLiveTick) return
      queryLiveTick.setLive(isLive)
      setIsLive(isLive)
    },
    [queryManager, queryLiveTick],
  )

  const handleTabVisibility = useCallback(() => {
    if (document.hidden) {
      setIsLiveBeforeExit(isLive)
      handleSetIsLive(false)
    } else {
      handleSetIsLive(isLiveBeforeExit)
    }
  }, [isLive, isLiveBeforeExit, handleSetIsLive])

  useEffect(() => {
    if (!queryLiveTick) return
    queryLiveTick.init()
    return () => {
      if (!queryLiveTick) return
      queryLiveTick.shutdown()
    }
  }, [queryLiveTick])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleTabVisibility)
    return () => {
      document.removeEventListener('visibilitychange', handleTabVisibility)
    }
  }, [handleTabVisibility])

  return [isLive, handleSetIsLive]
}

export default useQueryLiveTick
