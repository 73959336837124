import { PanelLeft } from 'lucide-react'
import { useAtom } from 'jotai'
import { cn } from '../../../../../../../utils/cn'
import persistAtom from '../../../../../../../state/persistAtom'

export const sidebarVisibleAtom = persistAtom<boolean>({
  key: 'logsSidebarVisible',
  defaultValue: window.innerWidth >= 1024,
  persistMode: 'local',
})

interface SidebarVisibilityButtonProps {
  className?: string
}

const SidebarVisibilityButton = ({
  className,
}: SidebarVisibilityButtonProps) => {
  const [sidebarVisible, setSidebarVisible] = useAtom(sidebarVisibleAtom)

  if (sidebarVisible) return null

  return (
    <button
      className={cn(
        'px-2 py-2 rounded bg-panel-bg border border-panel-border hover:bg-input-tint',
        className,
      )}
      onClick={() => setSidebarVisible(true)}
    >
      <PanelLeft size={16} className="text-text-2" />
    </button>
  )
}

export default SidebarVisibilityButton
