import { Eye, EyeClosed } from 'lucide-react'
import { cn } from '../../../utils/cn'

interface EyeButtonProps {
  hidden: boolean
  onClick: () => void
  disabled?: boolean
}

const EyeButton = ({ hidden, onClick, disabled }: EyeButtonProps) => {
  return (
    <button
      className={cn(
        'w-8 h-8 flex items-center justify-center text-text-2 outline-none',
        disabled ? 'hover:text-text-2' : 'hover:text-text-1',
      )}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {hidden ? <Eye size={16} /> : <EyeClosed size={16} />}
    </button>
  )
}

export default EyeButton
