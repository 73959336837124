import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export enum LinkKey {
  Signup = 'signup',
  Login = 'login',
  NewOrganization = 'organization',
  NewProject = 'project',
  Onboarding = 'onboarding',
  Logs = 'logs',
  Alerts = 'alerts',
  Alert = 'alert',
  AccountSettings = 'accountSettings',
  OrganizationSettings = 'organizationSettings',
  ProjectSettings = 'projectSettings',
  AlertSettings = 'alertSettings',
}

export const linkMap: Record<LinkKey, string> = {
  signup: '/signup',
  login: '/login',
  organization: '/organization',
  project: '/project',
  onboarding: '/onboarding',
  logs: '/logs',
  alerts: '/alerts',
  alert: '/alerts/:id',
  accountSettings: '/settings/account',
  organizationSettings: '/settings/organization',
  projectSettings: '/settings/project',
  alertSettings: '/settings/alerts',
}

const isInternalLink = (key: LinkKey) => linkMap[key].startsWith('/')

const useNav = () => {
  const navigate = useNavigate()

  return useCallback(
    (key: LinkKey, params?: Record<string, string>) => {
      let path = linkMap[key]
      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          path = path.replace(`:${key}`, value)
        })
      }

      if (isInternalLink(key)) {
        navigate(path)
      } else {
        window.open(path, '_blank')
      }
    },
    [navigate],
  )
}

export default useNav
