import { Plus, X } from 'lucide-react'
import PanelRow from '../../PanelRow/PanelRow'
import PanelSection from '../../PanelSection/PanelSection'
import { ContactChannelEmailSettings } from '../../../../backend/types'
import CodeInput from '../../CodeInput/CodeInput'
import { isValidEmail } from '../../../../utils/inputs'

interface EmailSettingsProps {
  settings: ContactChannelEmailSettings
  setSettings: (settings: ContactChannelEmailSettings) => void
}

const EmailSettings = ({ settings, setSettings }: EmailSettingsProps) => {
  const addEmail = () => {
    setSettings({ ...settings, emails: [...settings.emails, ''] })
  }

  const removeEmail = (index: number) => {
    setSettings({
      ...settings,
      emails: settings.emails.filter((_, i) => i !== index),
    })
  }

  const updateEmail = (index: number, value: string) => {
    setSettings({
      ...settings,
      emails: settings.emails.map((email, i) => (i === index ? value : email)),
    })
  }

  return (
    <PanelSection className="gap-2">
      <PanelRow>
        <p className="text-small-header text-text-1">Email List</p>
        <Plus
          className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
          onClick={addEmail}
        />
      </PanelRow>
      {settings.emails.map((email, index) => (
        <PanelRow key={index}>
          <CodeInput
            value={email}
            placeholder={'user@example.com'}
            onChange={(value) => updateEmail(index, value)}
            error={email.length > 0 && !isValidEmail(email)}
          />
          <X
            className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
            onClick={() => removeEmail(index)}
          />
        </PanelRow>
      ))}
    </PanelSection>
  )
}

export default EmailSettings
