import { Equal, EqualNot, Square, SquareCheckBig, X } from 'lucide-react'
import PanelRow from '../../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../hooks/utils/useClickOutside'
import { Filter, ValueCount } from '../../../../../../backend/types'
import PanelInput from '../../../../../library/PanelInput/PanelInput'
import {
  formatNumber,
  propertyToDisplay,
} from '../../../../../../utils/properties'
import { OptionsQueryMode } from '../../../../../../library/queryOptions/types'
import LoadingDots from '../../../../../library/LoadingDots/LoadingDots'

interface PropertyPopupProps {
  property: string
  optionsMode: OptionsQueryMode
  valueCounts: ValueCount[]

  filters: Filter[]
  setFilters: (filters: Filter[]) => void
  setOptionsFilter: (optionsFilter: string) => void

  close: () => void
}

const PropertyPopup = ({
  property,
  optionsMode,
  valueCounts,
  filters,
  setFilters,
  setOptionsFilter,
  close,
}: PropertyPopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => close(), undefined, ['property-button'])

  const [localContains, setLocalContains] = useState(
    filters.find((f) => f.field === property && f.operator === 'contains')
      ?.value,
  )
  const [localDoesNotContain, setLocalDoesNotContain] = useState(
    filters.find((f) => f.field === property && f.operator === 'not_contains')
      ?.value,
  )
  const hasExistsFilter = filters.find(
    (f) => f.field === property && f.operator === 'exists',
  )
  const hasNotExistsFilter = filters.find(
    (f) => f.field === property && f.operator === 'not_exists',
  )

  const [localOptionsTerm, setLocalOptionsTerm] = useState('')

  const handleSubmitContains = () => {
    if (localContains) {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        {
          field: property,
          operator: 'contains',
          value: localContains,
        },
      ])
    } else {
      setFilters(filters.filter((f) => f.field !== property))
    }
  }

  const handleSubmitDoesNotContain = () => {
    if (localDoesNotContain) {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        {
          field: property,
          operator: 'not_contains',
          value: localDoesNotContain,
        },
      ])
    } else {
      setFilters(filters.filter((f) => f.field !== property))
    }
  }

  const handleSubmitIsDefined = () => {
    if (hasExistsFilter) {
      setFilters(filters.filter((f) => f.field !== property))
    } else {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        { field: property, operator: 'exists', value: '' },
      ])
    }
  }

  const handleSubmitIsNotDefined = () => {
    if (hasNotExistsFilter) {
      setFilters(filters.filter((f) => f.field !== property))
    } else {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        { field: property, operator: 'not_exists', value: '' },
      ])
    }
  }

  const toggleEqualsFilter = (value: string) => {
    const hasFilter = filters.find(
      (f) =>
        f.field === property && f.operator === 'equals' && f.value === value,
    )
    if (hasFilter) {
      setFilters(filters.filter((f) => f.field !== property))
    } else {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        { field: property, operator: 'equals', value },
      ])
    }
  }

  const toggleNotEqualsFilter = (value: string) => {
    const hasFilter = filters.find(
      (f) =>
        f.field === property &&
        f.operator === 'not_equals' &&
        f.value === value,
    )
    if (hasFilter) {
      setFilters(filters.filter((f) => f.field !== property))
    } else {
      setFilters([
        ...filters.filter((f) => f.field !== property),
        { field: property, operator: 'not_equals', value },
      ])
    }
  }

  const handelSetOptionsTerm = (value: string) => {
    setLocalOptionsTerm(value)
    setOptionsFilter(value)
  }

  return (
    <div
      ref={ref}
      className="w-[496px] h-[600px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow flex flex-col"
    >
      <PanelSection mode="popup">
        <PanelRow>
          <h3 className="text-small-code-header text-text-1">
            {propertyToDisplay(property)}
          </h3>
          <X
            size={16}
            className="text-text-2 hover:text-text-1 cursor-pointer"
            onClick={close}
          />
        </PanelRow>
      </PanelSection>

      {propertyAllowsFilters(property) && (
        <PanelSection mode="popup">
          <PanelRow>
            <h3 className="text-small-header text-text-1">Filter</h3>
          </PanelRow>
          <PanelRow>
            <p className="w-[208px] text-small-code text-text-2">Contains:</p>
            <div className="w-[232px]">
              <PanelInput
                value={localContains || ''}
                onChange={(value) => setLocalContains(value)}
                onBlur={handleSubmitContains}
                placeholder="string match"
              />
            </div>
          </PanelRow>
          <PanelRow>
            <p className="w-[208px] text-small-code text-text-2">
              Does not contain:
            </p>
            <div className="w-[232px]">
              <PanelInput
                value={localDoesNotContain || ''}
                onChange={(value) => setLocalDoesNotContain(value)}
                onBlur={handleSubmitDoesNotContain}
                placeholder="string match"
              />
            </div>
          </PanelRow>
          <PanelRow>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleSubmitIsDefined}
            >
              {hasExistsFilter ? (
                <SquareCheckBig size={16} className="text-text-2" />
              ) : (
                <Square size={16} className="text-text-2" />
              )}
              <p className="text-small-code text-text-2">Is defined</p>
            </div>
          </PanelRow>
          <PanelRow>
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleSubmitIsNotDefined}
            >
              {hasNotExistsFilter ? (
                <SquareCheckBig size={16} className="text-text-2" />
              ) : (
                <Square size={16} className="text-text-2" />
              )}
              <p className="text-small-code text-text-2">Is not defined</p>
            </div>
          </PanelRow>
        </PanelSection>
      )}

      <div className="py-3 w-full flex flex-col gap-1 overflow-hidden">
        <div className="px-6 flex items-center justify-between">
          <h3 className="h-6 text-small-header text-text-1 flex items-center">
            Values
          </h3>
          {propertyAllowsFilters(property) && (
            <div className="w-[232px]">
              <PanelInput
                value={localOptionsTerm}
                onChange={handelSetOptionsTerm}
                placeholder="search values"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 overflow-y-auto">
          {optionsMode === 'loading' && (
            <div className="px-6 py-1 w-full h-6 flex items-center justify-between gap-2">
              <div className="flex-1 flex items-center text-small-code text-text-2">
                Loading attribute values
                <LoadingDots />
              </div>
            </div>
          )}
          {valueCounts.length > 0 &&
            valueCounts.map((value) => (
              <div
                key={value.value}
                className="px-6 py-1 w-full h-6 flex items-center justify-between gap-2"
              >
                <p className="flex-1 text-small-code text-text-2 overflow-scroll whitespace-nowrap">
                  {value.value}
                </p>
                <div className="flex items-center gap-2">
                  <Equal
                    size={16}
                    className="text-text-2 hover:text-text-1 cursor-pointer"
                    onClick={() => toggleEqualsFilter(value.value)}
                  />
                  <EqualNot
                    size={16}
                    className="text-text-2 hover:text-text-1 cursor-pointer"
                    onClick={() => toggleNotEqualsFilter(value.value)}
                  />
                  <p className="w-10 text-small-code text-text-2 text-right">
                    {formatNumber(value.count)}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

function propertyAllowsFilters(property: string) {
  if (property.startsWith('column.')) return false
  return true
}

export default PropertyPopup
