import { useRef } from 'react'
import useClickOutside from '../../../hooks/utils/useClickOutside'
import Fullscreen from '../Fullscreen/Fullscreen'
import PanelSection from '../PanelSection/PanelSection'
import PopupContainer from '../PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import CTAButton from '../CTAButton/CTAButton'

interface DeleteAlertPopupProps {
  name: string
  onSubmit: () => Promise<void>
  close: () => void
}

const DeleteAlertPopup: React.FC<DeleteAlertPopupProps> = ({
  name,
  onSubmit,
  close,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const handleSubmit = async () => {
    await onSubmit()
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Delete Alert</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <p className="text-body text-text-1">
            Are you sure you want to delete {name}?
          </p>
          <p className="text-body text-brand-error">
            Warning: This cannot be undone.
          </p>
        </PanelSection>
        <PanelSection bottom={true}>
          <div className="w-fit">
            <CTAButton mode="error" onClick={handleSubmit}>
              Delete Alert
            </CTAButton>
          </div>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default DeleteAlertPopup
