import { atom } from 'jotai'
import persistAtom from './persistAtom'
import { Organization, Project, Token, User } from './types'
import {
  AlertEvent,
  AlertInfo,
  ContactChannel,
  Integration,
  IntegrationExternalDetails,
  OrganizationMember,
} from '../backend/types'
import { ToastItem } from '../components/library/ToastContainer/ToastContainer'

export const userInfoAtom = atom<User>({ id: '', email: '' })

export const instanceIdAtom = persistAtom<{
  organizationId: string | null
  projectId: string | null
}>({
  key: 'instanceId',
  defaultValue: { organizationId: null, projectId: null },
  persistMode: 'local',
})

export const organizationInfoAtom = atom<Organization | null>(null)

export const organizationMembersAtom = atom<OrganizationMember[] | null>(null)

export const organizationsAtom = atom<Organization[] | null>(null)

export const joinOrganizationTokenAtom = atom<string | null>(null)

export const projectsAtom = atom<Project[] | null>(null)

export const tokensAtom = atom<Token[]>([])

export const integrationsAtom = atom<Integration[]>([])

export const integrationDetailsAtom = atom<IntegrationExternalDetails | null>(
  null,
)

export const contactChannelsAtom = atom<ContactChannel[]>([])

export const alertsAtom = atom<AlertInfo[]>([])

export const alertEventsAtom = atom<AlertEvent[]>([])

export const hasLogsAtom = atom<boolean>(false)

export const toastsAtom = atom<ToastItem[]>([])
