import { useEffect } from 'react'

export default function useClickOutside(
  ref: React.RefObject<HTMLElement | null>,
  callback: () => void,
  sourceRef?: React.RefObject<HTMLElement | null>,
  excludeClasses: string[] = [],
) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (
        ref.current &&
        !ref.current.contains(target) &&
        (!sourceRef?.current || !sourceRef.current.contains(target)) &&
        !isInsideExcludedClass(target, excludeClasses)
      ) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [callback, ref, sourceRef, excludeClasses])
}

function isInsideExcludedClass(target: HTMLElement, excludeClasses: string[]) {
  return excludeClasses.some((className) => target.closest(`.${className}`))
}
