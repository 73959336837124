import { AlertCreateRequest } from '../../backend/types'
import useLoadAlerts from '../data/load/useLoadAlerts'
import useBackend from '../services/useBackend'

const useCreateAlert = () => {
  const backend = useBackend()

  const loadAlerts = useLoadAlerts()

  const createAlert = async (request: AlertCreateRequest) => {
    try {
      await backend.createAlert(request)
      await loadAlerts()
    } catch (error) {}
  }

  return createAlert
}

export default useCreateAlert
