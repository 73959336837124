import posthog from 'posthog-js'
import { useAtomValue } from 'jotai'
import { userInfoAtom } from '../../state/state'
import { useEffect } from 'react'

const usePosthogIdentify = () => {
  const userInfo = useAtomValue(userInfoAtom)

  useEffect(() => {
    if (userInfo.id === '' || userInfo.email === '') return
    posthog.identify(userInfo.id, {
      email: userInfo.email,
    })
    localStorage.setItem('userEmail', userInfo.email)
    sessionStorage.setItem('sessionId', posthog.get_session_id())
  }, [userInfo])
}

export { usePosthogIdentify }
