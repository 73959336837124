import Fullscreen from '../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import CopyInput from '../../../../../library/CopyInput/CopyInput'

interface CopyTokenPopupProps {
  token: string
  close: () => void
}

const CopyTokenPopup: React.FC<CopyTokenPopupProps> = ({ token, close }) => {
  return (
    <Fullscreen>
      <PopupContainer className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Copy Your Token</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <p className="text-body text-text-1">
            You created a token. You can now copy it and use it to create an
            instance of the Vigilant logger or error monitor in your
            application.
          </p>
          <CopyInput value={token} />
          <p className="text-body text-brand-warning">
            Note: This token will only be shown once. If you lose it, you will
            have to create another one.
          </p>
        </PanelSection>
        <PanelSection bottom={true}>
          <div className="w-fit">
            <CTAButton onClick={close}>Done</CTAButton>
          </div>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default CopyTokenPopup
